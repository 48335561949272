import moment from 'moment';
import React from 'react';
import { TiWarningOutline } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import { queue, success } from 'assets';
import { ContactUs, Loading } from 'components';
import useQuery from 'hooks/useQuery';
import useUtilityCoreApi from 'hooks/useUtilityCoreApi';
import { UtilityCallBackService } from 'services/utility-core-services';

const Success = () => {
	const navigate = useNavigate();
	const [response, setResponse] = React.useState<any>('');
	const [queued, setQueued] = React.useState<boolean>(false);
	const query = useQuery();
	const queryPin = query.get('TransactionPin');
	const queryTransactionNumber = query.get('amt');
	const queryPaymentGatewayIdentifier = query.get('tran');
	const queryPostUtilityCallBack = query.get('cbsRefId');

	const postPayload: any = {
		transactionNumber: queryPaymentGatewayIdentifier,
		amount: queryTransactionNumber,
		transactionPin: queryPin,
		cbsRefId: queryPostUtilityCallBack,
	};

	const readConfig = useUtilityCoreApi();
	const postSuccessCallBackRequest = async () => {
		const res = await readConfig.handleRequest(
			UtilityCallBackService.postUtilityCallBack1(postPayload)
		);
		if (res?.utilityServiceRequestDeliveryStatusDto?.systemName === 'queued') {
			setQueued(true);
		} else {
			localStorage.removeItem('source');
			setQueued(false);
		}
		setResponse({ ...res });
	};

	let queuedStatus = response?.utilityServiceRequestDeliveryStatusDto
		? response?.utilityServiceRequestDeliveryStatusDto.systemName === 'queued'
		: '';

	React.useEffect(() => {
		postSuccessCallBackRequest();
		if (queuedStatus) {
			const interval = setInterval(() => postSuccessCallBackRequest(), 10000);
			return () => {
				clearInterval(interval);
			};
		}
	}, [queuedStatus]);
	let replaceAmmount = response.amount ? parseFloat(response.amount).toFixed(2) : '';
	return (
		<>
			{response === '' ? (
				<Loading />
			) : (
				<div className="mx-3 formLayout statusLayout d-flex align-items-center justify-content-center">
					<div className="bg-lightgray  col-lg-6">
						<div className="">
							<div className="statusLayout-header">
								<div className="">
									{/* <img src={queued ? queue : tick} alt="success-icon" /> */}
									<div className="checkmark mb-0">
										{response?.utilityServiceRequestDeliveryStatusDto.systemName === 'failed' ? (
											<div className="d-flex align-items-center justify-content-center">
												<TiWarningOutline color="#C53A42" size={100} />
											</div>
										) : response?.utilityServiceRequestDeliveryStatusDto.systemName === 'queued' ? (
											<img src={queue} alt="" />
										) : (
											<img src={success} alt="" />
										)}
									</div>
								</div>
								<div
									className={`${
										response?.utilityServiceRequestDeliveryStatusDto.systemName === 'failed'
											? 'main-error-text'
											: 'error-text'
									} text-center`}>
									{response.utilityServiceRequestDeliveryStatusDto.systemName === 'queued'
										? 'Your Request has been Queued.'
										: response?.utilityServiceRequestDeliveryStatusDto.systemName === 'failed'
										? response?.transactionMessage
										: 'Your Request was successful.'}
								</div>
							</div>

							{queued ? (
								<>
									<h6 className="d-block text-center mt-2 mx-2">
										{' '}
										Please wait a while...
										{/* <span
											className="track_request_here"
											onClick={() => navigate(`/track-request/${response.transactionNumber}`)}
										>
											here
										</span> */}
									</h6>
									<div
										className="spinner-border my-2"
										style={{ marginLeft: '300px' }}
										role="status"></div>
								</>
							) : (
								''
							)}
						</div>
						<div className=" statusLayout-lists mt-2">
							<div className="px-3 py-4 bg-white mb-4">
								{queued ? (
									<div className="mb-1 mb-sm-3 row justify-content-center align-items-center statusLayout-list">
										<div className="col-lg-6 text-lg-end text-start">Your Request Number is</div>
										<div className="col-lg-6">{response?.transactionNumber}</div>
									</div>
								) : (
									<>
										<div className="mb-1 mb-sm-3 row justify-content-center align-items-center statusLayout-list">
											<div className="col-lg-6 text-lg-end text-start">
												Your Transaction Amount was
											</div>
											<div className="col-lg-6">Rs. {replaceAmmount}</div>
										</div>

										<div className="mb-1 mb-sm-3 row justify-content-center align-items-center statusLayout-list">
											<div className="col-lg-6 text-lg-end text-start">
												Your Primary Identifier was
											</div>
											<div className="col-lg-6">
												<span>{response?.primaryParameter}</span>
											</div>
										</div>
										<div className="mb-1 mb-sm-3 row justify-content-center align-items-center statusLayout-list">
											<div className="col-lg-6 text-lg-end text-start">
												Your transaction number is
											</div>
											<div className="col-lg-6">
												<span>{response?.transactionNumber}</span>
											</div>
										</div>
										{/* <div className="mb-1 mb-sm-3 row justify-content-center align-items-center statusLayout-list">
											<div className="col-lg-6 text-lg-end text-start">Your transaction pin is</div>
											<div className="col-lg-6">
												<span>{response?.transactionPin}</span>
											</div>
										</div> */}
										<div className="mb-1 mb-sm-3 row justify-content-center align-items-center statusLayout-list">
											<div className="col-lg-6 text-lg-end text-start">
												Transaction Completed On:
											</div>
											<div className="col-lg-6">
												<span>
													{moment(response?.transactionCompletedTime).format('MM/DD/YYYY hh:mm:ss')}
												</span>
											</div>
										</div>
									</>
								)}
							</div>
							{!queued ? (
								<div className="row justify-content-center  mx-1">
									<div className="col-xl-8 offset-2-xl col-12">
										<div className="row ">
											<div className="col-lg-6 mb-2">
												<button
													onClick={() =>
														navigate(
															`/${response.serviceGroupName.toLowerCase()}/${
																response?.serviceGroupId
															}`
														)
													}
													className=" btn btn-gradient--solid px-4 py-2 btn-sm btn--medium w-100">
													Make Another Payment
												</button>
											</div>
											<div className="col-lg-6 mb-2">
												<button
													className="btn theme-primary back__button btn--medium px-4 py-2 btn-sm w-100 h-100"
													onClick={() =>
														(window.location.href = 'https://digibasket.nabilbank.com/')
													}>
													Back to Home
												</button>
											</div>
										</div>
									</div>
								</div>
							) : (
								''
							)}
						</div>
						<ContactUs />
					</div>
				</div>
			)}
		</>
	);
};

export default React.memo(Success);
