import { OpenAPI } from 'services/utility-core-services';
import { authConstant, LogoutConstant } from '../constant/auth.constant';
import axios from 'axios';

// console.log('==========', ());

const axiosConfig = axios.create({
	baseURL: (OpenAPI.BASE = window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL),
	headers: {
		'cache-control': 'no-cache',
		'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
	},
});

export const authAction = (user: any) => {
	return async (dispatch: any) => {
		dispatch({
			type: authConstant.LOGIN_REQUSET,
		});
		try {
			const { data } = await axiosConfig.post('/connect/token', user);
			let token = data.access_token;
			window.localStorage.setItem('token', token);
			dispatch({
				type: authConstant.LOGIN_SUCCESS,
				payload: token,
			});
		} catch (err: any) {
			dispatch({
				type: authConstant.LOGIN_FAILED,
			});
		}
	};
};

export const isUserLoggedIn = () => {
	return async (dispatch: any) => {
		const token = window.localStorage.getItem('token');
		if (token) {
			dispatch({
				type: authConstant.LOGIN_SUCCESS,
				payload: token,
			});
		}
	};
};
export const LogoutAction = () => {
	return async (dispatch: any) => {
		localStorage.removeItem('data');
		dispatch({
			type: LogoutConstant,
			payload: 'Logout Successfull',
		});
	};
};
