import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ContactUs } from 'components';
import useUtilityCoreApi from 'hooks/useUtilityCoreApi';
import { VisaDirectService } from 'services/utility-core-services';
import { TiWarningOutline } from 'react-icons/ti';
import useQuery from 'hooks/useQuery';

const declined = () => {
	const navigate = useNavigate();
	const query = useQuery();
	const queryAmount = query.get('amt');
	const queryOrderId = query.get('orderId');
	const queryNumber = query.get('mobileNumber');
	const queryTime = query.get('transactionCompletedTime');

	const [response, setResponse] = React.useState<any>('');
	const { handleRequest } = useUtilityCoreApi();

	const handleVisaDirectDeclined = async () => {
		const res: any = await handleRequest(VisaDirectService.postVisaDirect3());
		setResponse(res);
	};
	React.useEffect(() => {
		handleVisaDirectDeclined();
	}, []);

	return (
		<>
			<div className="mx-3 formLayout statusLayout d-flex align-items-center justify-content-center">
				<div className="bg-lightgray  col-lg-6">
					<div className="">
						<div className="statusLayout-header">
							<div className="d-flex align-items-center justify-content-center">
								<TiWarningOutline color="#C53A42" className="warning-icon" />
							</div>

							<div className="main-error-text">
								Your Request was declined.
								<div className="checkmark mb-0"></div>
							</div>
						</div>
					</div>
					<div className=" statusLayout-lists mt-2">
						<div className="px-3 py-4 bg-white mb-4">
							<>
								<div className="mb-1 mb-sm-3 row justify-content-center align-items-center statusLayout-list">
									<div className="col-lg-6 text-lg-end text-start">
										Your Transaction Amount was:
									</div>
									<div className="col-lg-6">Rs. {queryAmount}</div>
								</div>
								<div className="mb-1 mb-sm-3 row justify-content-center align-items-center statusLayout-list">
									<div className="col-lg-6 text-lg-end text-start">Your Mobile Number:</div>
									<div className="col-lg-6"> {queryNumber}</div>
								</div>

								<div className="mb-1 mb-sm-3 row justify-content-center align-items-center statusLayout-list">
									<div className="col-lg-6 text-lg-end text-start">Transaction Completed On:</div>
									<div className="col-lg-6">
										<span> {queryTime}</span>
									</div>
								</div>
								<div className="mb-1 mb-sm-3 row justify-content-center align-items-center statusLayout-list">
									<div className="col-lg-6 text-lg-end text-start">Order ID :</div>
									<div className="col-lg-6"> {queryOrderId}</div>
								</div>
							</>
						</div>

						<div className="row justify-content-center  mx-1">
							<div className="col-xl-8 offset-2-xl col-12">
								<div className="row ">
									<div className="col-lg-6 mb-2">
										<button
											onClick={() => navigate('/visadirect/f2c42748-5e43-11ec-babc-02420a000b10')}
											className=" btn btn-gradient--solid px-4 py-2 btn-sm btn--medium w-100">
											Make Another Payment
										</button>
									</div>
									<div className="col-lg-6 mb-2">
										<button
											className="btn theme-primary back__button btn--medium px-4 py-2 btn-sm w-100 h-100"
											onClick={() => (window.location.href = 'https://digibasket.nabilbank.com/')}>
											Back to Home
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<ContactUs />
				</div>
			</div>
		</>
	);
};

export default React.memo(declined);
