/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_DataPackDtos_DataPackRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_DataPackDtos_DataPackRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_DataPackDtos_NcellPackagesDetailsDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_DataPackDtos_NcellPackagesDetailsDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_DataPackDtos_Packages } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_DataPackDtos_Packages';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class DataPacksService {

    /**
     * @param serviceName
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_DataPackDtos_NcellPackagesDetailsDto Success
     * @throws ApiError
     */
    public static getDataPacks(
        serviceName?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_DataPackDtos_NcellPackagesDetailsDto> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/data-packs/data-package-list`,
            query: {
                'serviceName': serviceName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param productCode
     * @param packageId
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_DataPackDtos_Packages Success
     * @throws ApiError
     */
    public static getDataPacks1(
        productCode?: string,
        packageId?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_DataPackDtos_Packages> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/data-packs/data-package-details`,
            query: {
                'ProductCode': productCode,
                'PackageId': packageId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postDataPacks(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_DataPackDtos_DataPackRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/data-packs/data-pack-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}