/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ArhantInsuranceDetailsFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ArhantInsuranceDetailsFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ArhantInsurancePaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ArhantInsurancePaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_AsianLifeInsuranceFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_AsianLifeInsuranceFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_AsianLifeInsurancePaymentDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_AsianLifeInsurancePaymentDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_HimalayanInsurancePaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_HimalayanInsurancePaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_HimilayanGeneralInsuranceFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_HimilayanGeneralInsuranceFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ImeGeneralInsuranceFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ImeGeneralInsuranceFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ImeGeneralInsurancePaymentDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ImeGeneralInsurancePaymentDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_JyotiInsurancePaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_JyotiInsurancePaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_JyotiLifeInsuranceFormatDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_JyotiLifeInsuranceFormatDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_MahaLaxmiInsuranccePaymentDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_MahaLaxmiInsuranccePaymentDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_MahalaxmiInsuranceFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_MahalaxmiInsuranceFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NationalLifeInsuranceFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NationalLifeInsuranceFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NationalLifeInsurancePaymentDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NationalLifeInsurancePaymentDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NecoInsuranceDetailsFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NecoInsuranceDetailsFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NecoInsurancePayementRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NecoInsurancePayementRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NepalLifeInsuranceFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NepalLifeInsuranceFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NepalLifeInsurancePaymentDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NepalLifeInsurancePaymentDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrabhuLifeInsuranceFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrabhuLifeInsuranceFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrabhuLifeInsurancePaymentDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrabhuLifeInsurancePaymentDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrimeLifeInsuranceFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrimeLifeInsuranceFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrimeLifeInsurancePaymentDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrimeLifeInsurancePaymentDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrudentialInsuranceDetailFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrudentialInsuranceDetailFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrudentialInsurancePayemntDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrudentialInsurancePayemntDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_RelianceInsuranceDetailFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_RelianceInsuranceDetailFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_RelianceInsurancePaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_RelianceInsurancePaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SagarmathaInsuranceFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SagarmathaInsuranceFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SagarmathaInsurancePaymentDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SagarmathaInsurancePaymentDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SanimaInsuranceDetailFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SanimaInsuranceDetailFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SanimaInsurancePaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SanimaInsurancePaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ShikharInsuranceFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ShikharInsuranceFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ShikharInsurancePaymentDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ShikharInsurancePaymentDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SuryaInsurancePaymentDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SuryaInsurancePaymentDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SuryaLifeInsuranceFirstPaymentFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SuryaLifeInsuranceFirstPaymentFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SuryaLifeInsuranceFirstPaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SuryaLifeInsuranceFirstPaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SuryaLifeInsuranceFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SuryaLifeInsuranceFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_UnionLifeInsuranceDetailFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_UnionLifeInsuranceDetailFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_UnionLifeInsurancePaymentDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_UnionLifeInsurancePaymentDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class InsuranceService {

    /**
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NecoInsuranceDetailsFormat Success
     * @throws ApiError
     */
    public static getInsurance(): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NecoInsuranceDetailsFormat> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/insurance/neco-insurance-service-detail`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NecoInsurancePayementRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/neco-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestId
     * @param insuranceSlug
     * @param insuranceName
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ArhantInsuranceDetailsFormat Success
     * @throws ApiError
     */
    public static postInsurance1(
        requestId: string,
        insuranceSlug?: string,
        insuranceName?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ArhantInsuranceDetailsFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/arhant-insurance-service-detail/${requestId}`,
            query: {
                'insuranceSlug': insuranceSlug,
                'InsuranceName': insuranceName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance2(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ArhantInsurancePaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/arhant-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param policyNumber
     * @param dateOfBirth
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_RelianceInsuranceDetailFormat Success
     * @throws ApiError
     */
    public static postInsurance3(
        policyNumber?: string,
        dateOfBirth?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_RelianceInsuranceDetailFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/reliance-insurance-service-detail`,
            query: {
                'policyNumber': policyNumber,
                'dateOfBirth': dateOfBirth,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance4(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_RelianceInsurancePaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/reliance-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param policyNumber
     * @param dateOfBirth
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_JyotiLifeInsuranceFormatDto Success
     * @throws ApiError
     */
    public static postInsurance5(
        policyNumber?: string,
        dateOfBirth?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_JyotiLifeInsuranceFormatDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/jyoti-life-insurance-service-detail`,
            query: {
                'policyNumber': policyNumber,
                'dateOfBirth': dateOfBirth,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance6(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_JyotiInsurancePaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/jyoti-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param policyNumber
     * @param dateOfBirth
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_UnionLifeInsuranceDetailFormat Success
     * @throws ApiError
     */
    public static postInsurance7(
        policyNumber?: string,
        dateOfBirth?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_UnionLifeInsuranceDetailFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/union-life-insurance-service-detail`,
            query: {
                'policyNumber': policyNumber,
                'dateOfBirth': dateOfBirth,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance8(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_UnionLifeInsurancePaymentDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/union-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param policyNumber
     * @param dateOfBirth
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SuryaLifeInsuranceFormat Success
     * @throws ApiError
     */
    public static postInsurance9(
        policyNumber?: string,
        dateOfBirth?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SuryaLifeInsuranceFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/surya-life-insurance-service-detail`,
            query: {
                'policyNumber': policyNumber,
                'dateOfBirth': dateOfBirth,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance10(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SuryaInsurancePaymentDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/surya-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param policyNumber
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_HimilayanGeneralInsuranceFormat Success
     * @throws ApiError
     */
    public static postInsurance11(
        policyNumber?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_HimilayanGeneralInsuranceFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/himalayan-insurance-service-detail`,
            query: {
                'policyNumber': policyNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance12(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_HimalayanInsurancePaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/himalayan-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param policyNumber
     * @param dateOfBirth
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SanimaInsuranceDetailFormat Success
     * @throws ApiError
     */
    public static postInsurance13(
        policyNumber?: string,
        dateOfBirth?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SanimaInsuranceDetailFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/sanima-insurance-service-detail`,
            query: {
                'policyNumber': policyNumber,
                'dateOfBirth': dateOfBirth,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance14(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SanimaInsurancePaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/sanima-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param policyNumber
     * @param dateOfBirth
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NepalLifeInsuranceFormat Success
     * @throws ApiError
     */
    public static postInsurance15(
        policyNumber?: string,
        dateOfBirth?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NepalLifeInsuranceFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/nepal-life-insurance-service-detail`,
            query: {
                'policyNumber': policyNumber,
                'dateOfBirth': dateOfBirth,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance16(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NepalLifeInsurancePaymentDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/nepal-life-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ShikharInsuranceFormat Success
     * @throws ApiError
     */
    public static postInsurance17(): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ShikharInsuranceFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/shikhar-insurance-service-detail`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance18(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ShikharInsurancePaymentDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/shikhar-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param debitNoteNumber
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SagarmathaInsuranceFormat Success
     * @throws ApiError
     */
    public static postInsurance19(
        debitNoteNumber?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SagarmathaInsuranceFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/sagarmatha-insurance-service-detail`,
            query: {
                'debitNoteNumber': debitNoteNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance20(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SagarmathaInsurancePaymentDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/sagarmatha-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param policyNumber
     * @param dateOfBirth
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_MahalaxmiInsuranceFormat Success
     * @throws ApiError
     */
    public static postInsurance21(
        policyNumber?: string,
        dateOfBirth?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_MahalaxmiInsuranceFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/maha-laxmi-insurance-service-detail`,
            query: {
                'policyNumber': policyNumber,
                'dateOfBirth': dateOfBirth,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance22(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_MahaLaxmiInsuranccePaymentDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/maha-laxmi-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param policyNumber
     * @param dateOfBirth
     * @param lastName
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrimeLifeInsuranceFormat Success
     * @throws ApiError
     */
    public static postInsurance23(
        policyNumber?: string,
        dateOfBirth?: string,
        lastName?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrimeLifeInsuranceFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/prime-life-insurance-service-detail`,
            query: {
                'policyNumber': policyNumber,
                'dateOfBirth': dateOfBirth,
                'lastName': lastName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance24(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrimeLifeInsurancePaymentDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/prime-life-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param policyNumber
     * @param dateOfBirth
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NationalLifeInsuranceFormat Success
     * @throws ApiError
     */
    public static postInsurance25(
        policyNumber?: string,
        dateOfBirth?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NationalLifeInsuranceFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/national-life-insurance-service-detail`,
            query: {
                'policyNumber': policyNumber,
                'dateOfBirth': dateOfBirth,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance26(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_NationalLifeInsurancePaymentDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/national-life-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param policyNumber
     * @param dateOfBirth
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrabhuLifeInsuranceFormat Success
     * @throws ApiError
     */
    public static postInsurance27(
        policyNumber?: string,
        dateOfBirth?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrabhuLifeInsuranceFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/prabhu-life-insurance-service-detail`,
            query: {
                'policyNumber': policyNumber,
                'dateOfBirth': dateOfBirth,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance28(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrabhuLifeInsurancePaymentDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/prabhu-life-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param policyNumber
     * @param dateOfBirth
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_AsianLifeInsuranceFormat Success
     * @throws ApiError
     */
    public static postInsurance29(
        policyNumber?: string,
        dateOfBirth?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_AsianLifeInsuranceFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/asian-life-insurance-service-detail`,
            query: {
                'policyNumber': policyNumber,
                'dateOfBirth': dateOfBirth,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance30(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_AsianLifeInsurancePaymentDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/asian-life-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param policyNumber
     * @param serviceSlug
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SuryaLifeInsuranceFirstPaymentFormat Success
     * @throws ApiError
     */
    public static postInsurance31(
        policyNumber?: string,
        serviceSlug?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SuryaLifeInsuranceFirstPaymentFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/surya-life-first-payment-service-detail`,
            query: {
                'policyNumber': policyNumber,
                'serviceSlug': serviceSlug,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance32(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_SuryaLifeInsuranceFirstPaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/surya-life-first-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ImeGeneralInsuranceFormat Success
     * @throws ApiError
     */
    public static postInsurance33(): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ImeGeneralInsuranceFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/ime-general-insurance-service-detail`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance34(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_ImeGeneralInsurancePaymentDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/ime-general-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrudentialInsuranceDetailFormat Success
     * @throws ApiError
     */
    public static postInsurance35(): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrudentialInsuranceDetailFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/prudential-insurance-service-detail`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInsurance36(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Insurance_PrudentialInsurancePayemntDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/insurance/prudential-insurance-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}