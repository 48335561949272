import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { Header, Footer, NabilHeader } from './containers';
import { authAction, isUserLoggedIn } from './redux/Action/auth.action';
import { themeAction } from 'redux/Action/theme.action';
import RouteObj from './Route';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ToastContainer } from 'react-toastify';
import { Loading } from './components';
import useQuery from 'hooks/useQuery';
import Success from 'pages/Success';
import { ThemeProvider } from 'styles';
import Helmet from 'react-helmet';
import Spinner from 'components/Spinner';
import { isEmpty } from 'lodash';
import { getMenuAction } from 'redux/Action/menu.action';

interface Iprops {
	path: string;
	component: any;
}

const imageUrl = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_IMAGE_ENDPOINT;
const username = window.__RUNTIME_CONFIG__.REACT_APP_LOGIN_USERNAME;

const App = () => {
	const dispatch = useDispatch();
	const params = new URLSearchParams();
	const { themeSettings, isLoading, image, bankName } = useSelector((state: any) => state?.theme);
	const { metaDataSource } = useSelector((state: any) => state?.menu);
	const query = useQuery();
	const source = query.get('source');
	params.append('client_id', 'UtilityService_App');
	params.append('username', username);
	params.append('grant_type', 'password');
	React.useEffect(() => {
		dispatch(isUserLoggedIn());
	}, []);

	useEffect(() => {
		dispatch(authAction(params));
		dispatch(themeAction());
		dispatch(getMenuAction());
	}, []);
	const mob = localStorage.getItem('source');

	function getPageLocation() {
		const text = window.location.pathname;
		const regex = /\/([a-z-]+)\b/i;
		const match = text.match(regex);

		if (match && match.length > 1) {
			let location = match[1]
				.split('-')
				.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
				.join(' ');
			return location;
		} else {
			return 'Home';
		}
	}

	return (
		<React.Fragment>
			<ThemeProvider theme={themeSettings}>
				{!isLoading ? (
					<>
						<Helmet>
							<title>{`${
								isEmpty(themeSettings?.ApplicationName)
									? 'Loading...'
									: themeSettings?.ApplicationName + ' | ' + getPageLocation()
							}`}</title>
							<meta name="description" content={`${bankName?.CopyRightText} Utility Payments`} />
							<link rel="icon" type="image" href={`${imageUrl + image?.Favicon}`} sizes="16x16" />
						</Helmet>
						{source === 'mobile' || mob ? (
							''
						) : (
							<>
								{metaDataSource !== undefined && metaDataSource !== null
									? {
											nabil: <NabilHeader />,
											system: <Header />,}[metaDataSource as string]
									: ''}
							</>
						)}
						<Suspense fallback={<Loading />}>
							<Routes>
								{RouteObj.map((e: Iprops, i: number) => (
									<Route path={e.path} key={i} element={<e.component />} />
								))}
								<Route path={'/success'} element={<Success />} />
							</Routes>
						</Suspense>
						{source === 'mobile' || mob ? '' : <Footer />}
					</>
				) : (
					<div className="isLoadingContainer">
						<Spinner />
					</div>
				)}
			</ThemeProvider>
			<ToastContainer />
		</React.Fragment>
	);
};

export default App;
