import axios from 'axios';

const BaseUrl = window.__RUNTIME_CONFIG__.REACT_APP_DIGIBANK_URL;

const axiosInstance = axios.create({
	baseURL: `${BaseUrl}/rest-api/utility/getAllMenuByType`,
	headers: {
		Cookie:
			'ci_session=fijntg8sp0rvijb6f0d9qp3o2al4mbsn; swapper_cookie=95cd800ba9e370225eddf55cfb6ec31d',
	},
});

export default axiosInstance;
