import React from 'react';

interface Iprops {
	isValid: boolean;
	isSubmitting: boolean;
}

const ButtonComp = ({ isValid, isSubmitting }: Iprops) => {
	return (
		<div className="mt-3">
			<button
				className={`${
					isValid ? 'valid_btn ' : 'not_valid_btn'
				} btn btn-gradient--solid px-4 py-2 btn-sm btn--medium mt-0`}
				type="submit"
				disabled={!isValid || isSubmitting}
			>
				{isSubmitting ? (
					<span
						className="spinner-border spinner-border-sm"
						role="status"
						aria-hidden="true"
					></span>
				) : (
					'Proceed'
				)}
			</button>
		</div>
	);
};

export default React.memo(ButtonComp);
