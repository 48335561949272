import { menuConstants } from '../constant/menu.constant';
import axios from 'axios';

const API_BASE_URL =
	window.__RUNTIME_CONFIG__.REACT_APP_ADMIN_URL +
	'/admin-api/api/app/cms-menu-management/cms-menus-by-type';

const META_DATA_SOURCE_URL =
	window.__RUNTIME_CONFIG__.REACT_APP_ADMIN_URL +
	'/admin-api/api/app/cms-menu-management/cms-menu-data-source';

const getHeaderMenu = async () => {
	const res = await axios.get(`${API_BASE_URL}`, {
		params: {
			cmsMenuType: 'header-menu',
		},
	});
	return res.data;
};
const getFooterMenu = async () => {
	const res = await axios.get(`${API_BASE_URL}`, {
		params: {
			cmsMenuType: 'footer-menu',
		},
	});
	return res.data;
};

const getMetaDataSource = async () => {
	const res = await axios.get(`${META_DATA_SOURCE_URL}`);
	return res.data;
};

export const getMenuAction = () => {
	return async (dispatch: any) => {
		dispatch({
			type: menuConstants.GET_MENU_ITEMS_REQUEST,
		});
		try {
			let payload: any = [];
			const [headerMenuData, footerMenuData, metaDataSource]: any = await Promise.allSettled([
				getHeaderMenu(),
				getFooterMenu(),
				getMetaDataSource(),
			]);
			payload['headerMenuData'] = headerMenuData?.value;
			payload['footerMenuData'] = footerMenuData?.value;
			payload['metaDataSource'] = metaDataSource?.value;
			dispatch({
				type: menuConstants.GET_MENU_ITEMS_SUCCESS,
				payload: payload,
			});
		} catch (err: any) {
			dispatch({
				type: menuConstants.GET_MENU_ITEMS_FAILED,
			});
		}
	};
};
