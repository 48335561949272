/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityService_VisaDirect_Dtos_OrderStatusResponseDto } from '../models/Amnil_UtilityService_VisaDirect_Dtos_OrderStatusResponseDto';
import type { Amnil_UtilityService_VisaDirect_Dtos_VisaDirectDetailDto } from '../models/Amnil_UtilityService_VisaDirect_Dtos_VisaDirectDetailDto';
import type { Amnil_UtilityService_VisaDirect_Dtos_VisaDirectInputDto } from '../models/Amnil_UtilityService_VisaDirect_Dtos_VisaDirectInputDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class VisaDirectService {

    /**
     * @param amount
     * @param charge
     * @param email
     * @param mobile
     * @param remarks
     * @param reference
     * @returns Amnil_UtilityService_VisaDirect_Dtos_VisaDirectDetailDto Success
     * @throws ApiError
     */
    public static getVisaDirect(
        amount?: number,
        charge?: number,
        email?: string,
        mobile?: string,
        remarks?: string,
        reference?: string,
    ): CancelablePromise<Amnil_UtilityService_VisaDirect_Dtos_VisaDirectDetailDto> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/visa-direct/visa-direct-detail`,
            query: {
                'Amount': amount,
                'Charge': charge,
                'Email': email,
                'Mobile': mobile,
                'Remarks': remarks,
                'Reference': reference,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderId
     * @returns Amnil_UtilityService_VisaDirect_Dtos_OrderStatusResponseDto Success
     * @throws ApiError
     */
    public static getVisaDirect1(
        orderId: string,
    ): CancelablePromise<Amnil_UtilityService_VisaDirect_Dtos_OrderStatusResponseDto> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/visa-direct/order-status/${orderId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns string Success
     * @throws ApiError
     */
    public static postVisaDirect(
        requestBody?: Amnil_UtilityService_VisaDirect_Dtos_VisaDirectInputDto,
    ): CancelablePromise<string> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/visa-direct/order-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postVisaDirect1(): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/visa-direct/visa-direct-approved-call-back`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postVisaDirect2(): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/visa-direct/visa-direct-cancelled-call-back`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postVisaDirect3(): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/visa-direct/visa-direct-decline-call-back`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static getVisaDirect2(): CancelablePromise<number> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/visa-direct/charge`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}