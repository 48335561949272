import React from 'react';

const Textarea = () => {
	return (
		<div className="form-group mb-3">
			<textarea
				name="textarea"
				placeholder="Remarks (Optional)"
				className="form_textarea form-control"
			></textarea>
		</div>
	);
};

export default React.memo(Textarea);
