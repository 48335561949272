/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Vechicle_VehicleRegistrationDetailFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Vechicle_VehicleRegistrationDetailFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Vechicle_VehicleRegistrationInputDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Vechicle_VehicleRegistrationInputDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Vechicle_VehicleRegistrationPaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Vechicle_VehicleRegistrationPaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Vechicle_VehicleRegistrationSystemFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Vechicle_VehicleRegistrationSystemFormat';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class VehicleRegistrationService {

    /**
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Vechicle_VehicleRegistrationSystemFormat Success
     * @throws ApiError
     */
    public static postVehicleRegistration(): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Vechicle_VehicleRegistrationSystemFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/vehicle-registration/vehicle-registration-service-detail`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Vechicle_VehicleRegistrationDetailFormat Success
     * @throws ApiError
     */
    public static postVehicleRegistration1(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Vechicle_VehicleRegistrationInputDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Vechicle_VehicleRegistrationDetailFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/vehicle-registration/vehicle-registration-detail`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postVehicleRegistration2(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Vechicle_VehicleRegistrationPaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/vehicle-registration/vechicle-registration-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}