/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_FailureCallBackDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_FailureCallBackDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_FailureCallBackRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_FailureCallBackRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_SuccessCallBackDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_SuccessCallBackDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_SuccessCallBackRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_SuccessCallBackRequestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class UtilityCallBackService {

    /**
     * @param cbsRefId
     * @param tmpId
     * @param msg
     * @param paymentGatewayIdentifier
     * @returns string Success
     * @throws ApiError
     */
    public static getUtilityCallBack(
        cbsRefId: string,
        tmpId?: string,
        msg?: string,
        paymentGatewayIdentifier?: string,
    ): CancelablePromise<string> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/utility-call-back/failure-call-back/${cbsRefId}`,
            query: {
                'tmpID': tmpId,
                'msg': msg,
                'paymentGatewayIdentifier': paymentGatewayIdentifier,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_FailureCallBackDto Success
     * @throws ApiError
     */
    public static postUtilityCallBack(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_FailureCallBackRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_FailureCallBackDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/utility-call-back/failure-call-back`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param cbsRefId
     * @param tran
     * @param amt
     * @param transactionPin
     * @returns string Success
     * @throws ApiError
     */
    public static getUtilityCallBack1(
        cbsRefId: string,
        tran?: string,
        amt?: number,
        transactionPin?: string,
    ): CancelablePromise<string> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/utility-call-back/success-call-back/${cbsRefId}`,
            query: {
                'tran': tran,
                'amt': amt,
                'TransactionPin': transactionPin,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_SuccessCallBackDto Success
     * @throws ApiError
     */
    public static postUtilityCallBack1(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_SuccessCallBackRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_SuccessCallBackDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/utility-call-back/success-call-back`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}