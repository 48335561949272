import NabilPayCancel from 'pages/NabilCardRoutes/NabilPayCancel';
import NabilPayDeclined from 'pages/NabilCardRoutes/NabilPayDeclined';
import NabilPaySuccess from 'pages/NabilCardRoutes/NabilPaySuccess';
import approved from 'pages/VisaDirectTransfer/approved';
import cancel from 'pages/VisaDirectTransfer/cancel';
import declined from 'pages/VisaDirectTransfer/declined';
import {
	CreditCard,
	Electicity,
	GovermentServices,
	KhanePani,
	Travel,
	IRD,
	Customs,
	Loksewa,
	OCR,
	Passport,
	Revenue,
	Traffic,
	NotFound,
	SSF,
	DOFE,
	TopUp,
	Flight,
	TV,
	ClearTV,
	Dishhome,
	MeroTV,
	PrabhuTV,
	SimTV,
	SkyTV,
	Internet,
	ClassicTech,
	Vianet,
	Worldlink,
	Subisu,
	Demat,
	Insurance,
	NecoInsurance,
	NepalLifeInsurance,
	PrimelifeInsurance,
	Sagarmatha,
	PremierInsurance,
	UnitedInsurance,
	JyotiLifeInsurance,
	SanimaLifeInsurance,
	NepalInsurance,
	RelianceLifeInsurance,
	NLGInsurance,
	AjodInsurance,
	HimalayanGeneralInsurance,
	GeneralInsurance,
	SiddharthaInsurance,
	UnionLifeInsurance,
	SuryaLifeInsurance,
	SanimaGeneralInsurance,
	ShikharlInsurance,
	UnionLifeFirstPaymentlInsurance,
	PrudentialInsurance,
	MahalaxmiInsurance,
	LumbiniGeneralInsurance,
	EverestInsurance,
	SuryaLifeFirstPaymentInsurance,
	IMEGeneralInsurance,
	OrientalInsurance,
	JyotiLifeFirstPaymentInsurance,
	AsianLifeInsurance,
	PrabhuLifeInsurance,
	NationalLifeInsurance,
	Test,
	NTC,
	NCELL,
	Landline,
	ForeignEmploymentIndividual,
	ForeignEmploymentManpower,
	MuktinathCapitalDemat,
	PrabhuCapitalDemat,
	NibleAceCapitalDemat,
	Home,
	Error,
	Success,
	TrackRequest,
	NtcPaymentForm,
	NcellPaymentForm,
	FlightAvailable,
} from './pages';

import vehiclesRegistration from './pages/vehiclesRegistration';
import VisaDirectTransfer from './pages/VisaDirectTransfer';

const RouteObj = [
	{
		path: '/',
		component: Home,
	},
	{
		path: '/prabhu-capital',
		component: PrabhuCapitalDemat,
	},
	{
		path: '/nible-ace-capital',
		component: NibleAceCapitalDemat,
	},
	{
		path: '/muktinath-capital',
		component: MuktinathCapitalDemat,
	},
	{
		path: '/landline/:id',
		component: Landline,
	},
	{
		path: '/datapack/ntc-payment/:id',
		component: NtcPaymentForm,
	},
	{
		path: '/datapack/ncell-payment/:id',
		component: NcellPaymentForm,
	},
	{
		path: '/datapack/:id',
		component: Test,
	},
	{
		path: '/datapack/ncell',
		component: NCELL,
	},
	{
		path: '/datapack/ntc',
		component: NTC,
	},

	{
		path: '/prabhu-life-insurance',
		component: PrabhuLifeInsurance,
	},
	{
		path: '/asian-life-insurance',
		component: AsianLifeInsurance,
	},
	{
		path: '/jyoti-life-first-payment-insurance',
		component: JyotiLifeFirstPaymentInsurance,
	},
	{
		path: '/oriental-insurance',
		component: OrientalInsurance,
	},
	{
		path: '/prudential-insurance',
		component: PrudentialInsurance,
	},
	{
		path: '/ime-general-insurance',
		component: IMEGeneralInsurance,
	},
	{
		path: '/surya-life-payment-first',
		component: SuryaLifeFirstPaymentInsurance,
	},
	{
		path: '/everest-insurance',
		component: EverestInsurance,
	},
	{
		path: '/lumbini-general-insurance',
		component: LumbiniGeneralInsurance,
	},
	{
		path: '/mahalaxmi-insurance',
		component: MahalaxmiInsurance,
	},
	{
		path: '/union-firstPayment-insurance',
		component: UnionLifeFirstPaymentlInsurance,
	},
	{
		path: '/shikhar-insurance',
		component: ShikharlInsurance,
	},
	{
		path: '/sanima-general-insurance',
		component: SanimaGeneralInsurance,
	},
	{
		path: '/surya-life-insurance',
		component: SuryaLifeInsurance,
	},
	{
		path: '/union-life-insurance',
		component: UnionLifeInsurance,
	},
	{
		path: '/siddhartha-insurance',
		component: SiddharthaInsurance,
	},
	{
		path: '/general-insurance',
		component: GeneralInsurance,
	},
	{
		path: '/himalayan-general-insurance',
		component: HimalayanGeneralInsurance,
	},
	{
		path: '/ajod-insurance',
		component: AjodInsurance,
	},
	{
		path: '/nlg-insurance',
		component: NLGInsurance,
	},
	{
		path: '/reliance-insurance',
		component: RelianceLifeInsurance,
	},
	{
		path: '/nepal-insurance',
		component: NepalInsurance,
	},
	{
		path: '/sanima-life-insurance',
		component: SanimaLifeInsurance,
	},
	{
		path: '/jyoti-life-insurance',
		component: JyotiLifeInsurance,
	},
	{
		path: '/united-insurance',
		component: UnitedInsurance,
	},
	{
		path: '/premier-insurance',
		component: PremierInsurance,
	},
	{
		path: '/sagarmatha-insurance',
		component: Sagarmatha,
	},
	{
		path: '/national-life-insurance',
		component: NationalLifeInsurance,
	},
	{
		path: '/prime-life-insurance',
		component: PrimelifeInsurance,
	},

	{
		path: '/neco-insurance',
		component: NecoInsurance,
	},
	{
		path: '/demat/:id',
		component: Demat,
	},
	{
		path: '/insurance/:params',
		component: Insurance,
	},
	{
		path: '/nepal-life-insurance',
		component: NepalLifeInsurance,
	},

	{
		path: '/tv-cleartv',
		component: ClearTV,
	},
	{
		path: '/tv-dishhome',
		component: Dishhome,
	},

	{
		path: '/tv-merotv',
		component: MeroTV,
	},

	{
		path: '/tv-prabhutv',
		component: PrabhuTV,
	},
	{
		path: '/tv-simtv',
		component: SimTV,
	},
	{
		path: '/tv-skytv',
		component: SkyTV,
	},

	{
		path: '/internet-classicTech',
		component: ClassicTech,
	},

	{
		path: '/internet-vianet',
		component: Vianet,
	},

	{
		path: '/internet-worldlink',
		component: Worldlink,
	},
	{
		path: '/internet-subisu',
		component: Subisu,
	},

	{
		path: '/topup/:id',
		component: TopUp,
	},
	{
		path: '/tv/:params',
		component: TV,
	},
	{
		path: '/internet/:id',
		component: Internet,
	},
	{
		path: '/credit-card',
		component: CreditCard,
	},
	{
		path: '/electricity',
		component: Electicity,
	},
	{
		path: '/flight/:id',
		component: Flight,
	},
	{
		path: '/flight/available',
		component: FlightAvailable,
	},
	{
		path: '/gov-services',
		component: GovermentServices,
	},
	{
		path: '/khanepani/:id',
		component: KhanePani,
	},
	{
		path: '/bus-ticket',
		component: Travel,
	},
	{
		path: '/credit-card',
		component: CreditCard,
	},
	{
		path: '/electricity/:id',
		component: Electicity,
	},
	{
		path: '/governmentpayment/:id',
		component: GovermentServices,
	},
	{
		path: '/khane-pani',
		component: KhanePani,
	},
	{
		path: '/government-sevices-ird',
		component: IRD,
	},

	{
		path: '/government-sevices-custom',
		component: Customs,
	},
	{
		path: '/government-sevices-loksewa',
		component: Loksewa,
	},

	{
		path: '/government-sevices-ocr',
		component: OCR,
	},
	{
		path: '/government-sevices-passport',
		component: Passport,
	},

	{
		path: '/government-sevices-revenue',
		component: Revenue,
	},
	{
		path: '/government-sevices-traffic',
		component: Traffic,
	},

	{
		path: '/government-sevices-ssf',
		component: SSF,
	},
	{
		path: '/government-sevices-dofe',
		component: DOFE,
	},
	{
		path: '/government-sevices-dofe-foreign-employment-individual',
		component: ForeignEmploymentIndividual,
	},
	{
		path: '/government-sevices-dofe-foreign-employment-manpower',
		component: ForeignEmploymentManpower,
	},

	{
		path: '/error',
		component: Error,
	},
	{
		path: '/visadirect/:id',
		component: VisaDirectTransfer,
	},
	{
		path: '/canceled',
		component: cancel,
	},
	{
		path: '/approved',
		component: approved,
	},
	{
		path: '/declined',
		component: declined,
	},
	{
		path: '/nabilcardcancelcallback',
		component: NabilPayCancel,
	},
	{
		path: '/nabilcardcallback',
		component: NabilPaySuccess,
	},
	{
		path: '/nabilcarddeclinecallback',
		component: NabilPayDeclined,
	},

	{
		path: '/vehicle/:id',
		component: vehiclesRegistration,
	},
	{
		path: '/success',
		component: Success,
	},
	{
		path: '/track-request/:number',
		component: TrackRequest,
	},
	{
		path: '*',
		component: NotFound,
	},
];

export default RouteObj;
