import React from 'react';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import { RiErrorWarningFill } from 'react-icons/ri';
interface IProps {
	label?: string;
	placeholder: string;
	type?: string;
	defaultValue?: string | number;
}

const OptionalField = ({
	label,
	placeholder,
	type,
	defaultValue,
	...otherProps
}: IProps & FieldHookConfig<string>) => {
	const [field, meta] = useField(otherProps);
	return (
		<div className="input__text__feild__gap form-group">
			<label
				className={`${
					meta.error && meta.touched ? 'input__label__error__color' : 'position-relative'
				}`}
				htmlFor={field.name}
			>
				{label}{' '}
				{/* <b className="text-danger position-absolute" style={{ marginLeft: '.2rem' }}>
					*
				</b> */}
			</label>

			<input
				className={`form-control `}
				{...field}
				type={type}
				placeholder={placeholder}
				defaultValue={defaultValue}
			/>
			{meta.touched && meta.error ? (
				<div className="input__error__icon">
					<RiErrorWarningFill />
				</div>
			) : (
				''
			)}
			<ErrorMessage component="div" name={field.name} className="input__error__container mb-3" />
		</div>
	);
};

export default React.memo(OptionalField);
