import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Field, Form, FormikProps } from 'formik';
import { VehicleRegistrationService } from 'services/utility-core-services';
import useUtilityCoreApi from 'hooks/useUtilityCoreApi';
import { SeachableDropDown, TextField } from 'components';
import { FullFormLayout } from 'containers';
import { toast } from 'react-toastify';

interface ValueProps {
	mobileNumber: string;
	vehicleNumber: string;
	vehicleType: string;
	lotNumber: string;
	symbol: string;
	province: string;
	zones: string;
	office: string;
}

const FORM_VALIDATION = Yup.object().shape({
	mobileNumber: Yup.string().required('Mobile Number is required'),
	vehicleNumber: Yup.string().required('Vehicle Number is required'),
	lotNumber: Yup.string().required('Lot Number is required'),
});
const VehicleRegistration: React.FC<{}> = () => {
	const [response, setResponse] = React.useState<any>([]);
	const [reference, setReference] = React.useState<any>('');
	const [officeList, setOfficeList] = React.useState<any>([]);
	const [provinceList, setProvinceList] = React.useState<any>([]);
	const [symbolList, setSymbolList] = React.useState<any>([]);
	const [vehiclesTypes, setVehiclesTypes] = React.useState<any>([]);
	const [zonesList, setZonesList] = React.useState<any>([]);
	// const [customerID, setCustomerID] = React.useState('');
	const { handleRequest } = useUtilityCoreApi();

	const handleGetVehicleRegistration = async () => {
		const res = await handleRequest(VehicleRegistrationService.postVehicleRegistration());
		setReference(res?.reference);
		setOfficeList(res?.successResponse?.detail?.offices);
		setProvinceList(res?.successResponse?.detail?.provinces);
		setSymbolList(res?.successResponse?.detail?.symbols);
		setVehiclesTypes(res?.successResponse?.detail?.vehicle_types);
		setZonesList(res?.successResponse?.detail?.zones);
	};
	useEffect(() => {
		handleGetVehicleRegistration();
	}, []);

	const handlePostVehicleRegistration = async (val: any) => {
		const data: any = {
			liscenseType: 'Zonal',
			// provinceId: String(val.province),
			officeCode: val.office,
			zone: val.zones,
			vehicleSymbol: String(val.symbol),
			lotNumber: String(val.lotNumber),
			vehicleNumber: String(val.vehicleNumber),
			vehicleType: String(val.vehicleType),
			mobileNumber: String(val.mobileNumber),
			reference: reference,
			taxOffice: '18',
		};

		const res = await handleRequest(VehicleRegistrationService.postVehicleRegistration1(data));
		setResponse(res);
	};
	if (!response.status && response.errorData) {
		toast.error(response.errorData.message);
		setResponse([]);
	}
	// const handlePaymentRequestInsurance = async () => {
	// 	const data: any = {
	// 		requestId: String(response?.requestId),
	// 		amount: response?.successResponse?.amount,
	// 		insuranceSlug: 'united-insurance',
	// 		reference: response.reference,
	// 		proformaNumber: response?.successResponse?.proforma_no,
	// 		insuranceType: 'United Insurance',
	// 	};

	// 	const res: any = await handleRequest(InsuranceService.postInsurance2(data));
	// 	if (res?.paymentGatewayUrl !== '' || null) {
	// 		const redirectUrl: any = res?.paymentGatewayUrl;
	// 		window.location.href = redirectUrl;
	// 	}
	// };
	const office =
		officeList &&
		officeList.map((e: any) => {
			return {
				label: e.name,
				value: e.code,
			};
		});
	const province =
		provinceList &&
		provinceList.map((e: any) => {
			return {
				label: e.name,
				value: e.id,
			};
		});
	const symbol =
		symbolList &&
		symbolList.map((e: any) => {
			return {
				label: e,
				value: e,
			};
		});
	const vehicle_types =
		vehiclesTypes &&
		vehiclesTypes.map((e: any) => {
			return {
				label: e.name,
				value: e.id,
			};
		});
	const zones =
		zonesList &&
		zonesList.map((e: any) => {
			return {
				label: e.name,
				value: e.code,
			};
		});

	const resultData = [
		{ label: 'Class Name', data: response?.successResponse?.class_name },
		{ label: 'insured', data: response?.successResponse?.insured },
		{ label: 'Amount', data: response?.successResponse?.amount },
		{ label: 'Sum Insured', data: response?.successResponse?.sum_insured },
		{ label: 'Tp Premium', data: response?.successResponse?.tp_premium },
	];
	return (
		<>
			<FullFormLayout
				heading="Vehicles  Registration"
				initialValues={{
					mobileNumber: '',
					vehicleNumber: '',
					lotNumber: '',
					symbol: '',
					province: '',
					zones: '',
					office: '',
				}}
				FORM_VALIDATION={FORM_VALIDATION}
				handleSubmitForm={(values: any) => handlePostVehicleRegistration(values)}>
				{({ resetForm, values, isValid, isSubmitting, setFieldValue }: FormikProps<ValueProps>) => (
					<Form>
						<div className="row">
							<div className="col-lg-6 col-md-6">
								<div className="electricity__form">
									<label className="input__label">
										Offices
										<b className="text-danger position-absolute" style={{ marginLeft: '.2rem' }}>
											*
										</b>
									</label>
									<Field
										className="custom-select"
										name="office"
										placeholder="Select a Office"
										isMulti={false}
										component={(props: any) => (
											<SeachableDropDown
												{...props}
												options={office}
												setFieldValue={setFieldValue}
											/>
										)}
									/>
									<div className="row">
										<div className="col-sm-6 my-3">
											<label className="input__label">
												Zones
												<b
													className="text-danger position-absolute"
													style={{ marginLeft: '.2rem' }}>
													*
												</b>
											</label>
											<Field
												className="custom-select"
												name="zones"
												placeholder="Select a Zone"
												isMulti={false}
												component={(props: any) => (
													<SeachableDropDown
														{...props}
														options={zones}
														setFieldValue={setFieldValue}
													/>
												)}
											/>
										</div>
										<div className="col-sm-6 my-3">
											<label className="input__label">
												Provinces
												<b
													className="text-danger position-absolute"
													style={{ marginLeft: '.2rem' }}>
													*
												</b>
											</label>
											<Field
												className="custom-select"
												name="province"
												placeholder="Select a Province"
												isMulti={false}
												component={(props: any) => (
													<SeachableDropDown
														{...props}
														options={province}
														setFieldValue={setFieldValue}
													/>
												)}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-6 my-3">
											<label className="input__label">
												Vehicle Symbol
												<b
													className="text-danger position-absolute"
													style={{ marginLeft: '.2rem' }}>
													*
												</b>
											</label>
											<Field
												className="custom-select"
												name="symbol"
												placeholder="Select a Vehicle Symbol"
												isMulti={false}
												component={(props: any) => (
													<SeachableDropDown
														{...props}
														options={symbol}
														setFieldValue={setFieldValue}
													/>
												)}
											/>
										</div>
										<div className="col-sm-6 my-3">
											<label className="input__label">
												Vehicle Types
												<b
													className="text-danger position-absolute"
													style={{ marginLeft: '.2rem' }}>
													*
												</b>
											</label>
											<Field
												className="custom-select"
												name="vehicleType"
												placeholder="Select a Vehicle Type"
												isMulti={false}
												component={(props: any) => (
													<SeachableDropDown
														{...props}
														options={vehicle_types}
														setFieldValue={setFieldValue}
													/>
												)}
											/>
										</div>
										<TextField
											label="Mobile Number"
											name="mobileNumber"
											placeholder="Mobile Number"
											type="number"
										/>
										<TextField
											label="Lot Number"
											name="lotNumber"
											placeholder="Lot Number"
											type="number"
										/>
										<p className="landline__helptext">E.g : 89 → BA 89 PA 405</p>
										<TextField
											label="Vehicle Number"
											name="vehicleNumber"
											placeholder="Vehicle Number"
											type="number"
										/>
										<p className="landline__helptext">E.g : 405 → BA 89 PA 405</p>
									</div>

									{!response.status && (
										<div className=" mt-3">
											<button
												className={`${
													isValid ? 'valid_btn ' : 'not_valid_btn'
												} btn btn-gradient--solid px-4 py-2 btn-sm btn--medium mt-0`}
												type="submit"
												disabled={!isValid || isSubmitting}>
												{isSubmitting ? (
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden="true"></span>
												) : (
													'Get Details'
												)}
											</button>
										</div>
									)}
									{/* {!response.status && response.errorData && (
										<div className="text-danger border-danger border p-2 rounded text-center mt-3">
											{response.errorData.details}
										</div>
									)} */}
									{response.status && (
										<div className="d-flex  mt-3 justify-content-end align-items-center">
											<button
												className="btn btn-danger ms-3 mt-3 mx-3"
												onClick={() => {
													// setCustomerID('reset');
													resetForm();
													setResponse('');
												}}>
												Reset
											</button>
											<div className=" mt-3">
												<button
													className={`${
														isValid ? 'valid_btn ' : 'not_valid_btn'
													} btn btn-gradient--solid px-4 py-2 btn-sm btn--medium mt-0`}
													onClick={() => console.log()}
													disabled={!isValid || isSubmitting}>
													{isSubmitting ? (
														<span
															className="spinner-border spinner-border-sm"
															role="status"
															aria-hidden="true"></span>
													) : (
														'Proceed'
													)}
												</button>
											</div>
										</div>
									)}
								</div>
							</div>

							{response?.status ? (
								<div className="col-lg-6 col-md-6 mt-3">
									<h4>Insurance Details</h4> <hr />
									{resultData.map((e, idx: number) => (
										<div className="row mb-2" key={idx}>
											<div className="col-lg-4 col-md-6">
												<label className="get__details">{e?.label}</label>{' '}
											</div>
											<div className="col-lg-8 col-md-6">
												<span className="get__details__results">{e?.data} </span>
											</div>
										</div>
									))}
								</div>
							) : (
								''
							)}
						</div>
					</Form>
				)}
			</FullFormLayout>
		</>
	);
};

export default VehicleRegistration;
