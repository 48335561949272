import { themeConstants } from 'redux/constant/theme.constant';

type Payload = {
	name: string;
	value: string;
};

interface ThemePayload {
	theme: Payload[];
	image: Payload[];
	contact: Payload[];
	socialLink: Payload[];
	appLink: Payload[];
	bankName: Payload[];
	footerSideMenu: Payload[];
}

interface ThemeState {
	isLoading: boolean;
	isError: boolean;
	themeSettings: { [key: string]: string };
	image: { [key: string]: string };
	contact: { [key: string]: string };
	socialLink: { [key: string]: string };
	appLink: { [key: string]: string };
	bankName: { [key: string]: string };
	footerSideMenu :{[key:string]: string};
}

const initState: ThemeState = {
	isLoading: false,
	isError: false,
	themeSettings: {},
	image: {},
	contact: {},
	socialLink: {},
	appLink: {},
	bankName: {},
	footerSideMenu:{}
};

export const themeReducer = (
	state: ThemeState = initState,
	{ type, payload }: { type: string; payload: ThemePayload }
) => {
	switch (type) {
		case themeConstants.GET_THEME_SETTINGS_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}
		case themeConstants.GET_THEME_SETTINGS_SUCCESS: {
			const obj1: any = {};
			const obj2: any = {};
			const obj3: any = {};
			const obj4: any = {};
			const obj5: any = {};
			const obj6: any = {};
			const obj7: any ={};
			payload.theme.forEach((item) => {
				obj1[item.name] = item.value;
			});
			payload.image.forEach((item) => {
				obj2[item.name] = item.value;
			});
			payload.contact.forEach((item) => {
				obj3[item.name] = item.value;
			});
			payload.socialLink.forEach((item) => {
				obj4[item.name] = item.value;
			});
			payload.appLink.forEach((item) => {
				obj5[item.name] = item.value;
			});
			payload.bankName.forEach((item) => {
				obj6[item.name] = item.value;
			});
			payload.footerSideMenu.forEach((item) => {
				obj7[item.name] = item.value;
			});
			return {
				...state,
				themeSettings: obj1,
				image: obj2,
				contact: obj3,
				socialLink: obj4,
				appLink: obj5,
				bankName: obj6,
				footerSideMenu: obj7,
				isLoading: false,
			};
		}
		case themeConstants.GET_THEME_SETTINGS_FAILED: {
			return {
				...state,
				isLoading: false,
				isError: true,
			};
		}
		default:
			return state;
	}
};
