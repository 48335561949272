/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Government_Payment_GovernmentPaymentEchalanDetailFormat } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Government_Payment_GovernmentPaymentEchalanDetailFormat';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Government_Payment_GovernmentPaymentEchalanRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Government_Payment_GovernmentPaymentEchalanRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class GovernmentPaymentService {

    /**
     * @param appId
     * @param voucherNumber
     * @param amount
     * @param fiscalyear
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Government_Payment_GovernmentPaymentEchalanDetailFormat Success
     * @throws ApiError
     */
    public static postGovernmentPayment(
        appId: string,
        voucherNumber?: string,
        amount?: number,
        fiscalyear?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Government_Payment_GovernmentPaymentEchalanDetailFormat> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/government-payment/government-payment-echalan-details/${appId}`,
            query: {
                'voucherNumber': voucherNumber,
                'amount': amount,
                'fiscalyear': fiscalyear,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postGovernmentPayment1(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Government_Payment_GovernmentPaymentEchalanRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/government-payment/government-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}