/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import usePhoneNumber from 'hooks/usePhoneNumber';

const imageUrl = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_IMAGE_ENDPOINT;

const Header = () => {
	const {
		image,
		contact: customerSupport,
		themeSettings,
		socialLink,
	} = useSelector((state: any) => state?.theme);

	const { headerMenuData } = useSelector((state: any) => state?.menu);

	const viberNumber = usePhoneNumber(socialLink?.ViberUrl);

	const [showSideBar, setShowSideBar] = useState<boolean>(false);
	return (
		<div>
			<header className="navbar border-bottom mob-header">
				<div className="container-fluid py-2">
					<div className="d-flex w-100 align-items-center justify-content-between">
						<div className="navbar-header  ">
							<a
								href="/"
								target="_blank"
								rel="noopener noreferrer"
								className="logo navbar-btn pull-left img-fluid">
								<img src={imageUrl + image?.SecondaryLogo || ''} alt="logo" height={32} />
							</a>

							<a href="/" rel="noopener noreferrer">
								<img
									className="img-fluid mx-5"
									src={imageUrl + image?.PrimaryLogo || ''}
									alt="logo"
									width={120}
								/>
							</a>
						</div>
						<div
							className="hambuger-menu text-right d-flex align-items-center"
							onClick={() => {
								setShowSideBar(!showSideBar);
							}}>
							<div className="hambuger-menu h4">
								<svg
									stroke="currentColor"
									fill="currentColor"
									strokeWidth={0}
									viewBox="0 0 16 16"
									className="text-danger"
									height="1em"
									width="1em"
									xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M14 5H2V3h12v2zm0 4H2V7h12v2zM2 13h12v-2H2v2z"
									/>
								</svg>
							</div>
						</div>
					</div>
				</div>
			</header>

			<div id="sidebar" className={`sidemenu ${showSideBar ? 'menu-opened' : 'menu-hide'}`}>
				<header className="navbar border-bottom">
					<div className="container-fluid py-2">
						<div className="d-flex  w-100 align-items-center justify-content-between">
							<div className="navbar-header">
								{!isEmpty(image?.SecondaryLogo) && (
									<a
										href="/"
										target="_blank"
										rel="noopener noreferrer"
										className="logo navbar-btn pull-left img-fluid">
										<img src={imageUrl + image?.SecondaryLogo} alt="logo" height={32} />
									</a>
								)}
								{!isEmpty(image?.PrimaryLogo) && (
									<a href="/" rel="noopener noreferrer">
										<img
											src={imageUrl + image?.PrimaryLogo}
											alt="logo"
											className="img-fluid mx-5"
											width={120}
										/>
									</a>
								)}
							</div>
							<div className=" text-right" onClick={() => setShowSideBar(!showSideBar)}>
								<div className="h4">
									<svg
										stroke="currentColor"
										fill="currentColor"
										strokeWidth={0}
										viewBox="0 0 512 512"
										className="text-danger"
										height="1em"
										width="1em"
										xmlns="http://www.w3.org/2000/svg">
										<path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</header>
				<div className="sidebarmenu-wrapper">
					{!isEmpty(headerMenuData) ? (
						<div className="accordion accordion-flush tertiary-menu mt-3" id="tertiary-menu">
							{headerMenuData.map((e: any, i: number) => (
								<>
									{!isEmpty(e?.childMenuList) ? (
										<div className="accordion-item" key={i}>
											<h2 className="accordion-header">
												<button
													className="accordion-button collapsed"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target={`#${e?.slug}`}
													aria-expanded="false"
													aria-controls={`${e?.slug}`}>
													{e?.displayName}
												</button>
											</h2>
											<div
												id={`${e?.slug}`}
												className="accordion-collapse collapse"
												data-bs-parent="#tertiary-menu">
												<div className="accordion-body p-0">
													<ul className="list-unstyled mb-0">
														{e?.childMenuList &&
															e?.childMenuList.map((sub: any, idx: number) => (
																<li className="inner-list-product" key={idx}>
																	<a href={sub?.url}>{sub?.displayName}</a>
																</li>
															))}
													</ul>
												</div>
											</div>
										</div>
									) : (
										<div key={i} className="accordion-nodrop">
											<a href={e?.url} className="d-block">
												{e?.displayName}
											</a>
										</div>
									)}
								</>
							))}
						</div>
					) : null}
					<div className="contact-info contact-number">
						{!isEmpty(customerSupport?.CustomerCarePhone) && (
							<>
								<label className="mb-2">Call</label>
								<a
									href={`tel:+977${customerSupport?.CustomerCarePhone}`}
									className="theme-primary mb-2">
									<svg
										stroke="currentColor"
										fill={`${themeSettings?.SecondaryColor}`}
										strokeWidth={0}
										viewBox="0 0 512 512"
										className="me-2"
										height="1em"
										width="1em"
										xmlns="http://www.w3.org/2000/svg">
										<path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
									</svg>{' '}
									+977{customerSupport?.CustomerCarePhone}
								</a>
							</>
						)}
						{!isEmpty(socialLink?.ViberUrl) && (
							<a href={`${socialLink?.ViberUrl}`} className="theme-primary">
								<svg
									stroke="currentColor"
									fill={`${themeSettings?.SecondaryColor}`}
									strokeWidth={0}
									viewBox="0 0 512 512"
									className="me-2"
									height="1em"
									width="1em"
									xmlns="http://www.w3.org/2000/svg">
									<path d="M444 49.9C431.3 38.2 379.9.9 265.3.4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9.4-85.7.4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9.4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9.6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4.7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5.9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9.1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7.5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1.8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z"></path>
								</svg>{' '}
								{viberNumber}
							</a>
						)}
					</div>
					{!isEmpty(customerSupport?.CustomerCareEmail) && (
						<div className="contact-info contact-number">
							<label className="mb-2">Email</label>
							<a
								href={`mailto:${customerSupport?.CustomerCareEmail}`}
								className="theme-primary mb-2 ">
								<svg
									stroke={`${themeSettings?.SecondaryColor}`}
									fill="none"
									strokeWidth={0}
									viewBox="0 0 24 24"
									className="me-2"
									height="1em"
									width="1em"
									xmlns="http://www.w3.org/2000/svg">
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
								</svg>
								{customerSupport?.CustomerCareEmail}
							</a>
						</div>
					)}
				</div>
			</div>
			<header className="position-relative">
				<div className="desktop-header top">
					<div className="container">
						<div className="navigation-wrapper">
							<div className="navigation-top">
								<div className="navigation-top--left">
									{!isEmpty(image?.PrimaryLogo) && (
										<a href="/" rel="noopener noreferrer">
											<img src={imageUrl + image?.PrimaryLogo} alt="logo" width={120} />
										</a>
									)}
								</div>
								<div className="navigation-top--right">
									<div className="navigation-dropdown lang-dropdown">
										{!isEmpty(customerSupport) && (
											<div className="dropdown py-3 px-0">
												<span
													style={{ padding: '9px 10px 12px' }}
													className="dropdown-toggle"
													role="button"
													data-bs-toggle="dropdown"
													aria-expanded="false">
													<a className="p-0" href={`tel:+977${customerSupport?.CustomerCarePhone}`}>
														+977{customerSupport?.CustomerCarePhone}
													</a>
												</span>
												<ul className="dropdown-menu dropdown-menu-end">
													<li>
														<a href={`${socialLink?.ViberUrl}`} className="dropdown-item py-2">
															<svg
																stroke="currentColor"
																fill="currentColor"
																strokeWidth={0}
																viewBox="0 0 512 512"
																className="me-2 h5 mb-0"
																height="1em"
																width="1em"
																xmlns="http://www.w3.org/2000/svg">
																<path d="M444 49.9C431.3 38.2 379.9.9 265.3.4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9.4-85.7.4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9.4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9.6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4.7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5.9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9.1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7.5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1.8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z"></path>
															</svg>
															{viberNumber}
														</a>
													</li>
													<li>
														<a
															href={`mailto:${customerSupport?.CustomerCareEmail}`}
															className="dropdown-item py-2">
															<svg
																stroke="currentColor"
																fill="none"
																strokeWidth={0}
																viewBox="0 0 24 24"
																className="me-2 h5 mb-0"
																height="1em"
																width="1em"
																xmlns="http://www.w3.org/2000/svg">
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	strokeWidth={2}
																	d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
															</svg>
															{customerSupport?.CustomerCareEmail}
														</a>
													</li>
												</ul>
											</div>
										)}
									</div>
									<div className="navigation-dropdown top-menu d-flex ms-0">
										{!isEmpty(headerMenuData)
											? headerMenuData.map((e: any, i: number) => (
													<div className="dropdown py-3 px-0" key={i}>
														<a
															data-bs-toggle="dropdown"
															aria-expanded="false"
															href={isEmpty(e?.childMenuList) ? `${e?.url}` : `javascript:void(0)`}
															className={`${!isEmpty(e?.childMenuList) ? 'dropdown-toggle' : ''}`}>
															{e?.displayName}
														</a>
														{!isEmpty(e?.childMenuList) && (
															<ul className="dropdown-menu dropdown-menu-end">
																{e?.childMenuList.map((sub: any, idx: number) => (
																	<li key={idx}>
																		<a href={sub?.url} className="dropdown-item py-2">
																			{sub?.displayName}
																		</a>
																	</li>
																))}
															</ul>
														)}
													</div>
											  ))
											: null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="desktop-header">
					<div className="container">
						<div className="navigation-wrapper">
							<div className="navigation-bottom d-flex justify-content-between  position-relative">
								{!isEmpty(image?.SecondaryLogo) && (
									<a
										href="/"
										target="_blank"
										rel="noopener noreferrer"
										aria-current="page"
										className="py-3 active">
										<figure className="mb-0" style={{ width: 120 }}>
											<img src={imageUrl + image?.SecondaryLogo} alt="logo" className="img-fluid" />
										</figure>
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
			</header>
		</div>
	);
};

export default React.memo(Header);
