import { authConstant, LogoutConstant } from '../constant/auth.constant';

const initState = {
	token: null,
	authenticate: false,
	authenticating: false,
	error: '',
	message: '',
};

export const authReducer = (state = initState, { type, payload }: any) => {
	switch (type) {
		case authConstant.LOGIN_REQUSET:
			state = {
				...state,
				authenticating: true,
			};
			break;
		case authConstant.LOGIN_SUCCESS:
			state = {
				...state,
				authenticating: false,
				token: payload,
				authenticate: true,
			};
			break;
		case authConstant.LOGIN_FAILED:
			state = {
				...state,
				authenticate: false,
			};
			break;

		case LogoutConstant:
			state = {
				...initState,
				message: payload,
			};
	}
	return state;
};
