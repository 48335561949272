import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApiError, OpenAPI } from '../services/utility-core-services';

export function useUtilityCoreApi() {
	const [error, setError] = useState<ApiError | undefined>(undefined);
	const [isLoading, setIsloading] = useState<boolean>(false);
	OpenAPI.BASE = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_API_ENDPOINT;
	const token = useSelector((state: any) => state.auth.token);
	const localToken = localStorage.getItem('token');
	OpenAPI.TOKEN = localToken || token;
	const handleRequest = useCallback(async function <T>(request: Promise<T>) {
		setIsloading(true);
		try {
			const response = await request;
			setError(undefined);
			return response;
		} catch (exception: any) {
			setError(exception);
		} finally {
			setIsloading(false);
		}
	}, []);

	function dismissError() {
		setError(undefined);
	}

	return { dismissError, error, isLoading, handleRequest };
}

export default useUtilityCoreApi;
