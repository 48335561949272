import { useEffect, useState } from 'react';

const usePhoneNumber = (url: any) => {
	const [phoneNumber, setPhoneNumber] = useState(null);

	useEffect(() => {
		const extractPhoneNumber = () => {
			const regex = /number=([^&]+)/;
			const match = url?.match(regex);

			if (match && match[1]) {
				setPhoneNumber(match[1]);
			} else {
				setPhoneNumber(null);
			}
		};

		extractPhoneNumber();
	}, [url]);

	return phoneNumber;
};

export default usePhoneNumber;
