import React from 'react';
import { GrContact } from 'react-icons/gr';
import { HiOutlineMail } from 'react-icons/hi';
import { viber } from '../../assets';
import { toast } from 'react-toastify';
import axios from '../../config';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import usePhoneNumber from 'hooks/usePhoneNumber';

const ContactUs = () => {
	const [contact, setContact] = React.useState([]);
	const { metaDataSource } = useSelector((state: any) => state?.menu);
	const username = window.__RUNTIME_CONFIG__.MENU_TYPE_API_USERNAME;
	const {
		themeSettings,
		contact: customerSupport,
		socialLink,
	} = useSelector((state: any) => state?.theme);
	const viberNumber = usePhoneNumber(socialLink?.ViberUrl);

	let data = new FormData();
	data.append('username', username);
	data.append('type', 'contact');
	const fetchFooterData = async () => {
		try {
			const res: any = await axios.post('/', data);
			setContact(res.data.data);
		} catch (err: any) {
			toast.error(err.response);
		}
	};

	React.useEffect(() => {
		fetchFooterData();
	}, []);
	return (
		<>
			{metaDataSource !== undefined && metaDataSource !== null
				? {
						nabil: <NabilContact contact={contact} />,
						system: (
							<DefaultContact
								contact={contact}
								themeSettings={themeSettings}
								customerSupport={customerSupport}
								socialLink={socialLink}
								viberNumber={viberNumber}
							/>
						),
}[metaDataSource as string]
				: ''}
		</>
	);
};

export default React.memo(ContactUs);

function NabilContact({ contact }: any) {
	return (
		<div className="d-flex align-items-center flex-column  mt-3 n-contact">
			{contact.length > 0 &&
				contact.map((e: any, i: number) => (
					<React.Fragment key={i}>
						<div className="d-flex align-items-center justify-content-center text-center">
							<div className="" style={{ fontSize: '16px', fontWeight: 400 }}>
								<GrContact color="#1aa659" className="me-2" />
								For customer service call:{' '}
								<a
									href={`tel:${e.name}`}
									className="text-gray"
									style={{
										fontSize: '14px',
										marginRight: '5px',
										color: '#1aa659',
									}}>
									{e?.name}
								</a>
								or <img className="viber__logo" src={viber} alt="" /> :
								<a
									href={`viber://chat?number=${e?.children[0]?.name}`}
									className="text-gray"
									style={{
										marginLeft: '5px',
										fontSize: '14px',
										color: '#1aa659',
									}}>
									{e?.children[0]?.name}
								</a>
							</div>
						</div>
						or <br />
						<div>
							<span>
								{' '}
								<HiOutlineMail /> Email:
							</span>
							<a
								href={`mailto:${e?.children[1]?.name}`}
								className="text-gray "
								style={{
									marginLeft: '5px',
									fontSize: '14px',
									color: '#1aa659',
								}}>
								{e?.children[1]?.name}
							</a>
						</div>
					</React.Fragment>
				))}
		</div>
	);
}

function DefaultContact({ contact, themeSettings, customerSupport, socialLink, viberNumber }: any) {
	return (
		<div className="d-flex align-items-center flex-column  mt-3 contact">
			{contact.length > 0 &&
				contact.map((e: any, i: number) => (
					<React.Fragment key={i}>
						<div className="d-flex align-items-center justify-content-center text-center">
							<div className="" style={{ fontSize: '16px', fontWeight: 400 }}>
								<GrContact color={`${themeSettings?.PrimaryColor}`} className="me-2" />
								For customer service call:{' '}
								{!isEmpty(customerSupport?.CustomerCarePhone) && (
									<a
										href={`tel:${customerSupport?.CustomerCarePhone}`}
										className="text-gray"
										style={{
											fontSize: '14px',
											marginRight: '5px',
											color: themeSettings?.PrimaryColor,
										}}>
										{customerSupport?.CustomerCarePhone}
									</a>
								)}
								{!isEmpty(socialLink?.ViberUrl) && (
									<>
										or <img className="viber__logo" src={viber} alt="" /> :
										<a
											href={`${socialLink?.ViberUrl}`}
											className="text-gray"
											style={{
												marginLeft: '5px',
												fontSize: '14px',
												color: themeSettings?.PrimaryColor,
											}}>
											{viberNumber}
										</a>
									</>
								)}
							</div>
						</div>
						or <br />
						<div>
							{!isEmpty(customerSupport?.CustomerCareEmail) && (
								<>
									<span>
										{' '}
										<HiOutlineMail /> Email:
									</span>
									<a
										href={`mailto:${customerSupport?.CustomerCareEmail}`}
										className="text-gray "
										style={{
											marginLeft: '5px',
											fontSize: '14px',
											color: themeSettings?.PrimaryColor,
										}}>
										{customerSupport?.CustomerCareEmail}
									</a>
								</>
							)}
						</div>
					</React.Fragment>
				))}
		</div>
	);
}
