/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityService_Setting_Dtos_DocumentDto } from '../models/Amnil_UtilityService_Setting_Dtos_DocumentDto';
import type { Amnil_UtilityService_Setting_Dtos_UtilityServiceBalanceDto } from '../models/Amnil_UtilityService_Setting_Dtos_UtilityServiceBalanceDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class SettingService {

    /**
     * @returns Amnil_UtilityService_Setting_Dtos_DocumentDto Success
     * @throws ApiError
     */
    public static getSetting(): CancelablePromise<Array<Amnil_UtilityService_Setting_Dtos_DocumentDto>> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/setting/file`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Amnil_UtilityService_Setting_Dtos_UtilityServiceBalanceDto Success
     * @throws ApiError
     */
    public static postSetting(): CancelablePromise<Array<Amnil_UtilityService_Setting_Dtos_UtilityServiceBalanceDto>> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/setting/utility-service-amount-range`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}