import React from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { MdArrowBackIosNew } from 'react-icons/md';

interface Iprops {
	heading?: string;
	handleSubmitForm?: any;
	initialValues?: any;
	FORM_VALIDATION?: {};
	children: any;
}

const FullFormLayout = ({
	children,
	heading,
	initialValues,
	FORM_VALIDATION,
	handleSubmitForm,
}: Iprops) => {
	const navigate = useNavigate();
	return (
		<div className="py-5 formLayout">
			<div className="container bg-lightgray p-3">
				<div
					className="d-inline-flex align-items-center back__button btn btn-sm "
					onClick={() => {
						navigate(-1);
					}}>
					<MdArrowBackIosNew size={12} />
					<span className="mx-2">Back</span>
				</div>
				<div className="py-4">
					<div className="row">
						<div className="col-md-12 col-sm-12">
							<h4 className="formHeading">{heading}</h4>
							<Formik
								initialValues={initialValues}
								validateOnMount={true}
								enableReinitialize={true}
								validationSchema={FORM_VALIDATION}
								onSubmit={handleSubmitForm}>
								{children}
							</Formik>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default React.memo(FullFormLayout);
