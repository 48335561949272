/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityService_PaymentIntegrations_Dtos_DLRCallBackRequestDto } from '../models/Amnil_UtilityService_PaymentIntegrations_Dtos_DLRCallBackRequestDto';
import type { Amnil_UtilityService_PaymentIntegrations_Dtos_DLRCallBackResponseDto } from '../models/Amnil_UtilityService_PaymentIntegrations_Dtos_DLRCallBackResponseDto';
import type { Amnil_UtilityService_PaymentIntegrations_Dtos_ResponseStatusCheckDto } from '../models/Amnil_UtilityService_PaymentIntegrations_Dtos_ResponseStatusCheckDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_NtcTopupRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_NtcTopupRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class TelecomService {

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static getTelecom(): CancelablePromise<Array<number>> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/telecom/smartcell-topup-amount`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postTelecom(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_NtcTopupRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/telecom/ntc-topup-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postTelecom1(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_NtcTopupRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/telecom/ncell-topup-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postTelecom2(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_NtcTopupRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/telecom/smart-cell-topup-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param referenceNumber
     * @returns Amnil_UtilityService_PaymentIntegrations_Dtos_ResponseStatusCheckDto Success
     * @throws ApiError
     */
    public static getTelecom1(
        referenceNumber?: string,
    ): CancelablePromise<Amnil_UtilityService_PaymentIntegrations_Dtos_ResponseStatusCheckDto> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/telecom/top-up-status-check`,
            query: {
                'referenceNumber': referenceNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_PaymentIntegrations_Dtos_DLRCallBackResponseDto Success
     * @throws ApiError
     */
    public static postTelecom3(
        requestBody?: Amnil_UtilityService_PaymentIntegrations_Dtos_DLRCallBackRequestDto,
    ): CancelablePromise<Amnil_UtilityService_PaymentIntegrations_Dtos_DLRCallBackResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/telecom/d-lRCall-back`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}