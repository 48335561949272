/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_KhanePaniDtos_CustomerResponseBillsDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_KhanePaniDtos_CustomerResponseBillsDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_KhanePaniDtos_CustomerServiceChargeResponseDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_KhanePaniDtos_CustomerServiceChargeResponseDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_KhanePaniDtos_KhanePaniCountersListDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_KhanePaniDtos_KhanePaniCountersListDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_KhanePaniDtos_KhanePaniPaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_KhanePaniDtos_KhanePaniPaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class KhanePaniService {

    /**
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_KhanePaniDtos_KhanePaniCountersListDto Success
     * @throws ApiError
     */
    public static getKhanePani(): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_KhanePaniDtos_KhanePaniCountersListDto> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/khane-pani/counters-details`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param monthId
     * @param customerCode
     * @param counter
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_KhanePaniDtos_CustomerResponseBillsDto Success
     * @throws ApiError
     */
    public static getKhanePani1(
        monthId?: string,
        customerCode?: string,
        counter?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_KhanePaniDtos_CustomerResponseBillsDto> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/khane-pani/consumer-pending-bills`,
            query: {
                'MonthId': monthId,
                'CustomerCode': customerCode,
                'Counter': counter,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postKhanePani(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_KhanePaniDtos_KhanePaniPaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/khane-pani/khane-pani-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param counter
     * @param amount
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_KhanePaniDtos_CustomerServiceChargeResponseDto Success
     * @throws ApiError
     */
    public static getKhanePani2(
        counter?: string,
        amount?: number,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_KhanePaniDtos_CustomerServiceChargeResponseDto> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/khane-pani/consumer-service-charge`,
            query: {
                'Counter': counter,
                'Amount': amount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}