import styled from 'styled-components';

export const ThemeProvider = styled.div`
	/* Page Loader CSS */
	.isLoadingContainer {
		height: 100vh;
		width: 100vw;
		display: grid;
		place-items: center;
	}
	.isLoading {
		color: #ffffff;
		font-size: 45px;
		text-indent: -9999em;
		overflow: hidden;
		width: 1em;
		height: 1em;
		border-radius: 50%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
	}

	@keyframes mltShdSpin {
		0% {
			box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
				0 -0.83em 0 -0.477em;
		}
		5%,
		95% {
			box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
				0 -0.83em 0 -0.477em;
		}
		10%,
		59% {
			box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
				-0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
		}
		20% {
			box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
				-0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
		}
		38% {
			box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em,
				-0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
		}
		100% {
			box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
				0 -0.83em 0 -0.477em;
		}
	}

	@keyframes round {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	input:focus,
	select:focus {
		border: 1.5px solid ${(props) => props.theme.PrimaryColor} !important;
		box-shadow: none !important;
		outline-offset: 0px;
		outline: none;
	}
	input {
		color: #111 !important;
	}

	.modal {
		top: 132px;
		border-radius: 20px;
	}
	.modal-content {
		border: none;
	}
	.modal-body {
		padding: 0rem;
		margin-top: -78px !important;
		padding: 0;
	}
	.modal-header {
		display: none;
	}
	.modal-backdrop.show {
		opacity: 0.8;
	}

	label {
		font-size: 1rem;
		font-weight: 400;
		font: 12px;
		color: #555151;
	}
	.bg-lightgray {
		background-color: #f8f8f8 !important;
	}

	input.form-control::placeholder {
		color: #d1cbc9;
	}
	.form-control.is-invalid,
	.was-validated .form-control:invalid {
		background-image: none;
	}

	.nav-link {
		color: #111111a8 !important;
	}

	.valid_btn {
		color: #ffffffff;
		margin-top: 20px;
		background: linear-gradient(90deg, #05af3f 30.15%, rgba(109, 218, 126, 0.16) 174.74%);
		border-radius: 10px;
		width: 100%;
		outline: none;
		padding: 9px;
		border: none;
	}
	.form-control.is-invalid:focus,
	.was-validated .form-control:invalid:focus {
		border-color: #dc3545;
		box-shadow: none !important;
		border: 2px solid red !important;
	}

	@media (min-width: 1400px) {
		.container-xxl,
		.container-xl,
		.container-lg,
		.container-md,
		.container-sm,
		.container {
			max-width: 1140px;
		}
	}

	.back__button {
		border: 1px solid ${(props) => props.theme.PrimaryColor};
		color: ${(props) => props.theme.PrimaryColor};
		&:hover {
			background: ${(props) => props.theme.PrimaryColor};
			color: #ffff;
		}
		&:focus {
			/* box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
			 */
			box-shadow: 0 0 0 0.25rem ${(props) => props.theme.PrimaryColor + 40};
		}
	}
	.not_valid_btn {
		color: #ffffffff;
		margin-top: 20px;
		background: ${(props) => props.theme.PrimaryColor + '7a'};
		// background: linear-gradient(90deg, #133f22 30.15%, rgba(15, 51, 21, 0.16) 174.74%);
		border-radius: 5px;
		width: 100%;
		outline: none;
		padding: 9px;
		border: none;
	}
	.mlr-15 {
		margin: 4px 8px;
	}
	.mtb-15 {
		margin: 1.2rem 0;
	}
	.text-grey {
		color: #3d3d3d;
	}

	.text-purple {
		color: #330994;
	}
	.form-control.is-valid:focus,
	.was-validated .form-control:valid:focus {
		box-shadow: none !important;
	}

	.text-title-grey {
		color: #555555;
	}
	.fw-800 {
		font-weight: 800;
	}
	a {
		text-decoration: none;
		-webkit-transition: all 0.2s linear;
		transition: all 0.2s linear;
	}
	.flexjustifybtwn {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	a:hover {
		text-decoration: none;
	}
	h1 {
		font-size: 56px;
		line-height: 1.2;
	}

	h2 {
		font-size: 40px;
		line-height: 1.2;
	}

	h3 {
		font-size: 32px;
		line-height: 1.2;
	}

	h4 {
		font-size: 1.3em;
		line-height: 1.08;
	}

	h5 {
		font-size: 18px;
		line-height: 1.2;
	}

	h6 {
		font-size: 14px;
		line-height: 1.2;
	}

	p {
		font-size: 16px;
		line-height: 1.6;
	}

	.text-purple {
		color: #42056b;
	}

	.text-d-gray {
		color: #575555;
	}

	.adv-search {
		padding: 10px 0;
		margin: 1.5rem 0 1rem;
		border-bottom: 1px solid #e7e7e7;
	}
	.adv-search .form-group {
		margin-bottom: 0;
	}

	.adv-search .form-group button {
		-webkit-box-shadow: none;
		box-shadow: none;
		white-space: nowrap;
	}

	.adv-search .form-group button:focus {
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.adv-search-input .input-group-text {
		border-right: 0;
		background: transparent;
	}

	.adv-search-input input[type='text'] {
		-webkit-transition: all 0.2s linear;
		transition: all 0.2s linear;
	}

	.adv-search-input input[type='text']::-webkit-input-placeholder {
		color: #aeb0b2;
	}

	.adv-search-input input[type='text']::-moz-placeholder {
		color: #aeb0b2;
	}

	.adv-search-input input[type='text']:-ms-input-placeholder {
		color: #aeb0b2;
	}
	.adv-search-input input[type='text']::-ms-input-placeholder {
		color: #aeb0b2;
	}

	.adv-search-input input[type='text']::placeholder {
		color: #aeb0b2;
	}

	.adv-search-input input[type='text']:focus {
		outline: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		border: 1px solid ${(props) => props.theme.PrimaryColor};
	}

	.filter-tags-wrapper .filter-tag {
		font-size: 12px;
	}

	.filter-tags-wrapper button {
		width: 18px;
		height: 18px;
	}

	.text-gray {
		color: dimgray;
	}
	.home__page__paybills__heading {
		font-size: 18px;
		color: ${(props) => props.theme.PrimaryColor};
		font-size: 30px;
		font-weight: 700;
		text-transform: capitalize;
	}

	.home__page__paybills__heading__left {
		color: ${(props) => props.theme.PrimaryColor};
		border-bottom: 3px solid ${(props) => props.theme.PrimaryColor};
		font-size: 22px;
		font-weight: 700;
		text-transform: capitalize;
		cursor: pointer;
	}
	.megamenu-dropdown {
		position: absolute;
		z-index: 5;
		background: #fff;
		left: 0;
		right: 0;
		padding: 15px;
		border-top: 1px solid #e0e0e0;
		top: 100%;
		border-radius: 0 0 5px 5px;
		-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}
	.slick-next {
		padding: 21px !important;
		height: 126px !important;
		// background: linear-gradient(
		// 	90deg,
		// 	#05af3f 30.15%,
		// 	rgba(109, 218, 126, 0.16) 174.74%
		// ) !important;
		background: ${(props) => props.theme.PrimaryButtonColor};
		margin-right: -17px;
	}
	@media only screen and (max-width: 594px) {
		.slick-next {
			margin-right: 25px;
		}
	}
	.slick-next:before,
	.slick-prev:before {
		font-size: 23px;
		line-height: 1;
		opacity: 0.75;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 2222222;
		opacity: 22;
		opacity: 222 !important;
	}
	.slick-prev {
		padding: 21px !important;
		height: 126px !important;
		background: ${(props) => props.theme.PrimaryButtonColor};
		margin-left: -17px;
	}

	.pack__descriptions {
		font-size: 14px;
		font-weight: 400;
		color: #00000099;
	}
	.pack__heading {
		color: #000000de;
		font-size: 14px;
		font-weight: 700;
		margin: 0;
		padding-bottom: 2px;
	}
	.pack__descriptions {
		font-size: 14px;
		font-weight: 400;
		color: #00000099;
	}

	.package__validity {
		color: rgb(255, 255, 255);
		position: absolute;
		right: 0px;
		left: 0px;
		bottom: 0px;
		max-width: 60px;
		font-size: 12px;
		text-align: center;
		font-weight: bold;
	}
	.imgWrap {
		position: relative;
	}

	@media screen and (max-width: 426px) {
		.datapack-card .amount {
			font-size: 20px;
			font-weight: bold;
			margin-top: 0.5rem;
		}
		.datapack-card .pack-button {
			width: 100%;
			margin-top: 0.5rem;
		}
		.datapack-card .col-12 {
			padding: 0;
		}
		.nav-pills .nav-link {
			font-size: 12px;
			font-weight: normal;
		}
	}
	.ncell__pack__see_more {
		margin-top: 5px;
		display: block;
	}
	.ncell__pack__hide__see_more {
		margin-top: 5px;

		display: none;
	}

	@media only screen and (max-width: 594px) {
		.slick-prev {
			margin-left: 25px;
		}
	}

	.slick-dots li button:before {
		color: ${(props) => props.theme.PrimaryColor} !important;
	}

	.slick-dots {
		left: 50%;
		transform: translateX(-50%);
	}

	@media all and (max-width: 595px) {
		.services__slider {
			padding-left: 45px;
			padding-right: 45px;
		}
	}
	.services__slider .slick-track {
		display: flex !important;
	}
	.services__slider .slick-track .slick-slide {
		height: inherit !important;
	}

	.services__slider .slick-track .slick-slide > div:nth-child(1) {
		height: 100%;
	}

	.home__page__paybills__slide {
		padding: 20px;
		border-style: dashed;
		display: flex;
		align-items: center;
		justify-content: center;
		border-width: 1px;
		border-color: #1111113d;
		cursor: pointer;
		margin: 0 10px !important;
	}
	.home__page__paybills__slide__title {
		font-size: 15px;
	}

	@media screen and (min-width: 426px) {
		.megamenu-dropdown .col-md-8 > ul {
			-webkit-column-count: 2;
			-moz-column-count: 2;
			column-count: 2;
		}
	}

	@media screen and (min-width: 1200px) {
		.megamenu-dropdown .col-md-8 > ul {
			-webkit-column-count: 3;
			-moz-column-count: 3;
			column-count: 3;
		}
	}

	.hasdropdown {
		display: block;
	}

	.hasdropdown:hover > a:after {
		content: '';
		height: 3px;
		width: 90%;
		position: absolute;
		bottom: 5px;
		left: 5px;
		background: #ee1d25;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		margin: 0 auto;
		-webkit-transition: all 0.2s linear;
		transition: all 0.2s linear;
	}

	.dropdown-img-holder {
		position: relative;
	}
	.header__after__click {
		position: absolute;
		flex-direction: column;
		list-style-type: none;
		right: 0;
		background: #fff;
		border-top: 2px solid ${(props) => props.theme.SecondaryColor};
		box-shadow: 0 0 10px -3px rgb(0 0 0 / 20%);
		z-index: 100;
		border-radius: 0 0 5px 5px;
		overflow: hidden;
		margin-top: 5px;
		top: 40px;
	}
	.dropdown-img-holder:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		background: linear-gradient(144.47deg, #05b259 19.36%, rgba(196, 196, 196, 0) 76.5%);
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	.dropdown-img-holder h4 {
		position: absolute;
		top: 30px;
		left: 25px;
		color: #fff;
		z-index: 5;
		font-weight: bold;
		width: 45%;
		letter-spacing: 1px;
		font-size: 24px;
		white-space: normal;
	}

	.dropdown-img {
		height: 300px;
		-o-object-fit: cover;
		object-fit: cover;
	}

	.dropdown-link {
		color: #615c5c !important;
		font-size: 15px;
		border-bottom: 3px solid #ee1d25;
		padding-bottom: 3px;
		margin-bottom: 10px;
		display: inline-block;
		font-weight: bold;
	}

	.dropdown-item:hover {
		background: none;
	}

	.dropdown-sublist li {
		line-height: 18px;
		margin-bottom: 12px;
	}

	.dropdown-sublist li a {
		font-size: 14px;
		color: dimgray;
		white-space: break-spaces !important;
	}

	.dropdown-sublist li a:hover {
		color: #333;
	}

	.btnSearch {
		color: #05b259;
	}

	.btnSearch:focus {
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.btnSearch-active {
		background: ${(props) => props.theme.PrimaryColor};
		border-radius: 0;
	}

	.btnSearch-active svg {
		color: #fff;
	}

	.search-input {
		width: 100%;
		height: 40px;
		margin-top: 8px;
		text-indent: 10px;
		border: 1px solid #ebebeb;
		border-radius: 4px;
		padding-right: 100px;
	}
	.form-control.is-valid,
	.was-validated .form-control:valid,
	.search-input:focus {
		outline: none;
		border-color: ${(props) => props.theme.PrimaryColor};
	}

	.search-wrapper {
		position: absolute;
		right: 45px;
		top: 0;
		width: 100%;
		max-width: 765px;
		z-index: 5;
		-webkit-transition: all 0.2s linear;
		transition: all 0.2s linear;
		background: #fff;
	}

	.search-wrapper-wrap {
		width: 100%;
	}

	.search-wrapper-btnwrap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		white-space: nowrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-right: 10px;
		color: #bdbdbd;
		position: absolute;
		top: 55%;
		right: 0;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.search-wrapper-btnwrap span {
		font-size: 14px;
	}

	.search-lists-wrapper {
		background: #fff;
		padding: 10px;
		margin-top: 5px;
		-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 4px;
		border: 1px solid #ebebeb;
		max-height: 300px;
		overflow: hidden;
	}

	.search-list {
		margin-bottom: 0.5rem;
	}

	.search-list-data {
		width: calc(100% - 50px);
	}

	.search-list-data h5 {
		color: #4d5359;
		margin-bottom: 3px;
		font-weight: 700;
		font-size: 14px;
		white-space: pre-wrap;
	}

	.search-list-data p {
		white-space: pre-wrap;
		margin-bottom: 0;
	}

	.search-list a {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.search-list a figure {
		margin-bottom: 0;
		background: #f0f0f0;
		border-radius: 4px;
		min-width: 50px;
		min-height: 50px;
		margin-right: 10px;
	}

	.search-list a img {
		width: 50px;
		height: 50px;
		-o-object-fit: cover;
		object-fit: cover;
	}

	.quick-links-wrapper {
		text-align: right;
	}

	.quick-links-wrapper h6 {
		font-size: 14px;
	}

	.quick-links-lists {
		line-height: 18px;
	}

	.quick-links-lists a {
		font-size: 12px;
	}

	@media screen and (max-width: 992px) {
		main {
			padding-top: 69px;
		}
	}

	a:hover {
		cursor: pointer;
	}

	.product-box-main-title a {
		color: #212529;
	}

	.product-box-main-title a:hover {
		color: #d52933 !important;
		text-decoration: underline;
	}

	.font-roboto {
		font-family: 'Roboto', sans-serif;
	}

	.searchRow {
		height: 100%;
		border: 1px solid #e4e4e4;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: 1rem;
		border-radius: 4px;
	}
	.searchRow_top {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.searchRow_label {
		background-color: rgba(0, 51, 78, 0.1);
		color: ${(props) => props.theme.PrimaryColor};
		display: inline-block;
		font-size: 11px;
		line-height: 1;
		padding: 5px 8px;
		text-transform: uppercase;
		margin-bottom: 10px;
	}

	.searchRow_link {
		margin-bottom: 10px;
		color: #137a41;
		font-weight: bold;
	}

	.searchRow_link:hover {
		color: #17904d;
	}

	.searchRow_link,
	.searchRow_desc {
		line-height: 1.2;
	}

	.searchRow_desc {
		font-weight: 300;
		font-size: 18px;
	}

	.searchRow_btnWrap button {
		color: #00334e;
		font-size: 12px;
		font-size: 0.8em;
		margin: 0;
		-webkit-transition: all 0.3s linear 0s;
		transition: all 0.3s linear 0s;
	}

	.searchRow_topText {
		margin-bottom: 30px;
	}

	.searchRow_topText p {
		font-weight: 300;
		font-size: 14px;
		font-size: 0.93333em;
		margin-bottom: 0;
		color: #8f8e8e;
	}

	.btn {
		font-family: 'Roboto', sans-serif;
	}

	.btn--medium {
		border-radius: 4px;
		-webkit-transition: 0.2s all linear;
		transition: 0.2s all linear;
		padding: 12px 15px;
	}

	.btn--medium.icon {
		position: relative;
		padding-right: 35px;
	}

	.btn--medium.icon:before,
	.btn--medium.icon:after {
		content: '';
		position: absolute;
		-webkit-transition: 0.2s all linear;
		transition: 0.2s all linear;
		top: 50%;
	}

	.btn--medium.icon:before {
		width: 9px;
		height: 9px;
		right: 15px;
		-webkit-transform: translateY(-50%) rotate(45deg);
		transform: translateY(-50%) rotate(45deg);
	}

	.btn--medium.icon:after {
		border: 4px solid transparent;
		right: 15px;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.btn--medium.btn-success--outline,
	.btn--medium.btn-blank--solid {
		background: #fff;
		color: #1a1e1e;
	}

	.btn--medium.btn-success--outline:hover,
	.btn--medium.btn-blank--solid:hover {
		color: #fff;
		background-color: ${(props) => props.theme.PrimaryColor};
	}

	.btn--medium.btn-success--outline.icon:before,
	.btn--medium.btn-blank--solid.icon:before {
		border-top: 2px solid #d52933;
		border-right: 2px solid #d52933;
	}

	.btn--medium.btn-success--outline.icon:after,
	.btn--medium.btn-blank--solid.icon:after {
		border-left-color: ${(props) => props.theme.PrimaryColor};
	}

	.btn--medium.btn-primary--solid {
		background: #d52933;
		color: #fff;
	}

	.btn--medium.btn-primary--solid.icon:before {
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
	}

	.btn--medium.btn-primary--solid.icon:after {
		border-left-color: #fff;
	}

	.btn--medium.btn-primary--outline {
		background: #fff;
		color: #1a1e1e;
		border: 1.5px solid #d52933;
	}

	.btn--medium.btn-success--solid {
		background: ${(props) => props.theme.PrimaryColor};
		color: #fff;
	}

	.btn--medium.btn-success--solid:hover {
		color: #fff;
	}

	.btn--medium.btn-success--solid.icon:before {
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
	}

	.btn--medium.btn-success--solid.icon:after {
		border-left-color: #fff;
	}

	.btn--medium.btn-success--outline {
		border: 1.5px solid ${(props) => props.theme.PrimaryColor};
		color: ${(props) => props.theme.PrimaryColor};
	}

	.btn--medium.btn-blank--outline {
		background: ${(props) => props.theme.PrimaryColor};
		color: #fff;
		border: 1.5px solid #fff;
	}

	.btn--medium.btn-gradient--solid {
		color: ${(props) => props.theme.ButtonTextColor};
		background: ${(props) => props.theme.PrimaryButtonColor};
		// background: linear-gradient(
		// 	87.64deg,
		// 	${(props) => props.theme.PrimaryColor} 16.81%,
		// 	#6dda7e 147.4%
		// );
	}

	.btn--medium.btn-gradient--solid.icon:before {
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
	}

	.btn--medium.btn-gradient--solid.icon:after {
		border-left-color: #fff;
	}

	.btn--medium:hover {
		color: #fff;
		-webkit-box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.1);
		box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.1);
		top: -0.5px;
	}

	.btn--medium:hover.icon:before,
	.btn--medium:hover.icon:after {
		content: '';
		right: 13px;
	}

	.button-cover {
		margin: 0 20px;
		background-color: #e2e2e2;
		border-radius: 24px;
		padding: 3px;
	}

	#style-3::-webkit-scrollbar-track {
		background-color: #b0fad6;
	}

	#style-3::-webkit-scrollbar {
		width: 4px;
		background-color: #00b25a;
		border-radius: 4px;
	}

	#style-3::-webkit-scrollbar-thumb {
		background-color: #00b25a;
	}

	.custom-scrollbar::-webkit-scrollbar,
	.react-datepicker__time-list::-webkit-scrollbar {
		width: 3px;
	}

	.custom-scrollbar::-webkit-scrollbar-track,
	.react-datepicker__time-list::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(26, 166, 89, 0.3);
		box-shadow: inset 0 0 6px rgba(26, 166, 89, 0.3);
	}

	.custom-scrollbar::-webkit-scrollbar-thumb,
	.react-datepicker__time-list::-webkit-scrollbar-thumb {
		background-color: ${(props) => props.theme.PrimaryColor};
		outline: 1px solid ${(props) => props.theme.PrimaryColor};
	}

	.member-scrollbar::-webkit-scrollbar {
		width: 4px;
	}

	.member-scrollbar::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(26, 166, 89, 0.3);
		box-shadow: inset 0 0 6px rgba(26, 166, 89, 0.3);
	}

	.member-scrollbar::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.4);
		outline: 1px solid rgba(0, 0, 0, 0.4);
	}

	.input-group span.text-danger,
	.form-group span.text-danger {
		font-size: 13px;
	}

	.form-control {
		border-radius: 0;
	}

	.form-control:focus {
		border-color: ${(props) => props.theme.PrimaryColor};
		-webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0);
		box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0);
	}

	.requiredMsg {
		font-size: 13px;
	}

	.formLayout-footer {
		background: #fff;
		margin: -24px -40px;
		padding: 30px 16px;
		border-top: 1px solid #d3d3d3;
	}

	.productCard-wrapper .productCard-details.open {
		-webkit-box-flex: 0;
		-ms-flex: none;
		flex: none;
		height: auto;
	}

	.productCard-wrapper .productCard-title {
		font-size: 20px;
		color: #333;
		font-weight: bold;
		margin-bottom: 8px;
		display: block;
	}

	.productCard-wrapper .productCard-meta {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		line-height: 18px;
	}

	.productCard-wrapper .productCard-meta span:nth-child(1) {
		font-size: 14px;
	}

	.productCard-wrapper .productCard-meta span:nth-child(2) {
		font-size: 18px;
		font-weight: bold;
		text-transform: capitalize;
	}

	.productCard-wrapper .productCard-meta-list {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.productCard-wrapper .productCard-link-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		margin-top: 18px;
		padding-bottom: 18px;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		-webkit-box-align: end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.productCard-wrapper .productCard-link-wrapper a {
		font-size: 14px;
		color: #1a1e1e;
		position: relative;
		position: relative;
		padding-right: 20px;
	}

	.productCard-wrapper .productCard-link-wrapper a:before,
	.productCard-wrapper .productCard-link-wrapper a:after {
		content: '';
		position: absolute;
		-webkit-transition: 0.2s all linear;
		transition: 0.2s all linear;
		top: 50%;
	}

	.productCard-wrapper .productCard-link-wrapper a:before {
		width: 9px;
		height: 9px;
		right: 5px;
		-webkit-transform: translateY(-50%) rotate(45deg);
		transform: translateY(-50%) rotate(45deg);
		border-top: 2px solid #d52933;
		border-right: 2px solid #d52933;
	}

	.productCard-wrapper .productCard-link-wrapper a:after {
		border: 4px solid transparent;
		right: 5px;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		border-left-color: ${(props) => props.theme.PrimaryColor};
	}

	.productCard-wrapper .productCard-btn {
		background: #f7f7f7;
		color: #2f004f;
		font-size: 14px;
		border: none;
		padding: 14px;
		width: 100%;
		font-weight: bold;
	}

	.productCard-wrapper .productCard-content {
		border-top: 1px solid #e8e8e8;
		padding: 24px 0;
	}

	.productCard-wrapper .productCard-content p {
		color: #333;
		font-size: 14px;
	}

	.productCard-wrapper .productCard-content ul {
		color: #000;
		font-size: 14px;
		list-style: none;
		padding: 0;
	}

	.productCard-wrapper .productCard-content ul li {
		padding-left: 1.5rem;
		margin-bottom: 25px;
		position: relative;
	}

	.productCard-wrapper .productCard-content ul li:before {
		content: '';
		width: 10px;
		height: 6px;
		border-left: 1px solid ${(props) => props.theme.PrimaryColor};
		border-bottom: 1px solid ${(props) => props.theme.PrimaryColor};
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
		position: absolute;
		left: 5px;
		top: 5px;
	}

	.productCard-wrapper .productCard-collapse-detail {
		position: absolute;
		background: #fff;
		padding: 1rem;
		z-index: 1;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 1;
		background: #fff;
		overflow: hidden;
		-webkit-transition: -webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
		transition: -webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
		transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
		transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1),
			-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
		-webkit-transform-origin: left top;
		transform-origin: left top;
		opacity: 0;
		visibility: hidden;
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
		-webkit-transform: rotateX(-75deg);
		transform: rotateX(-75deg);
		border: 1px solid #eee;
		margin-left: -1px;
		margin-right: -1px;
		border-top: 0;
	}

	.productCard-wrapper .productCard-collapse-detail.open {
		-webkit-transition: -webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
		transition: -webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
		transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
		transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1),
			-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
		color: #fff;
		opacity: 1;
		visibility: visible;
		-webkit-transform: rotateX(0deg);
		transform: rotateX(0deg);
	}

	.grid-card-link-group {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;
	}

	@media screen and (min-width: 486px) and (max-width: 1091px) {
		.banner-container {
			position: absolute;
			bottom: -4em;
		}
	}

	@media screen and (max-width: 1280px) {
		.banner-container {
			position: relative;
			bottom: 0;
			padding: 1rem;
		}

		.banner-container .col-lg-6 {
			width: 100%;
			max-width: 100%;
			-webkit-box-flex: 0;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
		}

		.category-wrapper .slick-list {
			width: 100%;
			margin: 0 auto;
		}

		.category-wrapper-item {
			background: #fff;
			border-radius: 5px;
			padding: 1rem;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			flex-direction: row;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			text-align: center;
			height: 70px !important;
			margin: 5px;
		}

		.category-wrapper-item img {
			max-width: 40px;
			height: 30px;
			padding: 0 !important;
			margin-right: 10px;
		}

		.category-wrapper-item span {
			font-size: 16px;
			line-height: 20px;
			margin-top: 0;
			color: #333;
			text-align: left;
		}
	}

	.search-bar button,
	.search-bar input {
		line-height: 1.5em;
	}

	.search-bar input {
		color: #171717;
	}

	.search-bar {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	.search-bar input,
	.search-btn,
	.search-btn:before,
	.search-btn:after {
		-webkit-transition: all 0.25s ease-out;
		transition: all 0.25s ease-out;
	}

	.search-bar input,
	.search-btn {
		width: 2em;
		height: 2em;
	}

	.search-bar input:invalid:not(:focus),
	.search-btn {
		cursor: pointer;
	}

	.search-bar,
	.search-bar input:focus,
	.search-bar input:valid {
		width: 100%;
	}

	.search-bar input:focus,
	.search-bar input:not(:focus) + .search-btn:focus {
		outline: transparent;
	}

	.search-bar {
		margin: auto;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		max-width: 30em;
	}

	.search-bar input {
		background: transparent;
		border-radius: 1.5em;
		-webkit-box-shadow: 0 0 0 0.25em #28a745 inset;
		box-shadow: 0 0 0 0.25em #28a745 inset;
		padding: 0.75em;
		-webkit-transform: translate(0.5em, 0.5em) scale(0.5);
		transform: translate(0.5em, 0.5em) scale(0.5);
		-webkit-transform-origin: 100% 0;
		transform-origin: 100% 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		position: absolute;
		right: 0;
		border: none;
	}

	.search-bar input::-webkit-search-decoration {
		-webkit-appearance: none;
	}

	.search-bar input:focus,
	.search-bar input:valid {
		background: #fff;
		border-radius: 0.375em 0 0 0.375em;
		-webkit-box-shadow: 0 0 0 0.1em #d9d9d9 inset;
		box-shadow: 0 0 0 0.1em #d9d9d9 inset;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	.search-btn {
		background: #28a745;
		border-radius: 0;
		border: none;
		padding: 1px;
		position: relative;
		-webkit-transform: translate(0.25em, 0.25em) rotate(45deg) scale(0.25, 0.08);
		transform: translate(0.25em, 0.25em) rotate(45deg) scale(0.25, 0.08);
		-webkit-transform-origin: 120% 70%;
		transform-origin: 120% 70%;
	}

	.search-btn:before,
	.search-btn:after {
		content: '';
		display: block;
		opacity: 0;
		position: absolute;
	}

	.search-btn:before {
		border-radius: 50%;
		-webkit-box-shadow: 0 0 0 0.2em #f1f1f1 inset;
		box-shadow: 0 0 0 0.2em #f1f1f1 inset;
		top: 0.75em;
		left: 0.75em;
		width: 1.2em;
		height: 1.2em;
	}

	.search-btn:after {
		background: #f1f1f1;
		border-radius: 0 0.25em 0.25em 0;
		top: 51%;
		left: 51%;
		width: 0.75em;
		height: 0.25em;
		-webkit-transform: translate(0.2em, 0) rotate(45deg);
		transform: translate(0.2em, 0) rotate(45deg);
		-webkit-transform-origin: 0 50%;
		transform-origin: 0 50%;
	}

	.search-btn span {
		display: inline-block;
		overflow: hidden;
		width: 1px;
		height: 1px;
	}

	.search-bar input:focus + .search-btn,
	.search-bar input:valid + .search-btn {
		background: ${(props) => props.theme.PrimaryColor};
		border-radius: 0 0.375em 0.375em 0;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	.search-bar input:focus + .search-btn:before,
	.search-bar input:focus + .search-btn:after,
	.search-bar input:valid + .search-btn:before,
	.search-bar input:valid + .search-btn:after {
		opacity: 1;
	}

	.search-bar input:focus + .search-btn:hover,
	.search-bar input:valid + .search-btn:hover,
	.search-bar input:valid:not(:focus) + .search-btn:focus {
		background: ${(props) => props.theme.PrimaryColor};
	}

	.search-bar input:focus + .search-btn:active,
	.search-bar input:valid + .search-btn:active {
		-webkit-transform: translateY(1px);
		transform: translateY(1px);
	}

	@media screen and (prefers-color-scheme: dark) {
		input {
			color: #f1f1f1;
		}

		.search-bar input {
			-webkit-box-shadow: 0 0 0 0.4em #f1f1f1 inset;
			box-shadow: 0 0 0 0.4em #f1f1f1 inset;
		}

		.search-bar input:focus,
		.search-bar input:valid {
			background: #3d3d3d;
			-webkit-box-shadow: 0 0 0 0.1em #3d3d3d inset;
			box-shadow: 0 0 0 0.1em #3d3d3d inset;
		}

		.search-btn {
			background: #f1f1f1;
		}
	}

	.sidemenu {
		background: #fff;
		display: block;
		padding: 0;
		margin: 0;
		position: absolute;
		right: 100%;
		top: 0;
		bottom: 0;
		z-index: 10;
		-webkit-font-smoothing: antialiased;
		-webkit-transition: all 0.4s ease;
		transition: all 0.4s ease;
		visibility: hidden;
		opacity: 0;
	}
	@media screen and (min-width: 992px) {
		body {
			padding-top: 0;
		}
	}

	.mob-header {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 30;
		background: ${(props) => props.theme.HeaderBackgroundColor};
	}

	@media screen and (min-width: 992px) {
		.mob-header {
			display: none;
		}
	}

	.desktop-header {
		display: none;
	}

	@media screen and (min-width: 992px) {
		.desktop-header {
			display: block;
			top: 0;
			width: 100%;
			z-index: 100;
		}
		.desktop-header.top {
			background: ${(props) => props.theme.HeaderBackgroundColor};
		}
	}

	.desktop-header .navigation-bottom {
		min-height: 57px;
	}

	.desktop-header.header-sticky {
		position: fixed;
		top: 49px;
		border-bottom: 1px solid #e7e7e7;
	}

	.desktop-header.header-stickyTop {
		position: fixed;
		top: 0;
		z-index: 101;
	}
	#sidebar {
		width: 100%;
	}
	.rotate__plane {
		transform: rotate(180deg);
	}

	#sidebar.menu-opened {
		background-color: #fff;
		border: 0;
		position: fixed;
		color: #fff;
		-webkit-transition: right 0.8s ease 0s;
		transition: right 0.8s ease 0s;
		overflow-y: auto;
		right: 0;
		z-index: 30;
		visibility: visible;
		opacity: 1;
	}

	.sidebarmenu-wrapper {
		margin: 0 20px;
	}

	.sidebarmenu-wrapper .listview.primary-menu {
		margin: 15px auto;
	}

	.sidebarmenu-wrapper .listview.primary-menu .nav-item {
		position: relative;
	}

	.sidebarmenu-wrapper .listview.primary-menu .nav-item .nav-link {
		font-size: 1rem;
		line-height: normal;
		padding: 8px 0;
		border-bottom: 0;
		color: #00334e;
	}

	.sidebarmenu-wrapper .listview.primary-menu .nav-item .nav-link.active {
		color: #d52933;
		font-weight: bold;
	}

	.sidebarmenu-wrapper .listview.secondary-menu .nav-item {
		position: relative;
	}

	.sidebarmenu-wrapper .listview.secondary-menu .nav-item .lvl-toggle-btn {
		background-color: transparent;
		border: none;
		position: absolute;
		top: 12px;
		right: 25px;
		padding: 0;
		margin: 0;
		cursor: pointer;
		height: 20px;
		width: 20px;
		z-index: -1;
	}

	.sidebarmenu-wrapper .listview.secondary-menu .nav-item:not(:last-of-type) .nav-link {
		border-bottom: 1px solid #e4e4e4;
	}

	.sidebarmenu-wrapper .listview.secondary-menu .nav-item .nav-link {
		background-color: transparent;
		color: #00334e;
		font-size: 1.067em;
		font-style: normal;
		font-weight: normal;
		letter-spacing: normal;
		line-height: normal;
		padding: 15px 0;
		position: relative;
		text-decoration: none;
		text-transform: capitalize;
	}

	.sidebarmenu-wrapper .listview.secondary-menu .nav-item .nav-link:hover {
		cursor: pointer;
	}

	.sidebarmenu-wrapper .listview.tertary-menu {
		background: #eee;
	}

	.sidebarmenu-wrapper .listview.tertary-menu .nav-item {
		position: relative;
	}

	.sidebarmenu-wrapper .listview.tertary-menu .nav-item .lvl-toggle-btn {
		background-color: transparent;
		border: none;
		position: absolute;
		top: 12px;
		right: 25px;
		padding: 0;
		margin: 0;
		cursor: pointer;
		height: 20px;
		width: 20px;
		z-index: 0;
	}

	.sidebarmenu-wrapper .listview.tertary-menu .nav-item .nav-link {
		background-color: transparent;
		border-bottom: 1px solid #e4e4e4;
		color: #00334e;
		font-size: 1.067em;
		font-style: normal;
		font-weight: normal;
		letter-spacing: normal;
		line-height: normal;
		padding: 15px;
		position: relative;
		text-decoration: none;
		text-transform: capitalize;
	}

	.sidebar-search-wrapper {
		padding: 0px 15px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		width: 100%;
		height: 40px;
		border-radius: 2px;
		border: 1px solid gray;
		margin-top: 1rem;
	}

	.sidebar-search-wrapper input {
		width: 100%;
		border: none;
		outline: none;
	}

	.contact-info {
		margin: 1rem 0;
	}

	.contact-info label {
		display: block;
		margin-bottom: 5px;
		color: #00334e;
	}

	.contact-info a {
		display: block;
	}

	.contact-info a svg {
		font-size: 28px;
	}

	.contact-info a span {
		font-weight: bold;
		font-size: 22px;
	}

	.has-dropdown .inner-list {
		overflow: hidden;
		-webkit-transition: max-height 0.2s linear;
		transition: max-height 0.2s linear;
		display: none;
	}

	.has-dropdown .inner-list-has-children > a {
		position: relative;
		padding-right: 30px;
	}

	.has-dropdown .inner-list-has-children > a:after {
		content: '';
		display: block;
		border-bottom: 1px solid #000;
		border-right: 1px solid #000;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		height: 7px;
		width: 7px;
		position: absolute;
		cursor: pointer;
		right: 12px;
		top: 50%;
	}

	.has-dropdown .inner-list-has-children > ul {
		display: none;
	}

	.has-dropdown .inner-list > li > ul {
		background: #e0e0e0;
		padding: 0 10px;
	}

	.has-dropdown li:last-child a {
		border: none;
	}

	.has-dropdown li a {
		display: block;
		color: #696969;
		font-size: 12px;
		padding: 10px 0;
		border-bottom: 2px solid #f2f2f2;
		-webkit-transition: all 0.2s linear;
		transition: all 0.2s linear;
	}

	.has-dropdown li a:hover {
		border-color: #10803f;
	}

	.has-dropdown li a strong {
		display: block;
		color: #ec2127;
	}

	.has-dropdown li a span {
		white-space: normal;
	}

	.tertiary-menu .accordion-button {
		background-color: #eee;
		color: #111111a8;
	}
	.tertiary-menu .accordion-button:focus {
		box-shadow: unset;
	}

	.tertiary-menu .accordion-button:after {
		background-size: 12px;
		height: 12px;
		width: 12px;
		margin-right: 8px;
		filter: drop-shadow(0px 0px 0px #000);
	}

	.tertiary-menu .accordion-body li {
		background-color: rgb(219, 226, 230);
		padding: 10px 20px;
		font-size: 12px;
	}

	.tertiary-menu .accordion-nodrop a,
	.tertiary-menu .accordion-body li a {
		display: block;
		color: #696969;
	}

	.tertiary-menu .accordion-nodrop {
		background-color: #eee;
		color: #111111a8;
		width: 100%;
		padding: 16px 20px;
		line-height: 1.2;
	}

	.bg-lightgray {
		background-color: #f3f3f3;
	}
	.service__logo {
		width: 40px;
		height: 40px;
	}

	.navigation-dropdown {
		position: relative;
	}
	.digibank-btn {
		border: 1px solid #e8dfdf;
		background-color: white;
		border-radius: 4px;
		padding: 5px 10px;
		color: #7e7e7e;
	}

	.digibank-dropdown {
		display: none;
		position: absolute;
		top: 69px;
		z-index: 11;
		width: 100%;
		height: 100%;
		border-radius: 0;
	}

	.digibank-dropdown .payment-item a {
		padding: 25px 10px 25px 3rem;
	}
	.navigation-dropdown:nth-child(3) button.btn-outline-success {
		color: #fff;
		background: linear-gradient(87.64deg, #05af3f 16.81%, #6dda7e 147.4%);
		border: 1.5px solid #fff;
	}

	.navigation-dropdown:nth-child(3) button.btn-outline-success .with-arrow:after {
		content: '';
		border-top-color: #fff !important;
	}

	.navigation-dropdown:nth-child(3) button.btn-outline-success:hover .with-arrow:after {
		content: '';
		border-top-color: #fff !important;
	}

	.navigation-dropdown .btn {
		-webkit-box-shadow: none;
		box-shadow: none;
		white-space: nowrap;
		font-size: 15px;
	}

	.navigation-dropdown .btn.btn-outline-success .with-arrow:after {
		content: '';
		border-top-color: ${(props) => props.theme.PrimaryColor};
	}

	.navigation-dropdown .btn.btn-outline-success:hover .with-arrow:after {
		content: '';
		border-top-color: #fff;
	}

	.navigation-dropdown .btn.btn-gradient--solid {
		color: #fff;
		background: linear-gradient(87.64deg, #05af3f 16.81%, #6dda7e 147.4%);
		border: 1.5px solid #fff;
	}

	.navigation-dropdown .btn.btn-gradient--solid .with-arrow:after {
		content: '';
		border-top-color: #fff;
	}

	.navigation-dropdown .btn.btn-gradient--solid:hover .with-arrow:after {
		content: '';
		border-top-color: #fff;
	}
	.navigation-dropdown .with-arrow {
		position: relative;
		padding-right: 15px !important;
	}

	.navigation-dropdown .with-arrow:after {
		content: '';
		position: absolute;
		border: 4px solid transparent;
		border-top-color: #959595;
		top: 65%;
		right: 0;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.navigation-dropdown > a {
		padding: 0 !important;
	}
	.navigation-dropdown-links {
		position: absolute;
		right: 0;
		background: #fff;
		border-top: 2px solid #d52933;
		-webkit-box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.2);
		box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.2);
		z-index: 100;
		border-radius: 0 0 5px 5px;
		overflow: hidden;
		margin-top: 5px;
	}
	.khanepani__form,
	.electricity__form {
	}

	@media all and (min-width: 500px) {
		.tv__form {
			width: 45%;
		}
	}
	.get__details__results {
		font-weight: 600;
	}
	.navigation-dropdown-links a {
		padding: 10px 15px !important;
		white-space: nowrap;
		font-size: 14px !important;
	}
	.navigation-dropdown-links a:hover {
		background: ${(props) => props.theme.PrimaryColor};
		color: #fff;
	}
	.customerId__error {
		border: 1px solid #e4655f;
		border-radius: 5px;
		color: #e4655f;
		padding: 25px 175px;
		margin-top: 15px;
	}
	.electricity__time__error {
		border: 1px solid #e4655f;
		border-radius: 5px;
		color: #e4655f;
		padding: 25px 55px;
		margin-top: 15px;
	}
	.navigation-top {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid #dbdbdb;
	}
	.navigation-top--left ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	.navigation-top--left ul li a {
		color: dimgray;
		padding: 12px 10px;
		display: block;
		font-size: 14px;
		position: relative;
		padding-top: 15px;
		z-index: 1;
	}

	.navigation-top--left ul li a:after {
		-webkit-transform: translateY(0%);
		transform: translateY(0%);
		bottom: 0;
		left: 5px;
	}

	.navigation-top--left ul li a.active {
		color: #fff;
	}

	.navigation-top--left ul li a.active:after {
		content: '';
		height: 100%;
		width: 100%;
		background: linear-gradient(90.21deg, #ec2127 4.15%, #d2191f 97.82%);
		position: absolute;
		left: 0;
		right: 0;
		z-index: -1;
		-webkit-transform: translateY(0%);
		transform: translateY(0%);
	}
	.navigation-top--right {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	.navigation-top--right .navigation-dropdown {
		padding-left: 0;
		padding-right: 0;
		margin: 0 10px;
	}
	.navigation-top--right .navigation-dropdown.lang-dropdown {
		margin-right: 8px;
	}

	.navigation-top--right div,
	.navigation-top--right a {
		font-size: 14px;
		color: dimgray;
		padding: 9px 10px 12px;
	}
	.navigation-top--right .dropdown > a,
	.navigation-top--right .dropdown .dropdown-toggle,
	.navigation-top--right .dropdown .dropdown-toggle > a {
		color: ${(props) => props.theme.HeaderTextColor};
	}
	.navigation-top--right .dropdown-menu {
		border: 0;
		padding: 0;
		background: #fff;
		border-top: 2px solid ${(props) => props.theme.SecondaryColor};
		box-shadow: 0 0 10px -3px rgb(0 0 0 / 20%);
		z-index: 100;
		border-radius: 0 0 5px 5px;
		overflow: hidden;
	}

	.navigation-top--right a:hover {
		color: ${(props) => props.theme.PrimaryColor};
		cursor: pointer;
	}

	.navigation-bottom a {
		color: dimgray;
		white-space: nowrap;
		font-size: 16px;
		font-family: 'Ubuntu', sans-serif;
	}
	.cssload-dots {
		width: 0;
		height: 0;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		outline: 1px solid red;
	}

	.cssload-dot {
		width: 0;
		height: 0;
		position: absolute;
		left: 0;
		top: 0;
	}

	.cssload-dot:before {
		content: '';
		width: 34px;
		height: 34px;
		border-radius: 49px;
		background: #fbd301;
		position: absolute;
		left: 50%;
		transform: translateY(0);
		-o-transform: translateY(0);
		-ms-transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		margin-left: -17.5px;
		margin-top: -17.5px;
	}

	.cssload-dot:nth-child(5):before {
		z-index: 100;
		width: 44.5px;
		height: 44.5px;
		margin-left: -21.75px;
		margin-top: -21.75px;
		animation: cssload-dot-colors 4.6s ease infinite;
		-o-animation: cssload-dot-colors 4.6s ease infinite;
		-ms-animation: cssload-dot-colors 4.6s ease infinite;
		-webkit-animation: cssload-dot-colors 4.6s ease infinite;
		-moz-animation: cssload-dot-colors 4.6s ease infinite;
	}

	.cssload-dot:nth-child(1) {
		animation: cssload-dot-rotate-1 4.6s 0s linear infinite;
		-o-animation: cssload-dot-rotate-1 4.6s 0s linear infinite;
		-ms-animation: cssload-dot-rotate-1 4.6s 0s linear infinite;
		-webkit-animation: cssload-dot-rotate-1 4.6s 0s linear infinite;
		-moz-animation: cssload-dot-rotate-1 4.6s 0s linear infinite;
	}

	.cssload-dot:nth-child(1):before {
		background-color: #ff3270;
		animation: cssload-dot-move 4.6s 0s ease infinite;
		-o-animation: cssload-dot-move 4.6s 0s ease infinite;
		-ms-animation: cssload-dot-move 4.6s 0s ease infinite;
		-webkit-animation: cssload-dot-move 4.6s 0s ease infinite;
		-moz-animation: cssload-dot-move 4.6s 0s ease infinite;
	}

	.cssload-dot:nth-child(2) {
		animation: cssload-dot-rotate-2 4.6s 1.15s linear infinite;
		-o-animation: cssload-dot-rotate-2 4.6s 1.15s linear infinite;
		-ms-animation: cssload-dot-rotate-2 4.6s 1.15s linear infinite;
		-webkit-animation: cssload-dot-rotate-2 4.6s 1.15s linear infinite;
		-moz-animation: cssload-dot-rotate-2 4.6s 1.15s linear infinite;
	}

	.cssload-dot:nth-child(2):before {
		background-color: #208bf1;
		animation: cssload-dot-move 4.6s 1.15s ease infinite;
		-o-animation: cssload-dot-move 4.6s 1.15s ease infinite;
		-ms-animation: cssload-dot-move 4.6s 1.15s ease infinite;
		-webkit-animation: cssload-dot-move 4.6s 1.15s ease infinite;
		-moz-animation: cssload-dot-move 4.6s 1.15s ease infinite;
	}

	.cssload-dot:nth-child(3) {
		animation: cssload-dot-rotate-3 4.6s 2.3s linear infinite;
		-o-animation: cssload-dot-rotate-3 4.6s 2.3s linear infinite;
		-ms-animation: cssload-dot-rotate-3 4.6s 2.3s linear infinite;
		-webkit-animation: cssload-dot-rotate-3 4.6s 2.3s linear infinite;
		-moz-animation: cssload-dot-rotate-3 4.6s 2.3s linear infinite;
	}

	.cssload-dot:nth-child(3):before {
		background-color: #afe102;
		animation: cssload-dot-move 4.6s 2.3s ease infinite;
		-o-animation: cssload-dot-move 4.6s 2.3s ease infinite;
		-ms-animation: cssload-dot-move 4.6s 2.3s ease infinite;
		-webkit-animation: cssload-dot-move 4.6s 2.3s ease infinite;
		-moz-animation: cssload-dot-move 4.6s 2.3s ease infinite;
	}

	.cssload-dot:nth-child(4) {
		animation: cssload-dot-rotate-4 4.6s 3.45s linear infinite;
		-o-animation: cssload-dot-rotate-4 4.6s 3.45s linear infinite;
		-ms-animation: cssload-dot-rotate-4 4.6s 3.45s linear infinite;
		-webkit-animation: cssload-dot-rotate-4 4.6s 3.45s linear infinite;
		-moz-animation: cssload-dot-rotate-4 4.6s 3.45s linear infinite;
	}

	.cssload-dot:nth-child(4):before {
		background-color: #fbd301;
		animation: cssload-dot-move 4.6s 3.45s ease infinite;
		-o-animation: cssload-dot-move 4.6s 3.45s ease infinite;
		-ms-animation: cssload-dot-move 4.6s 3.45s ease infinite;
		-webkit-animation: cssload-dot-move 4.6s 3.45s ease infinite;
		-moz-animation: cssload-dot-move 4.6s 3.45s ease infinite;
	}

	@keyframes cssload-dot-move {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}

		18%,
		22% {
			-webkit-transform: translateY(-68px);
			transform: translateY(-68px);
		}

		40%,
		100% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
	}
	.loader-body {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(255, 255, 255, 0.9);
		z-index: 9999;
		overflow: hidden;
	}

	.navigation-dropdown.top-menu > .dropdown:not(:first-of-type) {
		margin-left: 8px;
	}

	.navigation-dropdown-links {
		min-width: 150px;
	}

	.navigation-dropdown-links li a,
	.navigation-dropdown-links li div,
	.navigation-dropdown-links li span {
		display: block;
	}

	.navigation-dropdown-links li a img,
	.navigation-dropdown-links li div img,
	.navigation-dropdown-links li span img {
		width: 24px;
	}

	.navigation-dropdown-links li a:hover {
		color: #fff !important;
	}

	.navigation-links a {
		font-size: 18px;
		padding: 0 5px;
		text-transform: capitalize;
	}

	.dropdown-filter-holder {
		position: relative;
		border: 1px solid ${(props) => props.theme.PrimaryColor};
		background: #fff;
		cursor: pointer;
		font-size: 14px;
		color: ${(props) => props.theme.PrimaryColor};
		width: 150px;
	}

	.dropdown-filter-holder-header {
		color: ${(props) => props.theme.PrimaryColor};
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: 5px 16px;
	}

	.dropdown-filter-holder-header:hover {
		background: ${(props) => props.theme.PrimaryColor};
		color: #fff !important;
	}

	.dropdown-filter-holder.open {
		border: none;
	}

	.dropdown-filter-holder.open .dropdown-filter-holder-header {
		border: 1px solid ${(props) => props.theme.PrimaryColor};
		color: ${(props) => props.theme.PrimaryColor};
		border-radius: 3px;
	}

	.dropdown-filter-holder.open .dropdown-filter-body {
		display: block;
	}

	.dropdown-filter-body {
		display: none;
		position: absolute;
		max-height: 216px;
		background: #fff;
		cursor: pointer;
		z-index: 1;
		width: 100%;
		left: 0;
		border-radius: 2px;
		top: calc(100% + 8px);
		min-width: 150px;
		-webkit-box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.2);
		box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.2);
	}

	.dropdown-filter-body-item {
		padding: 0 8px;
		height: 40px;
		font-size: 14px;
		line-height: 40px;
		color: #16191d;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		width: 100%;
		margin-bottom: 0.5px;
	}

	.dropdown-filter-body-item:hover {
		background: ${(props) => props.theme.PrimaryColor};
		color: #fff;
	}

	a {
		text-decoration: none;
	}

	a:hover {
		text-decoration: none;
	}

	.footer-wrapper {
		background: ${(props) => props.theme.MainBackgroundColor};
		padding-top: 4rem;
	}
	.footer__top {
		margin-bottom: 2rem;
	}

	.footer__middle {
		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}

	.footer__middle .social-link-lists li {
		margin: 0 5px;
	}

	.footer__middle .social-link-lists li a {
		font-size: 20px;
	}

	.copyright-section {
		background-color: ${(props) => props.theme.BackgroundColor};
	}
	.footer__bottom {
		border-top: 1px solid #dfdfdf;
		font-size: 14px;
		padding-top: 0.8em;
		padding-bottom: 0.8em;
	}

	.footer__pageLinks {
		margin-bottom: 1rem;
	}

	.footer__pageLinks h5 > a,
	.footer__pageLinks h5 {
		color: ${(props) => props.theme.FooterTitleTextColor};
		font-weight: 600;
		margin-bottom: 1rem;
		font-size: 1rem;
	}

	.footer__pageLinks ul li {
		padding: 5px 0;
		line-height: 20px;
		color: ${(props) => props.theme.FooterTextColor};
		font-size: 1em;
	}

	.footer__pageLinks ul li a {
		color: ${(props) => props.theme.FooterTextColor};
		font-size: 1em;
		word-break: break-word;
	}

	.footer__pageLinks:not(.underlined) ul li a:hover {
		color: ${(props) => props.theme.PrimaryColor};
	}

	.crafted-by.underlined:hover,
	.underlined ul li a:hover {
		text-decoration: underline !important;
		/* text-decoration-color: ${(props) => props.theme.FooterTextColor} !important; */
	}

	.footer__pageLink {
		padding: 5px 0;
	}

	.footer__pageLink a:hover {
		color: ${(props) => props.theme.PrimaryColor};
	}

	.social-link-lists li {
		margin-right: 8px;
	}

	.social-link-lists a {
		width: 32px;
		height: 32px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 8px;
		background: #fff;
		border-radius: 4px;
		-webkit-transition: all 0.2s linear;
		transition: all 0.2s linear;
		margin-bottom: 5px;
	}

	.social-link-lists a.facebook-icon {
		color: #3b5999;
	}

	.social-link-lists a.facebook-icon:hover {
		color: #3b5999;
	}

	.social-link-lists a.instagram-icon {
		color: #e4405f;
	}

	.social-link-lists a.instagram-icon:hover {
		color: #e4405f;
	}

	.social-link-lists a.twitter-icon {
		color: #55acee;
	}

	.social-link-lists a.twitter-icon:hover {
		color: #55acee;
	}

	.social-link-lists a.linkedin-icon {
		color: #0077b5;
	}

	.social-link-lists a.linkedin-icon:hover {
		color: #0077b5;
	}

	.social-link-lists a.viber-icon {
		color: #665cac;
	}

	.social-link-lists a.viber-icon:hover {
		color: #665cac;
	}

	.social-link-lists a.youtube-icon {
		color: #cd201f;
	}

	.social-link-lists a.youtube-icon:hover {
		color: #cd201f;
	}

	.social-link-lists a.pinterest-icon {
		color: #bd081c;
	}

	.social-link-lists a.pinterest-icon:hover {
		color: #bd081c;
	}

	.social-link-lists a.skype-icon {
		color: #00aff0;
	}

	.social-link-lists a.skype-icon:hover {
		color: #00aff0;
	}

	.social-link-lists a:hover {
		-webkit-box-shadow: 0px 16px 16px rgba(163, 163, 163, 0.25);
		box-shadow: 0px 16px 16px rgba(163, 163, 163, 0.25);
	}

	.footer__bottom .footer__pageLink {
		color: ${(props) => props.theme.FooterTextColor};
		font-size: 14px;
	}
	.crafted-by.underlined:hover,
	.crafted-by {
		color: ${(props) => props.theme.FooterTextColor};
	}

	.disabledWeekend .react-datepicker__week .react-datepicker__day:nth-child(7) {
		color: #ccc;
		cursor: default !important;
		pointer-events: none;
	}

	.navigation-dropdown-links {
		min-width: 150px;
		display: none;
	}

	.navigation-dropdown-links li a,
	.navigation-dropdown-links li div,
	.navigation-dropdown-links li span {
		display: block;
	}

	.navigation-dropdown-links li a img,
	.navigation-dropdown-links li div img,
	.navigation-dropdown-links li span img {
		width: 24px;
	}

	.navigation-dropdown-links li a:hover {
		color: #fff !important;
	}

	.navigation-links a {
		font-size: 18px;
		padding: 0 5px;
		text-transform: capitalize;
	}

	.search-wrapper {
		display: none;
		position: absolute;
		right: 56px;
		top: 0;
		width: 100%;
		max-width: 765px;
		z-index: 5;
		-webkit-transition: all 0.2s linear;
		transition: all 0.2s linear;
		background: #fff;
	}

	.search-wrapper-wrap {
		width: 100%;
	}

	.search-wrapper-btnwrap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		white-space: nowrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-right: 10px;
		color: #bdbdbd;
		position: absolute;
		top: 55%;
		right: 0;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.card_image_wrapper > img {
		width: 100%;
		height: 84px;
		object-fit: cover;
	}
	.card__text {
		text-align: center;
		text-transform: capitalize;
		font-size: 0.875rem;
		font-weight: 700;
	}
	.movie__card__wrapper {
		position: relative;
		display: inline-block;
		vertical-align: top;
		box-sizing: inherit;
		box-shadow: none;
		border: 1px solid #d5d5d5;
		border-radius: 5px;
		font-size: 1rem;
		position: relative;
		background: #fff;
		padding: 0px;
		margin: 5px;
		overflow: hidden;
	}
	.movie__image__wrapper > img {
		position: relative;
		float: left;
		width: 100%;
		height: 100%;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		box-sizing: inherit;
		cursor: pointer;
		display: block;
	}
	.movie__title {
		color: rgb(55, 63, 71);
		margin-top: 0;
		font-size: 1.28571429rem;
		font-family: Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
		line-height: 1.28571429em;
		margin: calc(2rem - 0.14285714em) 0 1rem;
		font-weight: 700;
		padding: 0;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		display: block;
	}
	.show__sechudule__check {
		width: 1060px;
	}
	.show__sechudule__shows_head {
		width: 100%;
	}
	.show__sechudule__no__of__movie {
		float: right;
		transition: color 0.2s ease;
		color: rgb(55, 63, 71);
		text-size-adjust: 100%;
		font-weight: 400;
		position: relative;
		padding-left: 600px;
		top: -45px;
	}
	.show__sechudule__schedule__time {
		box-shadow: 0 0 0 1px #21ba45 inset !important;
		color: #21ba45 !important;
		font-weight: 400;
		border-radius: 0.28571429rem;
		text-transform: none;
		text-shadow: none !important;
		font-size: 15px;
		display: inline-block;
		outline: 0;
		border: none;
		margin: 0 0.25em 0 0;
		padding: 5px 15px;
		font-style: normal;
		text-align: center;
		text-decoration: none;
		box-sizing: inherit;
		list-style-type: none;
		list-style-position: outside;
		margin-bottom: 10px;
		margin-left: 10px;
		height: 55px;
		width: 100px;
	}

	.show__sechudule__time {
		display: block;
		margin: 0;
		font-weight: 700;
		color: #21ba45 !important;
		font-size: 1.07142857em;
		line-height: 1.28571429em;
		text-transform: none;
		font-style: normal;
		text-align: center;
	}
	.show__sechudule__detail {
		font-weight: '300px';
		font-size: 'smaller';
	}

	.not__found__search__container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		padding: 20px;
	}
	.not__found__search__title {
		font-weight: 900;
		font-size: 20px;
	}

	.movie__card__text {
		margin-bottom: 5px;
		padding: 5px;
		text-align: center;
		color: #5d2e8e;
		font-size: 12px;
		font-weight: 700;
		display: block;
	}

	.input__error__container {
		background-color: #e4655f;
		color: #fff;
		margin-top: 3px;
		font-size: 12px;
		padding: 2px 2px 2px 23px;
		display: inline-block;
		margin-bottom: 0 !important;
	}
	.datapack__error__container {
		background-color: #e4655f;
		color: #fff;
		margin-top: 3px;
		font-size: 12px;
		padding: 2px;
		display: inline-block;
		margin-bottom: 0 !important;
	}
	.input__text__feild__gap {
		margin-bottom: 8px;
	}
	.input__label__error__color {
		color: #a94442 !important;
	}
	.input__label {
		font-size: 0.875em;
		font-weight: 550;
		color: #555151;
	}
	.electricity__help {
		min-height: 1em;
		margin: 1em 0;
		background: #f8f8f9;
		padding: 1em 1.5em;
		line-height: 1.4285em;
		color: rgba(0, 0, 0, 0.87);
		-webkit-transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease,
			-webkit-box-shadow 0.1s ease;
		transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease,
			-webkit-box-shadow 0.1s ease;
		transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
		transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease,
			-webkit-box-shadow 0.1s ease;
		border-radius: 0.28571429rem;
		-webkit-box-shadow: 0 0 0 1px rgb(34 36 38 / 22%) inset, 0 0 0 0 transparent;
		box-shadow: 0 0 0 1px rgb(34 36 38 / 22%) inset, 0 0 0 0 transparent;
	}

	.input__error__icon {
		position: absolute;
		z-index: 222;
		display: inline;
		color: #ffffffff;
		margin-left: 2px;
	}

	.logout__btn {
		padding: 10px;
		border: none;
		border-radius: 7px;
		background-color: #2563eb;
		color: #ffffffff;
	}

	.guest__login__btn {
		padding: 20px;
		border: none;
		border-radius: 7px;
		background-color: #2563eb;
		color: #ffffffff;
	}

	.not__found__btn {
		padding: 20px;
		outline: none;
		border: none;
		border-radius: 10px;
		background-color: #4da860;
	}
	.data__pack__container {
		margin-right: 20px;
		cursor: pointer;
	}
	.container-datapack {
		border: 1px solid #cdcdcd;
		box-sizing: border-box;
		border-radius: 5px;
		width: 885px;
		height: 304px;
		padding: 50px;
		background: rgb(247, 244, 244);
	}
	.container-pack {
		display: flex;
		align-items: center;
	}
	.ntc {
		height: 100px;
		width: 100px;
		background-color: white;
	}
	.ncell {
		height: 100px;
		width: 100px;
	}

	.cancel {
		width: 88px;
		height: 35px;

		border-radius: 5px;
	}
	.search {
		outline: 0;
		border-width: 0 0 2px;
		padding: 10px;
	}

	.days-logo {
		height: 60px;
		width: 60px;
	}
	.service-logo {
		height: 120px;
		width: 120px;
	}
	.pack-button {
		width: 58px;
		height: 35px;
		outline: none;
		border: none;
		background: ${(props) => props.theme.PrimaryButtonColor};
		border-radius: 5px;
		color: ${(props) => props.theme.ButtonTextColor};
	}
	.custom__form__select {
		width: 100%;
		padding: 8px;
		border-radius: 0px;
		background-color: #ffffff;
		outline: none;
		border: 0.5px solid rgba(0, 0, 0, 0.178);
		margin-top: 7.9px !important;
		margin-bottom: 14px !important;
	}

	.dofe__header {
		font-weight: 700;
		color: ${(props) => props.theme.PrimaryColor};
		font-size: 1.4rem;
	}
	.insurace__image__wrapper {
		height: auto;
		width: 100px;
		margin-right: 20px;
	}
	.insurace__main__container {
		background-color: #f8f8f8;
		padding: 20px;
	}

	.insurace__image__wrapper > img {
		width: 100%;
		background-size: contain;
	}
	.insurace__top__header__heading {
		font-size: 1.0788rem;
		color: rgba(0, 0, 0, 0.87);
	}

	.insurace__content__wrapper {
		display: flex;
	}

	.insurace__subtitle {
		color: #499e4d;
		font-size: 1rem;
	}

	.insurace__bottom__title {
		color: rgba(0, 0, 0, 0.87);
		font-size: 14px;
	}

	@media only screen and (max-width: 525px) {
		.insurace__main__container {
		}
	}
	@media only screen and (max-width: 525px) {
		.insurace__image__wrapper {
			height: 70px;
			width: 70px;
		}
		.insurace__top_header__heading {
			font-size: 16px;
		}
	}
	@media only screen and (max-width: 339px) {
		.insurace__subtitle {
			flex-direction: column;
		}
	}
	.movie__container {
		margin-right: 20px;
		cursor: pointer;
	}
	.top__up__service {
		background-color: #e1e1e1;
		border-radius: 3px;
		padding: 5px 10px;
		margin-right: 12px;
		font-size: 11px;
		font-weight: 700;
		cursor: pointer;
	}
	.top__up__service__selected {
		background-color: ${(props) => props.theme.PrimaryColor};
		border-radius: 3px;
		padding: 5px 10px;
		margin-right: 12px;
		font-size: 11px;
		font-weight: 700;
		cursor: pointer;
		color: #ffffffff;
	}

	.pack-button:hover {
		background: linear-gradient(
			90deg,
			${(props) => props.theme.PrimaryColor} 1%,
			${(props) => props.theme.PrimaryColor} 83%
		);
		transition: all 0.2s ease-in-out;
	}

	.see-more:hover {
		cursor: pointer;
	}

	.not__found__soory__text {
		color: #eb1f27 !important;
		font-size: 46px;
	}
	.not__found__text {
		font-size: 32px;
	}
	.not__found__bottom__text {
		font-size: 16px;
	}

	@media only screen and (max-width: 498px) {
		.card__text {
		}
	}
	@media only screen and (max-width: 359px) {
		.card__image__wrapper > img {
			width: 100%;
			height: 50px;
			object-fit: contain;
		}
		.card__text {
			text-align: center;
			text-transform: capitalize;
			font-size: 0.475rem;
			font-weight: 400;
		}
	}

	.search-wrapper-btnwrap span {
		font-size: 14px;
	}

	.payment-list {
		background: white;
		border-radius: 4px;
		overflow: hidden;
	}

	.payment-item a {
		position: relative;
		padding: 9px 10px 9px 3rem;
		color: #625f5f;
		display: block;
		-webkit-transition: all 0.02s linear;
		transition: all 0.02s linear;
	}

	.payment-item a:before,
	.payment-item a:after {
		content: '';
		position: absolute;
		-webkit-transition: 0.2s all linear;
		transition: 0.2s all linear;
		top: 50%;
	}

	.payment-item a:before {
		width: 9px;
		height: 9px;
		right: 15px;
		-webkit-transform: translateY(-50%) rotate(45deg);
		transform: translateY(-50%) rotate(45deg);
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
	}

	.payment-item a:after {
		border: 4px solid transparent;
		right: 15px;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		border-left-color: #fff;
	}

	.payment-item a:hover {
		padding-right: 35px;
		background: -webkit-gradient(linear, left top, right top, from(#41c864), to(#10803f));
		background: linear-gradient(90deg, #41c864 0%, #10803f 100%);
		color: white;
	}

	.payment-item a:hover svg path {
		fill: white;
	}

	.payment-item a img {
		position: absolute;
		left: 16px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.payment-item a svg {
		position: absolute;
		left: 16px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.menu-wrapper .menu::after {
		content: '';
		clear: both;
		display: block;
	}

	.menu-wrapper .menu a {
		display: block;
	}

	.menu-wrapper .menu > li {
		position: relative;
	}

	.menu-wrapper .menu,
	.menu-wrapper .menu ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}

	.menu-wrapper .menu ul {
		position: absolute;
		top: 100%;
		width: 230px;
		background: white;
		z-index: 1;
		padding: 1rem;
		visibility: hidden;
		opacity: 0;
		-webkit-transition: all 0.2s linear;
		transition: all 0.2s linear;
	}

	.menu-wrapper .menu ul li.has-children > a {
		position: relative;
	}

	.menu-wrapper .menu ul li.has-children > a:before {
		content: '';
		display: block;
		width: 7px;
		height: 7px;
		border-top: 1px solid #696969;
		border-left: 1px solid #696969;
		-webkit-transform: rotate(135deg) translateY(-50%);
		transform: rotate(135deg) translateY(-50%);
		position: absolute;
		right: 0;
		top: 50%;
	}

	.menu-wrapper .menu ul li:last-child a {
		border: none;
	}

	.menu-wrapper .menu ul li a {
		display: block;
		color: #696969;
		font-size: 12px;
		padding: 10px 0;
		border-bottom: 2px solid #f2f2f2;
		-webkit-transition: all 0.2s linear;
		transition: all 0.2s linear;
	}

	.menu-wrapper .menu ul li a:hover {
		border-color: #41c864;
	}

	.menu-wrapper .menu ul li a strong {
		display: block;
		color: #383838;
	}

	.menu-wrapper .menu ul li a span {
		white-space: normal;
	}
	.menu-wrapper .menu > li ul,
	.menu-wrapper .menu ul ul {
		opacity: 0;
		-webkit-transition: all 0.2s ease-in;
		transition: all 0.2s ease-in;
		z-index: -1;
		visibility: hidden;
	}

	.menu-wrapper .menu > li ul {
		top: 130%;
		right: 0;
	}

	.menu-wrapper .menu ul ul {
		left: 130%;
		top: 0;
	}

	.menu-wrapper .menu > li > ul > li.has-children:hover > a:before {
		content: '';
		border-color: #41c864;
		border-color: #41c864;
	}

	.menu-wrapper .menu > li > ul > li:hover > a {
		border-color: #41c864;
	}

	.menu-wrapper .menu > li:hover > ul {
		top: 100%;
		opacity: 1;
		z-index: 1;
		visibility: visible;
	}

	.menu-wrapper .menu ul > li > a {
		clear: both;
	}

	.menu-wrapper .menu ul > li:hover > ul {
		left: 100%;
		opacity: 1;
		z-index: 1;
		visibility: visible;
	}

	.alertPage-wrap {
		padding: 30px;
		border-radius: 5px;
	}

	.alertPage-wrap p {
		font-size: 15px;
		color: #757575;
	}

	.checkmark {
		position: relative;
		margin-bottom: 1rem;
		animation: checkmark 5m cubic-bezier(0.42, 0, 0.275, 1.155) both;
	}

	.checkmark__check {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 10;
		transform: translate3d(-50%, -50%, 0);
		fill: #fff;
	}

	.checkmark__background {
		animation: rotate 35s linear both infinite;
	}

	.checkmark__background--success {
		fill: green;
	}

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	a {
		text-decoration: none;
	}

	.input-group span.text-danger,
	.form-group span.text-danger {
		font-size: 13px;
	}
	.custom-file-label::after {
		color: white;
		background-color: ${(props) => props.theme.PrimaryColor};
	}
	.calender-wrap.calender-form .react-datepicker-wrapper {
		max-width: 100%;
		height: 100%;
	}
	.calender-wrap.calender-form .react-datepicker__input-container {
		position: relative;
		height: 100%;
	}
	.main__row {
		border: 1px solid #d5d5d5;
		border-radius: 5px;
	}

	@media only screen and (max-width: 768px) {
		.main__row {
			padding-left: 30px !important;
		}
	}
	@media only screen and (max-width: 576px) {
		.main__row {
			border: none;
		}
	}
	.component__header {
		font-weight: 700;
		color: ${(props) => props.theme.PrimaryColor};
		font-size: 1.4rem;
	}

	.amount {
		color: ${(props) => props.theme.PrimaryColor};
	}
	.header__container {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
		margin: 0px 2px 25px 2px !important;
	}

	.header__container > input {
		outline: none;
		border: none;
		border-bottom: 0.5px solid rgba(0, 0, 0, 0.281);
	}

	.calender-wrap.calender-form .react-datepicker__input-container input {
		color: #6c757d;
		font-weight: normal;
		height: 100%;
		z-index: 0;
	}
	.calender-wrap.calender-form .react-datepicker__input-container input:focus {
		color: #444;
		font-weight: normal;
	}
	.calender-wrap.calender-form .nepali-date-picker input[readonly] {
		color: #6c757d;
		font-weight: normal;
	}
	.calender-wrap.calender-form ~ .input-group-append {
		cursor: pointer;
	}
	.main {
		width: 170px;
		height: 210px;
		display: flex !important;
		flex-direction: column;
		justify-content: center;
		padding-top: 15px;
		padding-bottom: 15px;
		margin: 15px 13px;
		cursor: pointer;
		border-radius: 10px;
		box-shadow: 0px 0px 0px 1px #e5dedeb5;
	}
	@media only screen and (max-width: 767px) {
		.main {
			margin: 15px 31px;
		}
	}
	@media only screen and (max-width: 497px) {
		.main {
			width: 129px;
			height: 159px;
		}
	}
	@media only screen and (max-width: 413px) {
		.main {
			width: 119px;
			height: 128px;
		}
	}
	@media only screen and (max-width: 391px) {
		.main {
			width: 103px;
			height: 120px;
		}
	}
	@media only screen and (max-width: 359px) {
		.main {
			width: 80px;
			height: 93px;
		}
	}

	.calender-wrap .input-group-prepend,
	.calender-wrap .input-group-append {
		z-index: inherit !important;
	}
	.header {
		color: ${(props) => props.theme.PrimaryColor};
		font-size: 25px;
		font-weight: 700;
	}

	@media only screen and (max-width: 822px) {
		.header {
			font-size: 20px;
		}
	}
	@media only screen and (max-width: 498px) {
		.header {
			font-size: 15px;
		}
	}

	.form-group label {
		font-size: 15px;
		color: #424242;
		margin-bottom: 0.5rem;
	}
	.form-group .form-control {
		border-radius: 0;
		z-index: inherit;
	}
	.form-group .form-control:focus {
		border-color: ${(props) => props.theme.PrimaryColor};
		box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0);
	}
	.form-dashed {
		width: auto;
		border: 0;
		border-bottom: 1px dashed #9e9e9e;
	}
	.custom-control-input:checked ~ .custom-control-label::before {
		color: #fff;
		border-color: ${(props) => props.theme.PrimaryColor};
		background-color: ${(props) => props.theme.PrimaryColor};
	}
	.custom-control-label::before,
	.custom-control-label::after {
		top: 0.15rem;
	}
	.css-yk16xz-control {
		border-radius: 0px !important;
	}
	.css-yk16xz-control:hover,
	.css-yk16xz-control:focus {
		border-color: ${(props) => props.theme.PrimaryColor} !important;
	}
	.css-1fhf3k1-control {
		background-color: #e9ecef !important;
		border-color: #ced4da !important;
		border-radius: 0 !important;
	}
	.cardSmall {
		position: absolute;
		bottom: -5px;
	}
	.form-control {
		border-radius: 0;
	}
	.form-control:focus {
		border-color: ${(props) => props.theme.PrimaryColor};
		box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0);
	}
	.calender-wrap .nepali-date-picker {
		position: static;
	}
	.calender-wrap .nepali-date-picker .current-month,
	.calender-wrap .nepali-date-picker .current-year {
		position: relative;
	}
	.calender-wrap .nepali-date-picker .current-month:after,
	.calender-wrap .nepali-date-picker .current-year:after {
		content: '';
		border: 4px solid transparent;
		border-top-color: white;
		position: absolute;
		top: 50%;
		right: -2px;
	}
	.calender-wrap .nepali-date-picker .calender .calendar-controller .control .drop-down {
		top: 30px;
		min-width: 100px;
		color: black;
	}
	.calender-wrap .nepali-date-picker .calender .calendar-controller .control .drop-down li.active {
		background: ${(props) => props.theme.PrimaryColor};
	}
	.nepali-date-picker .calender table tr {
		border: none;
		height: 24px;
	}
	.form__layout__heading {
		font-weight: 700;
		color: #51b36d;
		font-size: 1.4rem;
		margin-top: 3rem;
	}
	.nav {
		background: #b7b7b78a;
	}

	.nav-pills .nav-link {
		border-radius: 0;
	}

	.nav-pills .nav-link.active,
	.nav-pills .show > .nav-link {
		color: #fff;
		background-color: ${(props) => props.theme.PrimaryColor};
		color: #ffffff !important;
	}

	.nav-pills .show > .nav-link {
		color: #fff;
		background-color: #05af3f;
	}

	.form__wrapper {
		background-color: #f8f8f8;
	}
	.form__select {
		width: 100%;
		padding: 8px;
		border-radius: 0px;
		background-color: #ffffff;
		outline: none;
		border: 0.5px solid rgba(0, 0, 0, 0.178);
		margin-top: 7.9px !important;
		margin-bottom: 14px !important;
	}

	.formHeading {
		padding: 10px 0;
		margin-bottom: 1rem;
		color: ${(props) => props.theme.PrimaryColor};
		border-bottom: 1px solid gainsboro;
		font-weight: 600;
		padding-bottom: 1rem;
	}
	.airline__heading {
		padding: 10px 0;
		margin-bottom: 1rem;
		color: ${(props) => props.theme.PrimaryColor};
		font-weight: 600;
		margin: 1rem 0;
	}
	.airline__ticket-heading {
		color: ${(props) => props.theme.PrimaryColor};
		font-weight: 600;
	}

	.formLayout ::placeholder,
	.formLayout .css-1wa3eu0-placeholder {
		font-size: 13px;
	}
	.formLayout-row {
		margin-bottom: 3rem;
	}
	.formLayout-removeSectionBtn {
		position: absolute;
		top: -25px;
		right: 0;
		height: 50px;
		width: 50px;
		border-radius: 50%;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		border: 5px solid white;
		transition: all 0.2s linear;
	}
	.formLayout-addSection {
		position: relative;
	}
	.formLayout-addSection_no {
		background: #28a745;
		position: absolute;
		left: -40px;
		top: 0;
		color: white;
		width: 30px;
		text-align: center;
	}
	.formLayout-addSection ~ .formLayout-addSection {
		border-top: 1px dashed gainsboro;
		padding-top: 1rem;
	}
	.requiredMsg {
		font-size: 13px;
	}
	.credit-card-step-container {
		display: flex;
		flex-direction: column;
		position: relative;
	}
	.credit-card-step-container .stepNumber {
		height: 50px;
		width: 50px;
		border-radius: 100px;
		background-color: grey;
		align-items: center;
		justify-content: center;
		display: flex;
		font-size: 20px;
		font-weight: bold;
		z-index: 1;
		background-color: white;
		border: 4px solid lightgray;
		color: lightgray;
		z-index: 3;
	}
	.credit-card-step-container .stepNumber ~ label {
		color: darkgray;
	}
	.credit-card-step-container .line {
		height: 3px;
		border-top: 2px solid ${(props) => props.theme.PrimaryColor};
		position: absolute;
		top: 25px;
		z-index: 2;
		transition: all 300ms ease;
	}
	.credit-card-step-container a.active .stepNumber {
		background: ${(props) => props.theme.PrimaryColor};
		border: 4px solid ${(props) => props.theme.PrimaryColor};
		color: white;
	}
	.credit-card-step-container a.active ~ label {
		color: #199d54;
	}
	.formLayout-footer {
		background: white;
		margin: -24px -40px;
		padding: 30px 16px;
		border-top: 1px solid lightgrey;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type='number'] {
		-moz-appearance: textfield;
	}
	.stepNumber-visited {
		border: 4px solid #959595 !important;
		color: #959595 !important;
		position: relative;
	}
	.credit-card-step-row .credit-card-step-container:not(:last-child)::after {
		content: '';
		height: 3px;
		width: 100%;
		border-top: 2px dashed lightgray;
		position: absolute;
		top: 25px;
		z-index: 1;
	}
	.card-step-active:not(:last-child)::after {
		content: '';
	}
	.mobileprogresslabel {
		display: none;
	}
	@media screen and (max-width: 576px) {
		.formLayout-footer {
			margin: -24px -15px;
		}
		.formLayout-addSection_no {
			left: 0;
			top: -20px;
		}
		.mobileprogresslabel {
			display: block;
		}
		.credit-card-step-row {
			flex-wrap: nowrap;
		}
		.credit-card-step-container .stepNumber {
			width: 40px;
			height: 40px;
			font-weight: normal;
			border-width: 3px !important;
		}
		.credit-card-step-container .stepNumber ~ label {
			display: none;
		}
		.credit-card-step-container .stepNumber-active {
			border-width: 3px !important;
		}
		.credit-card-step-container a.active .stepNumber {
			background: ${(props) => props.theme.PrimaryColor};
			border: 4px solid ${(props) => props.theme.PrimaryColor};
			color: white;
		}
		.credit-card-step-container a.active ~ label {
			color: #199d54;
		}
		.credit-card-step-row .credit-card-step-container:not(:last-child)::after {
			content: '';
			top: 20px;
		}
		.flightWrapper {
			box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
			margin-bottom: 20px;
			padding: 0 10px;
			background: white;
		}
		.flightWrapper-top {
			height: auto;
			padding-top: 1em;

			text-align: left;
		}

		.flightWrapper figure {
			height: 40px;
			width: 40px;
			padding: 3px;
		}
		.flightWrapper figure img {
			object-fit: contain;
		}

		.flightWrapper .flight-name h5 {
			margin-bottom: 0;
		}
		.flightWrapper .flight-name small {
			color: #888888;
		}

		.flight-wrapper-time {
		}
		.flight-time {
			display: flex;
			flex-direction: column;
		}

		.flightWrapper-bottom {
			border-top: 1px solid #dcdcdc;
		}
		.flight-meta label {
			border: 1px solid ${(props) => props.theme.PrimaryColor};
			color: ${(props) => props.theme.PrimaryColor};
			font-size: 14px;
		}

		.bc-plane {
			width: 100%;
			text-align: center;
			position: relative;
			margin: 0 15px;
		}

		.bc-plane img {
			width: 20%;
		}

		.bc-plane:before {
			content: '';
			width: 35%;
			height: 2px;
			background: ${(props) => props.theme.PrimaryColor};
			position: absolute;
			top: 50%;
			left: 0;
		}

		.bc-plane:after {
			content: '';
			width: 35%;
			height: 2px;
			background: ${(props) => props.theme.PrimaryColor};
			position: absolute;
			top: 50%;
			right: 0;
		}

		.ticket-details {
			background: ${(props) => props.theme.PrimaryColor};
			padding: 10px;
			color: white;
		}

		.ticket-detail {
			display: flex;
			flex-direction: column;
			margin-bottom: 0.5rem;
		}
		.ticket-detail label {
			color: #e0e0e0;
		}
		.ticket-detail span {
			color: #ffffff;
			font-size: 20px;
		}

		.ticket-detail-total label {
			font-size: 24px;
		}
		.ticket-detail-total span {
			font-size: 24px;
		}
	}
	.statusLayout {
		padding: 1rem 0;
	}

	.statusLayout .bg-lightgray {
		padding: 24px 1rem 1rem;
	}
	.statusLayout .statusLayout-header {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
	}
	.statusLayout .statusLayout-header img {
		width: 30px;
	}
	.statusLayout .statusLayout-header .checkmark__check {
		width: 16px;
	}
	.statusLayout .statusLayout-header .checkmark__background {
		width: 45px;
		height: 45px;
	}
	@media screen and (min-width: 426px) {
		.statusLayout .statusLayout-header .checkmark__check {
			width: auto;
		}
		.statusLayout .statusLayout-header .checkmark__background {
			width: auto;
			height: auto;
		}
	}
	.statusLayout .statusLayout-header .warning-icon {
		font-size: 40px;
	}
	.statusLayout .statusLayout-header .error-text {
		font-size: 16px;
		color: ${(props) => props.theme.PrimaryColor};
		font-weight: 600;
		margin-left: 1rem;
	}
	.statusLayout .statusLayout-header .main-error-text {
		font-size: 16px;
		color: rgb(209, 15, 15);
		font-weight: 600;
		margin-left: 1rem;
	}
	.statusLayout .statusLayout-list div:nth-child(1) {
		font-size: 12px;
		color: rgba(0, 0, 0, 0.5);
		font-weight: 400;
	}
	.statusLayout .statusLayout-list div:nth-child(2) {
		font-size: 14px;
		color: ${(props) => props.theme.PrimaryColor};
		font-weight: 500;
	}
	.track_request_here {
		color: ${(props) => props.theme.PrimaryColor};
		margin-left: 5px;
	}
	.track_request_here:hover {
		cursor: pointer;
	}
	.check__loader {
		height: 15px;
		width: 15px;
	}
	@media screen and (min-width: 426px) {
		.statusLayout {
			padding: 48px 0;
		}
		.statusLayout .bg-lightgray {
			padding: 48px 1rem 1rem;
		}

		.statusLayout .statusLayout-header {
			flex-direction: column;
			margin-bottom: 1rem;
		}
		.statusLayout .statusLayout-header img {
			width: 80px;
			margin-bottom: 1rem;
		}
		.statusLayout .statusLayout-header .warning-icon {
			font-size: 64px;
		}
		.statusLayout .statusLayout-header .error-text {
			font-size: 32px;
			margin-left: 0;
		}
		.statusLayout .statusLayout-header .main-error-text {
			font-size: 32px;
			margin-left: 0;
		}
		.statusLayout .statusLayout-list div:nth-child(1) {
			font-size: 16px;
		}
		.statusLayout .statusLayout-list div:nth-child(2) {
			font-size: 16px;
		}
	}
	.printcard label {
		color: grey;
		font-size: 13px;
		font-family: 'Roboto', sans-serif;
	}
	.printcard span {
		font-weight: bold;
		display: block;
	}
	[type='radio']:checked,
	[type='radio']:not(:checked) {
		position: absolute;
		left: -9999px;
	}
	[type='radio']:checked,
	[type='radio']:not(:checked) {
		position: relative !important;
		left: 0 !important;
	}
	[type='radio']:checked + label,
	[type='radio']:not(:checked) + label {
		position: relative;
		padding-left: 28px;
		cursor: pointer;
		line-height: 20px;
		display: inline-block;
		color: #666;
	}
	[type='radio']:checked + label:before,
	[type='radio']:not(:checked) + label:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 18px;
		height: 18px;
		border: 1px solid ${(props) => props.theme.PrimaryColor};
		border-radius: 100%;
		background: #fff;
	}
	[type='radio']:checked + label:after,
	[type='radio']:not(:checked) + label:after {
		content: '';
		width: 12px;
		height: 12px;
		background: ${(props) => props.theme.PrimaryColor};
		position: absolute;
		top: 3px;
		left: 3px;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	[type='radio']:not(:checked) + label:after {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	[type='radio']:checked + label:after {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	[type='radio']:checked,
	[type='radio']:not(:checked) {
		position: relative !important;
		left: 0 !important;
	}
	.form-check-input {
		position: absolute;
		opacity: 0;
	}
	.form-check-input + label {
		position: relative;
		cursor: pointer;
		padding: 0;
	}
	.form-check-input + label:before {
		content: '';
		margin-right: 10px;
		display: inline-block;
		vertical-align: text-top;
		width: 20px;
		height: 20px;
		border: 2px solid ${(props) => props.theme.PrimaryColor};
	}
	.form-check-input:hover + label:before {
		background: ${(props) => props.theme.PrimaryColor};
	}
	.form-check-input:focus + label:before {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
	}
	.form-check-input:checked + label:before {
		background: ${(props) => props.theme.PrimaryColor};
	}
	.form-check-input:disabled + label {
		color: #b8b8b8;
		cursor: auto;
	}
	.form-check-input:disabled + label:before {
		box-shadow: none;
		background: #ddd;
	}
	.form-check-input:checked + label:after {
		content: '';
		position: absolute;
		left: 5px;
		top: 10px;
		background: white;
		width: 2px;
		height: 2px;
		box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
			4px -8px 0 white;
		transform: rotate(45deg);
	}

	.mtb {
		margin: 1rem 0rem;
	}

	.iCard small {
		color: #a0a0a0;
	}
	.iCard .form-control#full_name {
		text-transform: capitalize;
	}

	.topup__main {
		width: 100%;
		background-color: red;
	}
	.topup__services__list {
		position: relative;
		top: -15px;
	}
	.top__success {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px 0;
	}
	.success__message {
		background: rgba(199, 236, 215, 0.48);
		border-radius: 5px;
		text-align: center;
		padding: 20px;
	}
	.success_text {
		color: ${(props) => props.theme.PrimaryColor};
	}
	.topup__tick {
		width: 55px;
		height: 55px;
		margin-bottom: 15px;
	}
	.cancel__button {
		width: 75px;
		height: 37px;
		position: relative;
		top: 27px;
		margin-left: 10px;
	}
	@media screen and (min-width: 576px) {
		.iCard .react-datepicker-wrapper {
			max-width: 100%;
		}
		.iCard .react-datepicker-wrapper input {
			font-weight: 500;
		}
	}
	@media only screen and (max-width: 992px) {
		.header__container > input {
			margin-bottom: 10px;
			margin-right: 0px;
			width: 174px;
		}
	}
	@media only screen and (max-width: 431px) {
		.header__container > input {
			width: 120px;
			margin-bottom: 10px;
		}
	}
	@media only screen and (max-width: 397px) {
		.header__container > input {
			width: 100px;
			margin-bottom: 10px;
		}
	}

	.viber__logo {
		height: 30px;
		width: 95px;
		margin: 0 -10px;
	}

	.ncell__datapack__description {
		font-size: 14px;
	}

	.btn-danger {
		background-color: #d52933;
		border-color: #d52933;
	}
	.landline__helptext {
		font-size: 13px !important;
		margin-top: 5px !important;
		font-weight: normal;
		color: grey;
	}
	.reference__texthelp__electricity {
		font-size: 13px;
		display: flex;
		align-items: center;
		font-weight: normal;
		color: grey;
	}
	.reference__texthelp__success {
		font-size: 13px;
		margin-left: 160px;
		margin-bottom: 15px;
		font-weight: normal;
		color: grey;
	}
	.flight__container {
		margin: 42px 0px;
	}
	.flight__block {
		border-bottom: 0.5px solid #80808030;
		padding: 19px 0;
	}
	.flight__cursor:hover {
		cursor: pointer;
	}
	.flight__block:last-child {
		border-bottom: none;
	}

	.flight__heading__container {
		background: #f3f4f5;
		color: rgba(0, 0, 0, 0.6);
	}
	.flight__heading {
		padding: 15px 80px;
		font-weight: 700;
		font-size: 20px;
	}

	.input-container input {
		position: relative;
	}

	input[type='date']::-webkit-calendar-picker-indicator {
		background: transparent;
		bottom: 0;
		color: transparent;
		cursor: pointer;
		height: auto;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: auto;
	}

	.input__wrapper {
		display: flex;
		align-items: center;
		position: relative;
	}
	.input__wrapper input {
		width: 100%;
		z-index: 1;
	}
	.input__wrapper button.icon__button {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		height: 100%;
		background: transparent;
		width: 20px;
		border: none;
		z-index: 0;
		padding: 5px 25px;
	}
	.input__wrapper button.icon__button:focus {
		outline: none;
		box-shadow: none;
	}

	.flight__data {
		padding: 5px 50px 5px 50px;
	}
	.custom-select .react-select__control {
		border-radius: 0;
	}
	.custom-select input:focus {
		border: none !important;
		box-shadow: none;
	}

	.react-select__menu .react-select__menu-list .react-select__option:hover {
		color: white;
	}
	.react-select__menu .react-select__menu-list .react-select__option--is-focused,
	.react-select__menu .react-select__menu-list .react-select__option--is-selected {
		background-color: ${(props) => props.theme.PrimaryColor};
		color: white !important;
	}

	.react-select__control.react-select__control--is-focused {
		border-color: ${(props) => props.theme.PrimaryColor};
	}

	input[type='date']::-webkit-datetime-edit-year-field {
		position: absolute !important;
		border-left: 2px solid #8c8c8c;
		padding: 2px 7px;
		color: #000;
		left: 62px;
	}

	input[type='date']::-webkit-datetime-edit-text {
		opacity: 0;
		visibility: hidden;
	}

	input[type='date']::-webkit-datetime-edit-month-field {
		position: absolute !important;
		border-left: 2px solid #8c8c8c;
		padding: 2px 7px;
		color: #000;
		left: 28px;
	}

	input[type='date']::-webkit-datetime-edit-day-field {
		position: absolute !important;
		color: #000;
		padding: 2px;
		left: 4px;
	}

	.datapack-badge {
		background-color: ${(props) => props.theme.PrimaryColor};
	}

	.theme-primary {
		color: ${(props) => props.theme.PrimaryColor};
	}
	.theme-secondary {
		color: ${(props) => props.theme.SecondaryColor};
	}
`;
