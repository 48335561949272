import { lazy } from 'react';
export const Home = lazy(() => import('./Home'));
export const Success = lazy(() => import('./Success'));
export const Error = lazy(() => import('./Error'));
export const TrackRequest = lazy(() => import('./TrackRequest'));
export const Electicity = lazy(() => import('./Electricity'));
export const KhanePani = lazy(() => import('./KhanePani'));
export const CreditCard = lazy(() => import('./CreditCard'));
export const Travel = lazy(() => import('./Travel'));
export const NotFound = lazy(() => import('./404'));
export const GovermentServices = lazy(() => import('./GovermentService'));
export const IRD = lazy(() => import('./GovermentService/Services/IRD'));
export const Customs = lazy(() => import('./GovermentService/Services/Customs'));
export const Loksewa = lazy(() => import('./GovermentService/Services/Loksewa'));
export const OCR = lazy(() => import('./GovermentService/Services/OCR'));
export const Passport = lazy(() => import('./GovermentService/Services/Passport'));
export const Revenue = lazy(() => import('./GovermentService/Services/Revenue'));
export const Traffic = lazy(() => import('./GovermentService/Services/Traffic'));
export const SSF = lazy(() => import('./GovermentService/Services/SSF'));
export const DOFE = lazy(() => import('./GovermentService/Services/DOFE'));
export const ForeignEmploymentIndividual = lazy(
	() => import('./GovermentService/Services/DOFE/pages/ForeignEmploymentIndividual')
);
export const ForeignEmploymentManpower = lazy(
	() => import('./GovermentService/Services/DOFE/pages/ForeignEmploymentManpower')
);
export const TopUp = lazy(() => import('./TopUp'));
export const Test = lazy(() => import('./DataPack'));
export const NTC = lazy(() => import('./DataPack/services/NTC'));
export const NCELL = lazy(() => import('./DataPack/services/NCELL'));
export const NcellPaymentForm = lazy(() => import('./DataPack/services/NCELL/NcellPaymentForm'));
export const NtcPaymentForm = lazy(() => import('./DataPack/services/NTC/NtcPaymentForm'));
export const Flight = lazy(() => import('./Flight'));
export const FlightAvailable = lazy(() => import('./Flight/FlightAvailable'));
// For TV Component Export
export const TV = lazy(() => import('./TV'));
export const ClearTV = lazy(() => import('./TV/Services/ClearTV'));
export const Dishhome = lazy(() => import('./TV/Services/DishHome'));
export const MeroTV = lazy(() => import('./TV/Services/MeroTV'));
export const PrabhuTV = lazy(() => import('./TV/Services/PrabhuTV'));
export const SimTV = lazy(() => import('./TV/Services/SimTV'));
export const SkyTV = lazy(() => import('./TV/Services/SkyTV'));

// For Home Page
export const home = lazy(() => import('./Home'));

// For Internet Component Export
export const Internet = lazy(() => import('./Internet'));
export const ClassicTech = lazy(() => import('./Internet/Services/ClassicTech'));
export const Vianet = lazy(() => import('./Internet/Services/Vianet'));
export const Worldlink = lazy(() => import('./Internet/Services/Worldlink'));
export const Subisu = lazy(() => import('./Internet/Services/Subisu'));

// For Demat Form Component Export
export const MuktinathCapitalDemat = lazy(
	() => import('./DematMeroshare/services/MuktinathCapital')
);
export const NibleAceCapitalDemat = lazy(() => import('./DematMeroshare/services/NibleAceCapital'));
export const PrabhuCapitalDemat = lazy(() => import('./DematMeroshare/services/PrabhuCapital'));
// Insurances
export const Insurance = lazy(() => import('./Insurance/index'));
export const Demat = lazy(() => import('./DematMeroshare/index'));
export const NecoInsurance = lazy(() => import('./Insurance/services/NecoInsurance'));
export const NepalLifeInsurance = lazy(() => import('./Insurance/services/NepalLifeInsurance'));
export const Sagarmatha = lazy(() => import('./Insurance/services/SagarmathaInsurance'));
export const PremierInsurance = lazy(() => import('./Insurance/services/PremierInsurance'));
export const UnitedInsurance = lazy(() => import('./Insurance/services/UnitedInsurance'));
export const JyotiLifeInsurance = lazy(() => import('./Insurance/services/JyotiLifeInsurance'));
export const SanimaLifeInsurance = lazy(() => import('./Insurance/services/SanimaLifeInsurance'));
export const NepalInsurance = lazy(() => import('./Insurance/services/NepalInsurance'));
export const RelianceLifeInsurance = lazy(
	() => import('./Insurance/services/RelianceLifeInsurance')
);
export const NLGInsurance = lazy(() => import('./Insurance/services/NLGInsurance'));
export const NationalLifeInsurance = lazy(
	() => import('./Insurance/services/NationalLifeInsurance')
);
export const AjodInsurance = lazy(() => import('./Insurance/services/AjodInsurance'));
export const HimalayanGeneralInsurance = lazy(
	() => import('./Insurance/services/HimalayanGeneralInsurance')
);
export const GeneralInsurance = lazy(() => import('./Insurance/services/GeneralInsurance'));
export const SiddharthaInsurance = lazy(() => import('./Insurance/services/SiddharthaInsurance'));
export const UnionLifeInsurance = lazy(() => import('./Insurance/services/UnionLifeInsurance'));
export const SuryaLifeInsurance = lazy(() => import('./Insurance/services/SuryaLifeInsurance'));
export const SanimaGeneralInsurance = lazy(
	() => import('./Insurance/services/SanimaGeneralInsurance')
);
export const ShikharlInsurance = lazy(() => import('./Insurance/services/ShikharInsurance'));
export const UnionLifeFirstPaymentlInsurance = lazy(
	() => import('./Insurance/services/UnionLifeFirstPaymentInsurance')
);
export const PrudentialInsurance = lazy(() => import('./Insurance/services/PrudentialInsurance'));
export const MahalaxmiInsurance = lazy(() => import('./Insurance/services/MahalaxmiInsurance'));
export const LumbiniGeneralInsurance = lazy(
	() => import('./Insurance/services/LumbiniGeneralInsurance')
);
export const EverestInsurance = lazy(() => import('./Insurance/services/EverestInsurance'));
export const SuryaLifeFirstPaymentInsurance = lazy(
	() => import('./Insurance/services/SuryaLifeFirstPaymentInsurance')
);
export const IMEGeneralInsurance = lazy(() => import('./Insurance/services/IMEGeneralInsurance'));
export const OrientalInsurance = lazy(() => import('./Insurance/services/OrientalInsurance'));
export const JyotiLifeFirstPaymentInsurance = lazy(
	() => import('./Insurance/services/JyotiLifeFirstPayment')
);
export const AsianLifeInsurance = lazy(() => import('./Insurance/services/AsianLifeInsurance'));
export const PrabhuLifeInsurance = lazy(() => import('./Insurance/services/PrabhuLifeInsurance'));
export const PrimelifeInsurance = lazy(() => import('./Insurance/services/PrimeLifeInsurance'));
export const Landline = lazy(() => import('./Landline'));

export const VisaDirectTransfer = lazy(() => import('./VisaDirectTransfer'));
