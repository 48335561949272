/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_FlightBookingRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_FlightBookingRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_FlightBookingResponseDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_FlightBookingResponseDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_FlightListDetails } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_FlightListDetails';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_FlightSearchResponseDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_FlightSearchResponseDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_IssueFlightRequest } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_IssueFlightRequest';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_PassengerInfoRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_PassengerInfoRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_PassengerInfoResponse } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_PassengerInfoResponse';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class FlightService {

    /**
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_FlightListDetails Success
     * @throws ApiError
     */
    public static getFlight(): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_FlightListDetails> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/flight/flight-list-details`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param flightType
     * @param tripType
     * @param flightDate
     * @param returnDate
     * @param adult
     * @param child
     * @param from
     * @param to
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_FlightSearchResponseDto Success
     * @throws ApiError
     */
    public static getFlight1(
        flightType?: string,
        tripType?: string,
        flightDate?: string,
        returnDate?: string,
        adult?: number,
        child?: number,
        from?: string,
        to?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_FlightSearchResponseDto> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/flight/flight-search`,
            query: {
                'FlightType': flightType,
                'TripType': tripType,
                'FlightDate': flightDate,
                'ReturnDate': returnDate,
                'Adult': adult,
                'Child': child,
                'From': from,
                'To': to,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_FlightBookingResponseDto Success
     * @throws ApiError
     */
    public static postFlight(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_FlightBookingRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_FlightBookingResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/flight/book-fLight`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_PassengerInfoResponse Success
     * @throws ApiError
     */
    public static postFlight1(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_PassengerInfoRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_PassengerInfoResponse> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/flight/passenger-info`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postFlight2(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Flight_IssueFlightRequest,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/flight/issue-flight`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}