import { themeConstants } from 'redux/constant/theme.constant';
import axios from 'axios';

const API_BASE_URL =
	window.__RUNTIME_CONFIG__.REACT_APP_ADMIN_URL +
	'/admin-api/api/app/cms-settings/settings-by-type?cmsSettingTypeName=';

const getTheme = async () => {
	const res = await axios.get(`${API_BASE_URL}theme-setting`);
	return res.data;
};
const getImage = async () => {
	const res = await axios.get(`${API_BASE_URL}image-setting`);
	return res.data;
};
const getContact = async () => {
	const res = await axios.get(`${API_BASE_URL}customer-support-setting`);
	return res.data;
};
const getSocialLinks = async () => {
	const res = await axios.get(`${API_BASE_URL}social-links-setting`);
	return res.data;
};
const getAppLink = async () => {
	const res = await axios.get(`${API_BASE_URL}mobile-app-setting`);
	return res.data;
};
const getBankName = async () => {
	const res = await axios.get(`${API_BASE_URL}copyright-setting`);
	return res.data;
};
const getFooterSideMenu = async () => {
	const res = await axios.get(`${API_BASE_URL}menu-setting`);
	return res.data;
};

export const themeAction = () => {
	return async (dispatch: any) => {
		dispatch({
			type: themeConstants.GET_THEME_SETTINGS_REQUEST,
		});
		try {
			let payload: any = {};

			const [theme, image, contact, socialLink, appLink, bankName, footerSideMenu]: any =
				await Promise.allSettled([
					getTheme(),
					getImage(),
					getContact(),
					getSocialLinks(),
					getAppLink(),
					getBankName(),
					getFooterSideMenu(),
				]);
			payload['theme'] = theme?.value;
			payload['image'] = image?.value;
			payload['contact'] = contact?.value;
			payload['socialLink'] = socialLink?.value;
			payload['appLink'] = appLink?.value;
			payload['bankName'] = bankName?.value;
			payload['footerSideMenu'] = footerSideMenu?.value;

			dispatch({
				type: themeConstants.GET_THEME_SETTINGS_SUCCESS,
				payload: payload,
			});
		} catch (err: any) {
			dispatch({
				type: themeConstants.GET_THEME_SETTINGS_FAILED,
			});
		}
	};
};
