import React from 'react';
const NotFoundSearch = () => {
	return (
		<div className="not__found__search__container">
			<h6 className="not__found__search__title">Not Found. Please Search Correct One.</h6>
		</div>
	);
};

export default React.memo(NotFoundSearch);
