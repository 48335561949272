import React from 'react';

interface Props {
	image?: any;
	title: string;
}

const Card: React.FC<Props> = ({ image, title }) => {
	return (
		<div>
			<div className="card_image_wrapper mb-2">
				<img src={image} alt="logo" className="img-fluid" />
			</div>
			<h6 className="card__text">{title}</h6>
		</div>
	);
};

export default React.memo(Card);
