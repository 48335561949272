/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_ElectricityDto_ConsumerResponseBillsDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_ElectricityDto_ConsumerResponseBillsDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_ElectricityDto_ConsumerResponseServiceChargeDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_ElectricityDto_ConsumerResponseServiceChargeDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_ElectricityDto_CounterDetailsDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_ElectricityDto_CounterDetailsDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_ElectricityDto_ElectricityPaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_ElectricityDto_ElectricityPaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ElectricityService {

    /**
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_ElectricityDto_CounterDetailsDto Success
     * @throws ApiError
     */
    public static getElectricity(): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_ElectricityDto_CounterDetailsDto> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/electricity/counters-details`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param scNumber
     * @param consumerId
     * @param officeCode
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_ElectricityDto_ConsumerResponseBillsDto Success
     * @throws ApiError
     */
    public static getElectricity1(
        scNumber?: string,
        consumerId?: string,
        officeCode?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_ElectricityDto_ConsumerResponseBillsDto> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/electricity/consumer-pending-bills`,
            query: {
                'SC_Number': scNumber,
                'ConsumerId': consumerId,
                'OfficeCode': officeCode,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postElectricity(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_ElectricityDto_ElectricityPaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/electricity/electricity-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param amount
     * @param sessionId
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_ElectricityDto_ConsumerResponseServiceChargeDto Success
     * @throws ApiError
     */
    public static getElectricity2(
        amount?: number,
        sessionId?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_ElectricityDto_ConsumerResponseServiceChargeDto> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/electricity/consumer-service-charge`,
            query: {
                'Amount': amount,
                'SessionId': sessionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}