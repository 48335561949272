/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_ClearTvRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_ClearTvRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_MeroTVCustomerDetails } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_MeroTVCustomerDetails';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_MeroTvPaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_MeroTvPaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_Package_list } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_Package_list';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_PrabhuTVCustomerDetails } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_PrabhuTVCustomerDetails';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_PrabhuTvPaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_PrabhuTvPaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_SkyTVCustomerDetails } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_SkyTVCustomerDetails';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_SkyTvPaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_SkyTvPaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_TVCustomerDetails } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_TVCustomerDetails';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_TVPaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_TVPaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class TvService {

    /**
     * @param casId
     * @param serviceName
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_TVCustomerDetails Success
     * @throws ApiError
     */
    public static getTv(
        casId: string,
        serviceName?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_TVCustomerDetails> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/t-v/t-vUser-detail/${casId}`,
            query: {
                'serviceName': serviceName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postTv(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_ClearTvRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/t-v/clear-tv-payment`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param customerId
     * @param stb
     * @param serviceName
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_SkyTVCustomerDetails Success
     * @throws ApiError
     */
    public static getTv1(
        customerId?: string,
        stb?: string,
        serviceName?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_SkyTVCustomerDetails> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/t-v/sky-tv-customer`,
            query: {
                'CustomerId': customerId,
                'Stb': stb,
                'ServiceName': serviceName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postTv1(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_TVPaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/t-v/t-vPayment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param stb
     * @param serviceName
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_MeroTVCustomerDetails Success
     * @throws ApiError
     */
    public static getTv2(
        stb?: string,
        serviceName?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_MeroTVCustomerDetails> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/t-v/mero-tv-user-detail`,
            query: {
                'stb': stb,
                'serviceName': serviceName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param stb
     * @param offerName
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_Package_list Success
     * @throws ApiError
     */
    public static getTv3(
        stb?: string,
        offerName?: string,
    ): CancelablePromise<Array<Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_Package_list>> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/t-v/mero-tv-offer-by-name`,
            query: {
                'stb': stb,
                'offerName': offerName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param casId
     * @param serviceName
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_PrabhuTVCustomerDetails Success
     * @throws ApiError
     */
    public static getTv4(
        casId: string,
        serviceName?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_PrabhuTVCustomerDetails> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/t-v/prabhu-tv-user-detail/${casId}`,
            query: {
                'serviceName': serviceName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postTv2(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_MeroTvPaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/t-v/mero-tv-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postTv3(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_SkyTvPaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/t-v/sky-tv-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postTv4(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_TV_PrabhuTvPaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/t-v/prabhu-tv-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}