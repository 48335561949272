import React from 'react';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import { RiErrorWarningFill } from 'react-icons/ri';
import { AiOutlineCalendar } from 'react-icons/ai';
interface IProps {
	placeholder: string;
	type?: string;
	label?: string;
	defaultValue?: string | number;
	disabled?: boolean;
	readOnly?: boolean;
	onKeyDown?: any;
	propsclassName?: string;
	icons?: any;
	max?: any;
	capitalize?: boolean;
}

const FlightInfoField = ({
	capitalize = true,
	placeholder,
	label,
	type,
	icons,
	disabled,
	readOnly,
	max,
	onKeyDown,
	defaultValue,
	propsclassName,
	...otherProps
}: IProps & FieldHookConfig<string>) => {
	const [field, meta] = useField(otherProps);
	return (
		<div className="input-container input__text__feild__gap form-group mt-2">
			<label
				className={`input__label ${
					meta.error && meta.touched ? 'input__label__error__color' : 'position-relative'
				}`}
				htmlFor={field.name}>
				{label}{' '}
				<b className="text-danger" style={{ marginLeft: '.2rem' }}>
					*
				</b>
			</label>
			<div className="input__wrapper">
				<input
					className={`form-control ${capitalize ? 'text-capitalize' : ''}  ${
						meta.touched && meta.error ? 'is-invalid' : ''
					} 
					${(meta.error || propsclassName === 'reset-form') && type !== 'date' ? '' : 'is-valid'}`}
					{...field}
					type={type}
					placeholder={placeholder}
					defaultValue={defaultValue}
					autoComplete="off"
					disabled={disabled}
					readOnly={readOnly}
					onKeyDown={onKeyDown}
					max={type === 'date' ? '9999-12-31' : ''}
				/>
				{meta.error && (
					<>
						{icons && (
							<button className="icon__button">
								<AiOutlineCalendar color="#28a745" />
							</button>
						)}
					</>
				)}
			</div>
			{meta.touched && meta.error ? (
				<div className="input__error__icon">
					<RiErrorWarningFill />
				</div>
			) : (
				''
			)}
			<ErrorMessage component="div" name={field.name} className="input__error__container mb-3" />
		</div>
	);
};

export default React.memo(FlightInfoField);
