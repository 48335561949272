import { menuConstants } from 'redux/constant/menu.constant';

interface MenuState {
	isLoading: boolean;
	isError: boolean;
	headerMenuData: any;
	footerMenuData: any;
	metaDataSource: any;
}

const initState: MenuState = {
	isLoading: false,
	isError: false,
	headerMenuData: [],
	footerMenuData: [],
	metaDataSource: '',
};

export const menuReducer = (
	state: MenuState = initState,
	{ type, payload }: { type: string; payload: any }
) => {
	switch (type) {
		case menuConstants.GET_MENU_ITEMS_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}
		case menuConstants.GET_MENU_ITEMS_SUCCESS: {
			const headerMenuData: any = payload?.headerMenuData;
			const footerMenuData: any = payload?.footerMenuData;
			const metaDataSource: any = payload?.metaDataSource;
			return {
				...state,
				headerMenuData: headerMenuData,
				footerMenuData: footerMenuData,
				metaDataSource: metaDataSource,
				isLoading: false,
			};
		}
		case menuConstants.GET_MENU_ITEMS_FAILED: {
			return {
				...state,
				isLoading: false,
				isError: true,
			};
		}
		default:
			return state;
	}
};
