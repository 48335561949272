/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceGroupDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceGroupDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServicesDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServicesDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class UtilityService {

    /**
     * @param utilityServiceGroupIdentifier
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceGroupDto Success
     * @throws ApiError
     */
    public static getUtility(
        utilityServiceGroupIdentifier?: string,
    ): CancelablePromise<Array<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceGroupDto>> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/utility/utility-service-group`,
            query: {
                'utilityServiceGroupIdentifier': utilityServiceGroupIdentifier,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param utilityServiceGroupIdentifier
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServicesDto Success
     * @throws ApiError
     */
    public static getUtility1(
        utilityServiceGroupIdentifier?: string,
    ): CancelablePromise<Array<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServicesDto>> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/utility/utility-services`,
            query: {
                'utilityServiceGroupIdentifier': utilityServiceGroupIdentifier,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}