/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from '../../config';
import { useSelector } from 'react-redux';

const NabilHeader = () => {
	const [active, setActive] = React.useState(false);
	const [activeCall, setActiveCall] = React.useState(false);
	const [activeCallInner, setActiveCallInner] = React.useState(false);
	const [response, setResponse] = React.useState([]);
	const [topRight, setTopRight] = React.useState([]);
	const [contact, setContact] = React.useState([]);
	const { themeSettings } = useSelector((state: any) => state.theme);

	const { image } = useSelector((state: any) => state?.theme);

	const username = window.__RUNTIME_CONFIG__.MENU_TYPE_API_USERNAME;
	const imageUrl = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_IMAGE_ENDPOINT;

	let data = new FormData();
	data.append('username', username);
	data.append('type', 'head');
	const fetchFooterData = async () => {
		try {
			const res = await axios.post('/', data);
			setResponse(res.data.data);
		} catch (err: any) {
			toast.error(err?.response);
		}
	};
	let data1 = new FormData();
	data1.append('username', username);
	data1.append('type', 'top-right');
	const fetchFooterData1 = async () => {
		try {
			const res = await axios.post('/', data1);
			setTopRight(res.data.data);
		} catch (err: any) {
			toast.error(err.response);
		}
	};
	let data2 = new FormData();
	data2.append('username', username);
	data2.append('type', 'contact');
	const fetchFooterData2 = async () => {
		try {
			const res: any = await axios.post('/', data2);
			setContact(res.data.data);
		} catch (err: any) {
			toast.error(err.response);
		}
	};
	const [showSideBar, setShowSideBar] = useState<boolean>(false);
	React.useEffect(() => {
		fetchFooterData();
		fetchFooterData1();
		fetchFooterData2();
	}, []);
	return (
		<div>
			<header className="navbar border-bottom mob-header">
				<div className="container-fluid py-2">
					<div className="d-flex w-100 align-items-center justify-content-between">
						<div className="navbar-header d-flex align-items-center justify-content-between w-100">
							<a
								className="logo navbar-btn pull-left img-fluid"
								href="https://nbankhome.nabilbank.com/"
								title="Home">
								<img src={imageUrl + image?.SecondaryLogo || ''} alt="Home" height="32px" />
							</a>
							<a href="https://www.nabilbank.com" target="_blank" rel="noopener noreferrer">
								<img
									src={imageUrl + image?.PrimaryLogo || ''}
									className="img-fluid mx-5"
									width="120px"
									alt=""
								/>
							</a>
						</div>
						<div
							className="hambuger-menu text-right d-flex align-items-center"
							onClick={() => {
								setShowSideBar(!showSideBar);
							}}>
							<div className="hambuger-menu h4">
								<svg
									stroke="currentColor"
									fill="currentColor"
									strokeWidth={0}
									viewBox="0 0 16 16"
									className="text-danger"
									height="1em"
									width="1em"
									xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M14 5H2V3h12v2zm0 4H2V7h12v2zM2 13h12v-2H2v2z"
									/>
								</svg>
							</div>
						</div>
					</div>
				</div>
			</header>
			<ul className="digibank-dropdown payment-list list-unstyled p-0 m-0">
				<li className="payment-item">
					<a href="">
						<svg
							width={19}
							height={16}
							viewBox="0 0 19 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path d="M0 11.8893H18.1495V13.1857H0V11.8893Z" fill="#625F5F" />
							<path d="M0 14.4818H18.1495V15.7782H0V14.4818Z" fill="#625F5F" />
							<path
								d="M14.2601 4.11108C14.0037 4.11108 13.7531 4.18712 13.5399 4.32957C13.3267 4.47201 13.1605 4.67448 13.0624 4.91137C12.9643 5.14825 12.9386 5.40891 12.9887 5.66039C13.0387 5.91186 13.1621 6.14286 13.3434 6.32416C13.5248 6.50546 13.7557 6.62893 14.0072 6.67895C14.2587 6.72898 14.5194 6.7033 14.7562 6.60518C14.9931 6.50706 15.1956 6.3409 15.338 6.12771C15.4805 5.91452 15.5565 5.66388 15.5565 5.40747C15.5565 5.06365 15.4199 4.73391 15.1768 4.49079C14.9337 4.24767 14.604 4.11108 14.2601 4.11108Z"
								fill="#625F5F"
							/>
							<path
								d="M9.07459 8.00014C8.56179 8.00014 8.0605 7.84807 7.63412 7.56318C7.20774 7.27828 6.87542 6.87334 6.67918 6.39957C6.48293 5.9258 6.43159 5.40448 6.53163 4.90153C6.63168 4.39858 6.87861 3.93659 7.24122 3.57398C7.60383 3.21138 8.06582 2.96444 8.56877 2.8644C9.07172 2.76435 9.59304 2.8157 10.0668 3.01194C10.5406 3.20818 10.9455 3.54051 11.2304 3.96689C11.5153 4.39327 11.6674 4.89455 11.6674 5.40736C11.6665 6.09474 11.3931 6.75373 10.907 7.23978C10.421 7.72584 9.76198 7.99928 9.07459 8.00014ZM9.07459 4.11097C8.81819 4.11097 8.56755 4.187 8.35436 4.32945C8.14117 4.4719 7.97501 4.67437 7.87688 4.91125C7.77876 5.14813 7.75309 5.4088 7.80311 5.66027C7.85313 5.91175 7.9766 6.14274 8.15791 6.32404C8.33921 6.50535 8.5702 6.62882 8.82168 6.67884C9.07316 6.72886 9.33382 6.70319 9.5707 6.60507C9.80759 6.50694 10.0101 6.34078 10.1525 6.12759C10.295 5.9144 10.371 5.66376 10.371 5.40736C10.3706 5.06364 10.2339 4.7341 9.9909 4.49105C9.74786 4.248 9.41831 4.11131 9.07459 4.11097Z"
								fill="#625F5F"
							/>
							<path
								d="M3.88929 4.11108C3.63288 4.11108 3.38224 4.18712 3.16905 4.32957C2.95586 4.47201 2.7897 4.67448 2.69158 4.91137C2.59346 5.14825 2.56778 5.40891 2.61781 5.66039C2.66783 5.91186 2.7913 6.14286 2.9726 6.32416C3.1539 6.50546 3.3849 6.62893 3.63637 6.67895C3.88785 6.72898 4.14851 6.7033 4.38539 6.60518C4.62228 6.50706 4.82475 6.3409 4.9672 6.12771C5.10964 5.91452 5.18568 5.66388 5.18568 5.40747C5.18568 5.06365 5.04909 4.73391 4.80597 4.49079C4.56285 4.24767 4.23311 4.11108 3.88929 4.11108Z"
								fill="#625F5F"
							/>
							<path
								d="M16.8531 10.5929H1.29639C0.952829 10.5921 0.623584 10.4552 0.380649 10.2123C0.137714 9.96934 0.000855698 9.64009 0 9.29653V1.51819C0.000855698 1.17463 0.137714 0.845386 0.380649 0.602451C0.623584 0.359516 0.952829 0.222657 1.29639 0.221802H16.8531C17.1966 0.222657 17.5259 0.359516 17.7688 0.602451C18.0118 0.845386 18.1486 1.17463 18.1495 1.51819V9.29653C18.149 9.6402 18.0122 9.96964 17.7692 10.2127C17.5262 10.4557 17.1967 10.5924 16.8531 10.5929ZM16.8531 1.51819H1.29639V9.29653H16.8531V1.51819Z"
								fill="#625F5F"
							/>
						</svg>
						Online Account Opening
					</a>
				</li>
				<li className="payment-item">
					<a href="">
						<img src="https://digibasket.nabilbank.com/assets/frontend/images/icons/Icons-1.svg" />
						Fixed Deposit
					</a>
				</li>
				<li className="payment-item">
					<a href="">
						<img src="https://digibasket.nabilbank.com/assets/frontend/images/icons/Icons-2.svg" />
						Credit Card
					</a>
				</li>
				<li className="payment-item">
					<a href="">
						<img src="https://digibasket.nabilbank.com/assets/frontend/images/icons/Icons-3.svg" />
						Document Processing
					</a>
				</li>
				<li className="payment-item">
					<a href="">
						<img src="https://digibasket.nabilbank.com/assets/frontend/images/icons/Icons-4.svg" />
						E-KYC
					</a>
				</li>
				<li className="payment-item">
					<a href="">
						<img src="https://digibasket.nabilbank.com/assets/frontend/images/icons/Icons-5.svg" />
						Top-Up
					</a>
				</li>
				<li className="payment-item">
					<a href="">
						<img src="https://digibasket.nabilbank.com/assets/frontend/images/icons/Icons-6.svg" />
						School Fees
					</a>
				</li>
				<li className="payment-item">
					<a href="">
						<img src="https://digibasket.nabilbank.com/assets/frontend/images/icons/group.svg" />
						Internet
					</a>
				</li>
				<li className="payment-item">
					<a href="">
						<img src="https://digibasket.nabilbank.com/assets/frontend/images/icons/Icons-7.svg" />
						Heh
					</a>
				</li>
				<li className="payment-item">
					<a href="">
						<img src="https://digibasket.nabilbank.com/assets/frontend/images/icons/Icons-8.svg" />
						Khanepani
					</a>
				</li>
				<li className="payment-item">
					<a href="">
						<img src="https://digibasket.nabilbank.com/assets/frontend/images/icons/Icons-9.svg" />
						Airlines
					</a>
				</li>
			</ul>

			<div id="sidebar" className={`sidemenu ${showSideBar ? 'menu-opened' : 'menu-hide'}`}>
				<header className="navbar border-bottom">
					<div className="container-fluid py-2">
						<div className="d-flex  w-100 align-items-center justify-content-between">
							<div className="navbar-header d-flex align-items-center justify-content-between w-100">
								<a
									className="logo navbar-btn pull-left"
									href="https://nbankhome.nabilbank.com/"
									title="Home"
									rel="home">
									<img
										// src={`${BaseUrl}/assets/frontend/images/digibanklogo.png`}
										src={imageUrl + image?.SecondaryLogo || ''}
										height="32px"
										alt="Home"
									/>
								</a>
								<a href="https://www.nabilbank.com" target="_blank" rel="noopener noreferrer">
									<img
										src={imageUrl + image?.PrimaryLogo || ''}
										className="img-fluid mx-5"
										width="120px"
										alt=""
									/>
								</a>
							</div>
							<div className=" text-right" onClick={() => setShowSideBar(!showSideBar)}>
								<div className="h4">
									<svg
										stroke="currentColor"
										fill="currentColor"
										strokeWidth={0}
										viewBox="0 0 512 512"
										className="text-danger"
										height="1em"
										width="1em"
										xmlns="http://www.w3.org/2000/svg">
										<path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</header>
				<div className="sidebarmenu-wrapper">
					<ul className="listview secondary-menu  list-unstyled">
						<li className="nav-item has-dropdown ">
							{response
								? response.map((val: any, idx: number) => (
										<React.Fragment key={idx}>
											<a className="nav-link" onClick={() => setActiveCall(!activeCall)}>
												{val?.name}
											</a>
											<button className="lvl-toggle-btn">
												<svg
													stroke="currentColor"
													fill="none"
													strokeWidth={2}
													viewBox="0 0 24 24"
													strokeLinecap="round"
													strokeLinejoin="round"
													height="1em"
													width="1em"
													xmlns="http://www.w3.org/2000/svg">
													<polyline points="6 9 12 15 18 9" />
												</svg>
											</button>
											<ul
												className={`inner-list opened-list list-unstyled ps-3 ${
													activeCall ? 'd-flex flex-column' : 'd-none'
												}`}
												style={{ background: 'rgb(219, 226, 230)' }}>
												{val.children &&
													val.children.map((e: any, i: number) => (
														<li className="inner-list-product" key={i}>
															<a
																onClick={() => setActiveCallInner(!activeCallInner)}
																href={e?.siteurl}>
																{e?.name}
															</a>
															<button className="lvl-toggle-btn">
																<svg
																	stroke="currentColor"
																	fill="none"
																	strokeWidth={2}
																	viewBox="0 0 24 24"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	height="1em"
																	width="1em"
																	xmlns="http://www.w3.org/2000/svg">
																	<polyline points="6 9 12 15 18 9" />
																</svg>
															</button>
															<ul
																className={`inner-list opened-list list-unstyled ${
																	activeCallInner ? 'd-none' : 'd-none'
																}`}>
																{e.children &&
																	e.children.map((sub: any, id: number) => (
																		<li className="inner-list-title d-flex" key={id}>
																			<a href={sub?.siteurl}>
																				<strong>{sub?.name}</strong>
																			</a>
																		</li>
																	))}
															</ul>
														</li>
													))}
											</ul>
										</React.Fragment>
								  ))
								: null}
						</li>
					</ul>
					<ul className="listview tertary-menu list-unstyled">
						<li className="nav-item has-dropdown " onClick={() => setActive(!active)}>
							{topRight
								? topRight.map((e: any, i: number) => (
										<React.Fragment key={i}>
											<a className="nav-link">{e?.name}</a>
											<button className="lvl-toggle-btn">
												<svg
													stroke="currentColor"
													fill="none"
													strokeWidth={2}
													viewBox="0 0 24 24"
													strokeLinecap="round"
													strokeLinejoin="round"
													height="1em"
													width="1em"
													xmlns="http://www.w3.org/2000/svg">
													<polyline points="6 9 12 15 18 9" />
												</svg>
											</button>
											{e.children &&
												e.children.map((sub: any, idx: number) => (
													<ul
														className={`inner-list list-unstyled opened-list ps-3 ${
															active ? 'd-flex' : 'd-none'
														}`}
														style={{ background: 'rgb(219, 226, 230)' }}
														key={idx}>
														<li className="inner-list-product">
															<a href={sub?.siteurl}>{sub?.name}</a>
														</li>
													</ul>
												))}
										</React.Fragment>
								  ))
								: null}
						</li>
					</ul>
					{contact
						? contact.map((e: any, i: number) => (
								<React.Fragment key={i}>
									<div className="contact-info contact-number">
										<label className="mb-2">Call</label>

										<a href={`tel: ${e.name}`} className="text-purple mb-2">
											<svg
												stroke="currentColor"
												fill="currentColor"
												strokeWidth={0}
												viewBox="0 0 512 512"
												className="text-danger me-2"
												height="1em"
												width="1em"
												xmlns="http://www.w3.org/2000/svg">
												<path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
											</svg>{' '}
											{e.name}
										</a>
										<a href={`viber://chat?number=${e.children[0].name}`} className="text-purple">
											<svg
												stroke="currentColor"
												fill="currentColor"
												strokeWidth={0}
												viewBox="0 0 512 512"
												className="text-danger me-2"
												height="1em"
												width="1em"
												xmlns="http://www.w3.org/2000/svg">
												<path d="M444 49.9C431.3 38.2 379.9.9 265.3.4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9.4-85.7.4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9.4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9.6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4.7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5.9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9.1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7.5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1.8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z"></path>
											</svg>{' '}
											{e.children[0].name}
										</a>
									</div>
									<div className="contact-info contact-number">
										<label className="mb-2">Email</label>
										<a href={`mailto:${e.children[1].name}`} className="text-purple mb-2 ">
											<svg
												stroke="currentColor"
												fill="none"
												strokeWidth={0}
												viewBox="0 0 24 24"
												className="text-danger me-2"
												height="1em"
												width="1em"
												xmlns="http://www.w3.org/2000/svg">
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth={2}
													d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
											</svg>
											{e.children[1].name}
										</a>
									</div>
								</React.Fragment>
						  ))
						: null}
				</div>
			</div>
			<header className="position-relative">
				<div className="desktop-header">
					<div className="container">
						<div className="navigation-wrapper">
							<div className="navigation-top">
								<div className="navigation-top--left">
									<a href="https://www.nabilbank.com" target="_blank" rel="noopener noreferrer">
										<img src={imageUrl + image?.PrimaryLogo || ''} alt="logo" width={120} />
									</a>
								</div>
								<div className="navigation-top--right">
									<div className="navigation-dropdown lang-dropdown">
										{contact
											? contact.map((e: any, i: number) => (
													<React.Fragment key={i}>
														<span
															className="d-flex align-items-center"
															role="button"
															style={{
																padding: '10px 0 12px',
																color: themeSettings?.PrimaryColor,
															}}>
															<a href={`tel: ${e.name}`} className="p-0 pe-2" style={{
																color: themeSettings?.PrimaryColor,
															}}>
																{e.name}
															</a>
															<svg
																onClick={() => setActiveCall(!activeCall)}
																stroke="currentColor"
																fill="currentColor"
																strokeWidth={0}
																viewBox="0 0 1024 1024"
																height="1em"
																width="1em"
																xmlns="http://www.w3.org/2000/svg"
																style={{ color: themeSettings?.PrimaryColor, fontSize: 10 }}>
																<path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
															</svg>
														</span>
														<ul
															className={` header__after__click ${
																activeCall ? 'd-flex' : 'd-none '
															}`}>
															<li>
																<a
																	href={`viber://chat?number=${e.children[0].name}`}
																	className="d-flex align-items-center ">
																	<svg
																		stroke="currentColor"
																		fill="currentColor"
																		strokeWidth={0}
																		viewBox="0 0 512 512"
																		className="me-2 h4 mb-0"
																		height="1em"
																		width="1em"
																		xmlns="http://www.w3.org/2000/svg">
																		<path d="M444 49.9C431.3 38.2 379.9.9 265.3.4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9.4-85.7.4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9.4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9.6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4.7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5.9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9.1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7.5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1.8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z"></path>
																	</svg>
																	{e.children[0].name}
																</a>
															</li>
															<li>
																<a
																	href={`mailto:${e.children[1].name}`}
																	className="d-flex align-items-center ">
																	<svg
																		stroke="currentColor"
																		fill="none"
																		strokeWidth={0}
																		viewBox="0 0 24 24"
																		className="me-2 h4 mb-0"
																		height="1em"
																		width="1em"
																		xmlns="http://www.w3.org/2000/svg">
																		<path
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			strokeWidth={2}
																			d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
																	</svg>
																	{e.children[1].name}
																</a>
															</li>
														</ul>
													</React.Fragment>
											  ))
											: null}
									</div>
									<div className="navigation-dropdown" style={{ padding: '10px 0 12px' }}>
										{topRight
											? topRight.map((e: any, i: number) => (
													<div className="dropdown" key={i}>
														<span
															className="dropdown-toggle"
															role="button"
															data-bs-toggle="dropdown"
															aria-expanded="false">
															<a>{e?.name}</a>
														</span>

														<ul className="dropdown-menu dropdown-menu-end">
															{e.children &&
																e.children.map((sub: any, idx: number) => (
																	<li key={idx} className="dropdown-item py-2">
																		<a href={sub?.siteurl}>{sub?.name}</a>
																	</li>
																))}
														</ul>
													</div>
											  ))
											: null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="desktop-header">
					<div className="container">
						<div className="navigation-wrapper">
							<div className="navigation-bottom d-flex justify-content-between  position-relative">
								<a
									aria-current="page"
									className="py-3 active"
									href="https://nbankhome.nabilbank.com/">
									<figure className="mb-0" style={{ minWidth: 175 }}>
										<img
											src={imageUrl + image?.SecondaryLogo || ''}
											alt="Nabil Bank"
											className="img-fluid"
										/>
									</figure>
								</a>

								<div className="d-flex align-items-center menu-wrapper">
									<ul className="d-flex align-items-center list-unstyled mb-0 hover-underline menu">
										{response &&
											response.map((val: any, idx: number) => (
												<React.Fragment key={idx}>
													<li className="hasdropdown py-3">
														<a className="d-block px-3 " href="">
															{val?.name}
															<svg
																className="svg-inline--fa fa-angle-down fa-w-10 mx-1"
																style={{ height: '16px' }}
																aria-hidden="true"
																focusable="false"
																data-prefix="fas"
																data-icon="angle-down"
																role="img"
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 320 512"
																data-fa-i2svg="">
																<path
																	fill="currentColor"
																	d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
															</svg>
														</a>

														<ul className="list-unstyled  m-0">
															{val.children &&
																val.children.map((e: any, i: number) => (
																	<li className={`${e.children ? 'has-children' : ''}`} key={i}>
																		<a href={e?.siteurl}>
																			<strong>{e?.name}</strong>
																		</a>
																		<ul className="list-unstyled  m-0">
																			{e.children &&
																				e.children.map((sub: any, id: number) => (
																					<li key={id}>
																						<a href={sub?.siteurl}>
																							<strong>{sub?.name}</strong>
																						</a>
																					</li>
																				))}
																		</ul>
																	</li>
																))}
														</ul>
													</li>
												</React.Fragment>
											))}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</div>
	);
};

export default React.memo(NabilHeader);
