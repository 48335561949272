import { combineReducers } from 'redux';
import { authReducer } from './auth.reducer';
import { themeReducer } from './theme.reducer';
import { menuReducer } from './menu.reducer';

const rootReducers = combineReducers({
	auth: authReducer,
	theme: themeReducer,
	menu: menuReducer,
});

export default rootReducers;
