/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityService_PaymentIntegrations_Dtos_WorldlinkUserDetailsResponseDto } from '../models/Amnil_UtilityService_PaymentIntegrations_Dtos_WorldlinkUserDetailsResponseDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_ClassicTechPackageDetailsDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_ClassicTechPackageDetailsDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_ClassicTechPaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_ClassicTechPaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_SubisuPaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_SubisuPaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_VianetPaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_VianetPaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_VianetUserResponseDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_VianetUserResponseDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_WorldLinkPaymentRequestDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_WorldLinkPaymentRequestDto';
import type { Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto } from '../models/Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class InternetService {

    /**
     * @param username
     * @returns Amnil_UtilityService_PaymentIntegrations_Dtos_WorldlinkUserDetailsResponseDto Success
     * @throws ApiError
     */
    public static postInternet(
        username?: string,
    ): CancelablePromise<Amnil_UtilityService_PaymentIntegrations_Dtos_WorldlinkUserDetailsResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/internet/worldlink-user-details`,
            query: {
                'username': username,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static getInternet(): CancelablePromise<string> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/internet/reference-for-subisu`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInternet1(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_WorldLinkPaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/internet/world-link-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInternet2(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_SubisuPaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/internet/subisu-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param customerId
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_VianetUserResponseDto Success
     * @throws ApiError
     */
    public static getInternet1(
        customerId: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_VianetUserResponseDto> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/internet/vianet-bill-details/${customerId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInternet3(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_VianetPaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/internet/via-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param username
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_ClassicTechPackageDetailsDto Success
     * @throws ApiError
     */
    public static getInternet2(
        username?: string,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_ClassicTechPackageDetailsDto> {
        return __request({
            method: 'GET',
            path: `/framework/api/app/internet/classic-tech-package`,
            query: {
                'username': username,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto Success
     * @throws ApiError
     */
    public static postInternet4(
        requestBody?: Amnil_UtilityService_UtilityServiceRequests_Dtos_Internet_ClassicTechPaymentRequestDto,
    ): CancelablePromise<Amnil_UtilityService_UtilityServiceRequests_Dtos_UtilityServiceResponseDto> {
        return __request({
            method: 'POST',
            path: `/framework/api/app/internet/classic-tech-payment-request`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}