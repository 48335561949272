import { memo, useEffect, useState } from 'react';

const ScrollToTop = () => {
	const [refresh, setRefresh] = useState(false);
	window.scrollTo(0, 0);
	useEffect(() => {
		if (!refresh) setRefresh(true);
	}, [refresh]);

	return null;
};

export default memo(ScrollToTop);
