import React, { useEffect, useRef } from 'react';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import { RiErrorWarningFill } from 'react-icons/ri';
import { AiOutlineCalendar } from 'react-icons/ai';

interface IProps {
	label?: string;
	placeholder: string;
	type?: string;
	defaultValue?: string | number;
	disabled?: boolean;
	readOnly?: boolean;
	value?: any;
	onChange?: any;
	onBlur?: any;
	onKeyDown?: any;
	onInput?: any;
	maxLength?: number;
	propsclassName?: string;
	icons?: any;
	max?: any;
}

const TextField = ({
	label,
	placeholder,
	value,
	max,
	onChange,
	onBlur,
	type,
	icons,
	disabled,
	readOnly,
	onKeyDown,
	onInput,
	maxLength,
	defaultValue,
	propsclassName,
	...otherProps
}: IProps & FieldHookConfig<string>) => {
	const [field, meta] = useField(otherProps);
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const handleWheel = (e: WheelEvent) => {
			if (document.activeElement === inputRef.current) {
				e.preventDefault();
			}
		};

		const inputElement = inputRef.current;
		if (inputElement) {
			inputElement.addEventListener('wheel', handleWheel);
		}

		return () => {
			if (inputElement) {
				inputElement.removeEventListener('wheel', handleWheel);
			}
		};
	}, []);

	var curr = new Date();
	curr.setDate(curr.getDate());
	var todayDate = curr.toISOString().substr(0, 10);

	return (
		<div className="input-container input__text__feild__gap form-group mt-2">
			<label
				className={`input__label ${
					meta.error && meta.touched ? 'input__label__error__color' : 'position-relative'
				}`}
				htmlFor={field.name}>
				{label}{' '}
				<b className="text-danger" style={{ marginLeft: '.2rem' }}>
					*
				</b>
			</label>
			<div className="input__wrapper">
				<input
					ref={inputRef}
					className={`form-control  ${meta.touched && meta.error ? 'is-invalid' : ''} ${
						meta.error || propsclassName === 'reset-form' ? '' : 'is-valid'
					}`}
					{...field}
					type={type}
					placeholder={placeholder}
					defaultValue={defaultValue}
					autoComplete="off"
					value={value}
					disabled={disabled}
					readOnly={readOnly}
					onKeyDown={onKeyDown}
					onInput={onInput}
					maxLength={maxLength}
					max={type === 'date' ? todayDate : ''}
				/>
				{meta.error && (
					<>
						{icons && (
							<button className="icon__button">
								<AiOutlineCalendar color="#28a745" />
							</button>
						)}
					</>
				)}
			</div>
			{meta.touched && meta.error ? (
				<div className="input__error__icon">
					<RiErrorWarningFill />
				</div>
			) : (
				''
			)}
			<ErrorMessage component="div" name={field.name} className="input__error__container mb-3" />
		</div>
	);
};

export default React.memo(TextField);
