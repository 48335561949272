import * as Yup from 'yup';
import { Form, FormikProps } from 'formik';
import React from 'react';
import { ButtonComp, ScrollToTop, TextField } from 'components';
import { FormLayout } from 'containers';
import { VisaDirectService } from 'services/utility-core-services';
import useUtilityCoreApi from 'hooks/useUtilityCoreApi';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface ValueProps {
	mobile_number: number;
	amount: number;
	total_amount: number;
	charges: number;
	email: string;
	cardNumber: number;
	confirm_cardNumber: number;
}

const FORM_VALIDATION = Yup.object().shape({
	email: Yup.string().email('Invalid Email').required('This field is required'),
	mobileNumber: Yup.string()
		.matches(/^9\d{9}$/, 'Phone number is not valid')
		.required('This field is required'),
	amount: Yup.number()
		.integer('Please enter a valid amount without decimal values')
		.positive('Please enter a valid amount')
		.typeError('Amount must be number')
		.max(100000, 'Per Amount Transaction is upto 1 lakh')
		.required(' This field is required'),
});

const VisaDirectTransfer = () => {
	const [response, setResponse] = React.useState<any>({
		cardNumber: '',
		amount: '',
		charge: '',
		totalAmount: '',
		email: '',
		mobile: '',
	});
	const { id } = useParams();
	const [charges, setCharges] = React.useState<any>();
	const blockInvalidChar = (e: any) =>
		['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
	const [amount, setAmount] = React.useState<any>();
	const [totalAmount, setTotalAmount] = React.useState<number>(0);
	const [showProceed, setShowProceed] = React.useState(true);

	let chargeAmount = charges;

	const { handleRequest } = useUtilityCoreApi();
	const fetchCharges = async () => {
		const res: any = await handleRequest(VisaDirectService.getVisaDirect2());
		setCharges(res);
	};
	React.useEffect(() => {
		fetchCharges();
		getServiceName();
	}, []);
	const [serviceNameGroupList, setServiceNameGroupList] = React.useState<any>([]);
	const getServiceName = async () => {
		const res = await axios.get(
			window.__RUNTIME_CONFIG__.REACT_APP_ADMIN_URL +
				`/admin-api/api/app/utility-service-request/service-group`
		);
		setServiceNameGroupList(res.data);
	};

	const BASEURL = window.__RUNTIME_CONFIG__.REACT_APP_DIGIBANK_URL;
	React.useEffect(() => {
		if (serviceNameGroupList && serviceNameGroupList.length > 0) {
			const findId =
				serviceNameGroupList && serviceNameGroupList?.find((item: any) => item.id === id);
			if (!findId?.isEnabled) {
				window.location.replace(BASEURL);
			}
		}
	}, [serviceNameGroupList]);

	const handleChange = async (val: any) => {
		const { email, mobileNumber } = val;
		let charge = chargeAmount;
		const res: any = await handleRequest(
			VisaDirectService.getVisaDirect(amount, charge, email, mobileNumber)
		);
		setResponse(res);
	};
	const handleVisaDirectURlRedirect = async () => {
		const { email, mobile, charge, amount } = response;
		setShowProceed(false);
		const data = {
			email,
			mobile,
			charge,
			amount,
		};

		const res = await handleRequest(VisaDirectService.postVisaDirect(data));
		if (res !== '' || null) {
			const redirectUrl: any = res;
			window.location.href = redirectUrl;
		}
	};
	const resultData = [
		{ label: 'Beneficiary Email:', data: response?.email },
		{ label: 'Beneficiary Mobile:', data: response?.mobile },
		{ label: 'Amount NPR :', data: response?.amount },
		{ label: 'Charges NPR :', data: response?.charge },
		{ label: 'Total NPR :', data: response?.totalAmount },
	];

	return (
		<>
			<ScrollToTop />
			{response?.amount ? (
				<>
					<FormLayout heading="Visa Direct Transfer" handleSubmitForm={handleVisaDirectURlRedirect}>
						{({ isValid, isSubmitting }: FormikProps<ValueProps>) => (
							<Form>
								<div className="container detailWrapper">
									<h4 className="theme-primary fw-bold">Review Transfer Details</h4> <hr />
									{resultData.map((e, i: number) => (
										<div className="row mb-3" key={i}>
											<div className="col-12">
												<label className="get__details text-capitalize fw-bold "> {e.label} </label>
											</div>
											<div className="col-12">
												<span className="get__details__results theme-primary ">{e.data}</span>
											</div>
										</div>
									))}
								</div>
								<div className=" mt-3">
									<button
										className={`${
											isValid ? 'valid_btn ' : 'not_valid_btn'
										} btn btn-gradient--solid px-4 py-2 btn-sm btn--medium mt-0`}
										type="submit"
										disabled={!isValid || isSubmitting}>
										{!showProceed ? (
											<span
												className="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true"></span>
										) : (
											'Proceed'
										)}
									</button>
								</div>
								{/* <ButtonComp isSubmitting={isSubmitting} isValid={isValid} /> */}
								<button
									className="btn btn-danger  mt-3 w-100 btn-secondary px-4 py-2 btn-sm btn--medium mt-0"
									onClick={() => setResponse('')}>
									Cancel{' '}
								</button>
							</Form>
						)}
					</FormLayout>
				</>
			) : (
				<>
					<FormLayout
						heading="VISA DIRECT TRANSFER"
						initialValues={{
							cardNumber: '',
							mobile_number: '',
							amount: '',
							total_amount: '',
							charges: '',
							email: '',
							confirm_cardNumber: '',
						}}
						FORM_VALIDATION={FORM_VALIDATION}
						handleSubmitForm={(values) => handleChange(values)}>
						{({ isValid, isSubmitting, values }: FormikProps<ValueProps>) => (
							<Form>
								<TextField
									label="Amount"
									name="amount"
									placeholder="Enter Amount"
									type="number"
									value={amount}
								/>
								{setAmount(values.amount)}
								{setTotalAmount(parseInt(amount) + chargeAmount)}
								<label className="form-label input__label">Charges</label>
								<input className="form-control" value={chargeAmount} disabled />
								<label className="form-label input__label mt-2">Total Amount</label>
								<input className="form-control " value={totalAmount ? totalAmount : ''} disabled />
								<TextField
									label="Beneficiary Email"
									name="email"
									placeholder="Enter Email"
									type="email"
									autoComplete="off"
								/>
								<TextField
									label="Beneficiary Mobile"
									name="mobileNumber"
									placeholder="Enter Mobile Number"
									type="number"
									onKeyDown={blockInvalidChar}
									autoComplete="off"
								/>

								<ButtonComp isSubmitting={isSubmitting} isValid={isValid} />
							</Form>
						)}
					</FormLayout>
				</>
			)}
		</>
	);
};

export default React.memo(VisaDirectTransfer);
